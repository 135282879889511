export function checkVideoUrl(url?: string): { videoUrl?: string; isYoutube: boolean; isTwitch: boolean } {
  let videoUrl = url;
  const isTwitch = url?.indexOf('https://player.twitch.tv/') === 0 || url?.indexOf('https://player.twitch.com/') === 0;

  if (isTwitch) {
    videoUrl = `${url}&parent=${window.location.host}`;
  }

  const isYoutube = url?.indexOf('https://www.youtube.com/') === 0 || url?.indexOf('https://youtu.be') === 0;

  if (!isYoutube && !isTwitch) videoUrl = '';

  return { videoUrl, isYoutube, isTwitch };
}
