import { VoluntaryContributionConfigValueType } from '../../../../types/__generated-graphQL__';
import { TipJarConfigSet } from '../../../../types/tipjarConfig';
import { lowTipAboveUpperLimitInPennies, lowTipDefault } from './tipjarConfigDefaults';

export const tipJarConfigDefault16_low0: TipJarConfigSet = [
  lowTipDefault,
  {
    from: lowTipAboveUpperLimitInPennies,
    to: Number.MAX_VALUE,
    valueType: VoluntaryContributionConfigValueType.Percentage,
    values: [0, 10, 16, 17.5, 20],
    defaultValue: 16,
  },
];
