export const validateSourcePagePath = (pagePath: string, preserveCampaignV1DotFormat?: boolean): string => {
  const pageRouteComponents = pagePath.split('/').filter(Boolean);

  if (
    pageRouteComponents.length === 4 &&
    pageRouteComponents[0] === 'campaigns' &&
    pageRouteComponents[1] === 'charity'
  ) {
    // V1 Campaigns
    // Original source page route - eg. /campaigns/charity/thedemocharity/fintech-deploytest
    pageRouteComponents.unshift(
      `${pageRouteComponents.shift()}.${pageRouteComponents.shift()}.${pageRouteComponents.shift()}`,
    );
    return `/${pageRouteComponents[0]}/${pageRouteComponents[1]}`;
  }

  if (
    pageRouteComponents.length === 2 &&
    pageRouteComponents[0].startsWith('campaigns.charity.') &&
    preserveCampaignV1DotFormat
  ) {
    // V1 Campaigns
    // Preserve amended format when required
    return `/${pageRouteComponents[0]}/${pageRouteComponents[1]}`;
  }

  if (pageRouteComponents.length === 2 && pageRouteComponents[0].startsWith('campaigns.charity.')) {
    // V1 Campaigns
    // Re-built source page route (see above) - eg. /campaigns.charity.thedemocharity/fintech-deploytest
    return `/${pageRouteComponents[0].replace(/\./g, '/')}/${pageRouteComponents[1]}`;
  }

  if (pageRouteComponents.length === 1) {
    // Charity direct
    // Required while charity page URLs do not contain a page type
    pageRouteComponents.unshift('charity');
  }

  if (pageRouteComponents.length !== 2) {
    // Not an expected length
    return '';
  }

  if (!['page', 'charity', 'campaign', 'fundraising', 'crowdfunding'].includes(pageRouteComponents[0])) {
    // Not a JG page type
    return '';
  }

  return `/${pageRouteComponents[0]}/${pageRouteComponents[1]}`;
};
