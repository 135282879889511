import isMobileLib from 'is-mobile';

import { DeviceType } from '../../../types/__generated-graphQL__';

export const getDeviceTypeFromUserAgent = () => {
  return isMobile(window.navigator.userAgent) ? DeviceType.Mobile : DeviceType.Desktop;
};

export const isMobile = (userAgent: string): boolean => {
  interface NewNavigator extends Navigator {
    userAgentData?: {
      brands: { brand: string; version: string };
      mobile: boolean;
      platform: string;
    };
  }
  const navi = window.navigator as NewNavigator;

  return (
    navi?.userAgentData?.mobile ??
    isMobileLib({
      ua: userAgent,
      featureDetect: true,
      tablet: true,
    })
  );
};

export const isAndroid = (win = window): boolean => win.navigator.userAgent.includes('Android');

export const isWindows = (win = window): boolean => win.navigator.userAgent.includes('Windows');
