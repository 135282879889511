import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-tag';

import { AddressesByPostalCodeConnection, AddressesByPostalCodeInput } from '../../../../types/__generated-graphQL__';
import { graphqlRequestBaseQuery } from '../../utils/graphqlRequestBaseQuery';

export const addressLookupApiSlice = createApi({
  reducerPath: 'addressLookup',
  baseQuery: graphqlRequestBaseQuery,
  endpoints: builder => ({
    fetchAddress: builder.query<
      { addressesByPostalCode: AddressesByPostalCodeConnection | null },
      { input: AddressesByPostalCodeInput }
    >({
      query({ input }) {
        return {
          variables: { input },
          document: gql`
            query addressesByPostalCode($input: AddressesByPostalCodeInput!) {
              addressesByPostalCode(input: $input) {
                edges {
                  node {
                    id
                    line1
                    line2
                    townCity
                    countyStateRegion
                    postalCode
                    country
                  }
                }
                totalCount
              }
            }
          `,
        };
      },
    }),
  }),
});

export const useLazyAddressLookup = addressLookupApiSlice.useLazyFetchAddressQuery;
