import { useState } from 'react';

import { RouteNextArgs } from '../../router/useRoutes';
import useAccordionExperiment from './useAccordionExperiment';

const useAccordionPage = () => {
  const [isAccordionCollapsed, setIsAccordionCollapsed] = useState(false);
  const [pageLoading, setPageLoading] = useState<PageName | null>(null);
  const { accordionEnabled } = useAccordionExperiment();

  const collapseAccordion = async ({ nextRoute }: RouteNextArgs) => {
    setPageLoading(nextRoute);
    if (!accordionEnabled) {
      return;
    }

    setIsAccordionCollapsed(true);
    // TODO Look into checking if the animation is finished rather than a hard coded timeout
    await new Promise(resolve => setTimeout(resolve, 350));
  };

  return { isAccordionCollapsed, collapseAccordion, pageLoading };
};

export default useAccordionPage;
