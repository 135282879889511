import { useEffect } from 'react';

import { TipScheme } from '../../../types/__generated-graphQL__';
import { useFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLBranding,
  getGQLIsPoweredByAvailable,
} from '../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLCauseTipScheme } from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import getColourContrast from '../utils/getColourContrast';
import { injectStylesIntoHead } from '../utils/injectStylesIntoHead';

export function useCharityBranding() {
  const { data } = useFetchCheckoutQuery();
  const causeTipScheme = getGQLCauseTipScheme(data);
  const poweredByAvailable = getGQLIsPoweredByAvailable(data);
  const branding = getGQLBranding(data);
  const brandingTheme = branding?.theme;

  useEffect(() => {
    if ((causeTipScheme !== TipScheme.TipJar2_1 && !poweredByAvailable) || !brandingTheme) return;

    if (brandingTheme) {
      let textColor = brandingTheme;
      let themeContrast = getColourContrast(brandingTheme);
      let textOnBackgroundColor = '#ffffff';
      let buttonGroupBorderColor = brandingTheme;

      if (themeContrast && themeContrast > 175) {
        textColor = '#000000';
        textOnBackgroundColor = '#000000';
        buttonGroupBorderColor = '#cccccc;';
      }

      const disabledButtonColor = '#555555';
      const charityBrandingStyles = `
        :root {
            --theme-backgroundColor: ${brandingTheme};
            --theme-textColor: ${textColor};

            --buttonGroupBorderColor: ${buttonGroupBorderColor};
            --buttonGroupBackgroundColorActive: ${brandingTheme};
            --buttonGroupTextColorActive: ${textOnBackgroundColor};

            --primaryButtonBackground: ${brandingTheme};
            --primaryButtonBackgroundDisabled: ${disabledButtonColor};
            --primaryButtonTextColor: ${textOnBackgroundColor};

            --amountInputTextColor: ${textColor};
        }
			`;
      injectStylesIntoHead(charityBrandingStyles);
    }
  }, [brandingTheme, causeTipScheme, poweredByAvailable]);
}
