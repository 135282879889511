import {
  BeneficiaryItem,
  Charity,
  Crypto,
  CurrencyDefaults,
  DirectDebit,
  DonationItemDetails,
  ImageMedia,
  LineItemDetails,
  LivestreamMedia,
  Media,
  PaymentCard,
  PaymentDetails,
  VideoMedia,
  VoluntaryContributionConfig,
} from '../../../types/__generated-graphQL__';

export const isDonationDetails = (details?: LineItemDetails | null): details is DonationItemDetails => {
  return details?.__typename === 'DonationItemDetails';
};

export const isPaymentCard = (paymentDetails?: PaymentDetails | null): paymentDetails is PaymentCard => {
  return paymentDetails?.__typename === 'PaymentCard';
};

export const isDirectDebit = (paymentDetails?: PaymentDetails | null): paymentDetails is DirectDebit => {
  return paymentDetails?.__typename === 'DirectDebit';
};

export const isCrypto = (paymentDetails?: PaymentDetails | null): paymentDetails is Crypto => {
  return paymentDetails?.__typename === 'Crypto';
};

export const isImageMedia = (media?: Media | null): media is ImageMedia => {
  return media?.__typename === 'ImageMedia';
};

export const isVideoMedia = (media?: Media | null): media is VideoMedia => {
  return media?.__typename === 'VideoMedia';
};

export const isLivestreamMedia = (media?: Media | null): media is LivestreamMedia => {
  return media?.__typename === 'LivestreamMedia';
};

export const isCurrencyDefaults = (value: any): value is CurrencyDefaults => {
  return value?.__typename === 'CurrencyDefaults';
};

export const isVoluntaryContributionConfig = (
  voluntaryContributionConfigArray?: (VoluntaryContributionConfig | null)[] | null,
): voluntaryContributionConfigArray is [VoluntaryContributionConfig] => {
  return voluntaryContributionConfigArray?.[0]?.__typename === 'VoluntaryContributionConfig';
};

export const isCharityBeneficiary = (beneficiary?: BeneficiaryItem | null): beneficiary is Charity => {
  return beneficiary?.__typename === 'Charity';
};
