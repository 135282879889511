import { compose } from 'redux';

export const digitsOnly = (s: string) => (s ? s.replace(/[^\d]/g, '') : '');

const sortCodeGroupsWithDashes = (s: string) => s.replace(/(\d\d)/g, '$1-');

const truncate = (length: number) => (s: string) => s.substr(0, length);

export const formatSortCode = (newValue: string, prevValue: string = ''): string => {
  const charactersWereDeleted = prevValue !== '' && prevValue.startsWith(newValue);
  return charactersWereDeleted ? newValue : compose(truncate(8), sortCodeGroupsWithDashes, digitsOnly)(newValue);
};

export const formatAccountNumber = compose(truncate(8), digitsOnly);

export const dayOfMonthOptions = [
  { label: '1st', value: 1 },
  { label: '15th', value: 15 },
  { label: '25th', value: 25 },
] as const;

export const humanizedDayOfMonth = (dayOfMonth: number) =>
  dayOfMonthOptions.find(dayOfMonthOption => dayOfMonthOption.value === dayOfMonth)?.label;
