import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useDecision } from '@optimizely/react-sdk';

import { getAbTestKey } from '../../config/abTests';
import Heart from '../Heart/Heart';
import * as styles from './ThankYouInAdvance.css';

const ThankYouInAdvance: FunctionComponent = () => {
  const { t } = useTranslation();

  const [{ enabled }] = useDecision(getAbTestKey({ abTest: 'THANKYOU_ADVANCE' }));

  if (!enabled) return null;

  return (
    <div className={styles.container}>
      <Heart />
      <div>{t('thankyouForYourGenerosity', { ns: 'ab' })}</div>
    </div>
  );
};

export default ThankYouInAdvance;
