import React, { FunctionComponent, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AuthContext } from '@justgiving/auth-react';
import { OptimizelyContext } from '@optimizely/react-sdk';

import { TipScheme } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { PageHeading } from '../../components/PageHeading/PageHeading';
import AlertRecoverableError from '../../components/alert/AlertRecoverableError';
import { getAbTestKey } from '../../config/abTests';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useSetSelectedPaymentType } from '../../hooks/useSetSelectedPaymentType';
import AccordionPages from '../../modules/AccordionPages/AccordionPages';
import useAccordionExperiment from '../../modules/AccordionPages/useAccordionExperiment';
import useAccordionPage from '../../modules/AccordionPages/useAccordionPage';
import { AmountBoostPrompt } from '../../modules/AmountBoostPrompt/AmountBoostPrompt';
import AmountContinue from '../../modules/AmountContinue/AmountContinue';
import { CryptoWrapper } from '../../modules/Crypto/CryptoWrapper';
import DirectDebitForm from '../../modules/DirectDebit/DirectDebitForm';
import { digitsOnly, formatSortCode } from '../../modules/DirectDebit/directDebit.utils';
import { DonationAmountIsPrivate } from '../../modules/DonationValue/DonationAmountIsPrivate';
import { DonationValue } from '../../modules/DonationValue/DonationValue';
import { DonorPaysFeesWrapper } from '../../modules/DonorPaysFees/DonorPaysFees';
import AmountPageFooter from '../../modules/Footer/AmountPageFooter';
import RecurrenceUpgrade from '../../modules/Recurrence/RecurrenceUpgrade';
import RecurringCardPayments from '../../modules/Recurrence/RecurringCardPayments';
import TransactionTypeTabs from '../../modules/Recurrence/TransactionTypeTabs';
import SuggestedAmounts from '../../modules/SuggestedAmounts/SuggestedAmounts';
import { TipJarWrapper } from '../../modules/TipJar/TipJar';
import TotalAmountToPay from '../../modules/TotalAmountToPay/TotalAmountToPay';
import TrustMark from '../../modules/TrustMark/TrustMark';
import { useLazyAddressLookup } from '../../redux/addressLookupApiSlice/addressLookupApiSlice';
import { useMutateAmountMutation } from '../../redux/checkoutApiSlice/mutateAmount';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLSavedOrDefaultDonationValue,
  getGQLSavedOrDefaultCurrencyCode,
  getGQLIsDonorPayingFees,
  getGQLIsRecurringOrDirectDebit,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLCauseTipScheme,
  getGQLIsAmountPrivate,
  getGQLRecurrenceDayOfMonth,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLTipAmountInPounds,
  getGQLTotalAmountInPounds,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import {
  getGQLDirectDebitDetails,
  getGQLSelectedPaymentType,
} from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getIsEmbedded } from '../../redux/session/session.selectors';
import { useRoutes } from '../../router/useRoutes';
import { calculateTransactionType } from '../../utils/calculateTransactionType';
import { getLoginURL, getSignUpURL } from '../../utils/getLoginURL';
import { normalisePostcodeInput } from '../../utils/normalisePostcodeInput';
import { InvalidPostcodeError, validateAddressExists } from '../../utils/validateAddressExists';

export const AmountPage: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { routeNext } = useRoutes();
  const { t } = useTranslation();
  const { optimizely } = useContext(OptimizelyContext);
  const [updateAmount] = useMutateAmountMutation();
  const [addressLookup] = useLazyAddressLookup();
  const { setSelectedPaymentType } = useSetSelectedPaymentType();

  const directDebitDetails = getGQLDirectDebitDetails(data);
  const causeTipScheme = getGQLCauseTipScheme(data);
  const savedOrDefaultCurrencyCode = getGQLSavedOrDefaultCurrencyCode(data);
  const isRecurringOrDirectDebit = getGQLIsRecurringOrDirectDebit(data);
  const selectedPaymentType = getGQLSelectedPaymentType(data);
  const recurrenceDayOfMonth = getGQLRecurrenceDayOfMonth(data);
  const isDonorPayingFees = getGQLIsDonorPayingFees(data);
  const totalAmountInPounds = getGQLTotalAmountInPounds(data);
  const tipAmountInPounds = getGQLTipAmountInPounds(data);
  const isAmountPrivate = getGQLIsAmountPrivate(data);
  const savedOrDefaultDonationValue = getGQLSavedOrDefaultDonationValue(data);
  const isEmbedded = useSelector(getIsEmbedded);
  const signUpURL = getSignUpURL(isEmbedded);

  const loginURL = getLoginURL(isEmbedded);
  const auth = useContext(AuthContext);

  const mapDirectDebit = (): Omit<AmountPageFormValues['directDebit'], 'accountDetails'> => {
    const directDebitDefaults = {
      accountHolderName: '',
      dayOfMonth: 1,
      accountNumber: '',
      sortCode: '',
      cancelWhenCauseExpires: 'no',
      address: undefined,
    };
    const directDebit = directDebitDetails ?? directDebitDefaults;

    return {
      ...directDebit,
      sortCode: formatSortCode(directDebit.sortCode),
    };
  };

  const formMethods = useForm<AmountPageFormValues>({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: {
      donationCurrencyCode: savedOrDefaultCurrencyCode,
      recurrenceDayOfMonth: recurrenceDayOfMonth ?? '1',
      transactionType: calculateTransactionType(isRecurringOrDirectDebit, selectedPaymentType),
      isDonorPayingFees,
      totalAmountToPay: totalAmountInPounds,
      isAmountPrivate,
      donationValue: savedOrDefaultDonationValue,
      tipAmount: tipAmountInPounds,
      directDebit: mapDirectDebit(),
      causeTipScheme,
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    register,
  } = formMethods;
  useDocumentTitle(t('heading', { ns: 'amount' }));
  const { accordionEnabled } = useAccordionExperiment();

  const [submitFailure, setSubmitFailure] = useState<null | 'RecoverableError' | 'InvalidBankDetails'>(null);

  const { isAccordionCollapsed, collapseAccordion, pageLoading } = useAccordionPage();

  const onSubmit = (redirectToLogin?: boolean) =>
    handleSubmit(async formData => {
      try {
        setSubmitFailure(null);
        if (formData.directDebit?.address?.country === 'GB' && formData.directDebit?.address?.postalCode) {
          const { data } = await addressLookup(
            { input: { postalCode: normalisePostcodeInput(formData.directDebit.address.postalCode) } },
            true,
          );
          validateAddressExists(data);
        }

        const directDebitAddress = formData.directDebit?.address;
        if (typeof directDebitAddress?.id !== 'undefined') delete directDebitAddress.id;

        const tipSchemeAppliedToDonation =
          formData.isDonorPayingFees === false ? TipScheme.Disabled : formData.causeTipScheme ?? TipScheme.Disabled;
        const calculatedTipAmount =
          formData.tipAmount ??
          (formData.totalAmountToPay ? formData.totalAmountToPay - parseFloat(formData.donationValue) : 0);

        const response = await updateAmount({
          currency: formData.donationCurrencyCode,
          value: parseFloat(formData.donationValue),
          tipValue: calculatedTipAmount,
          tipScheme: tipSchemeAppliedToDonation,
          isAmountPrivate: formData.isAmountPrivate ?? false,
          isRecurring: formData.transactionType === TransactionTypes.RECURRING,
          recurrenceDayOfMonth: formData.recurrenceDayOfMonth?.toString(),
          ...(formData.directDebit
            ? {
                directDebit: {
                  accountHolderName: formData.directDebit.accountHolderName,
                  accountNumber: formData.directDebit.accountNumber,
                  dayOfMonth: formData.directDebit.dayOfMonth,
                  sortCode: digitsOnly(formData.directDebit.sortCode),
                  address: directDebitAddress,
                  ...(formData.directDebit.cancelWhenCauseExpires
                    ? { cancelWhenCauseExpires: formData.directDebit.cancelWhenCauseExpires === 'yes' }
                    : {}),
                },
              }
            : {}),
          ...(formData.crypto
            ? {
                crypto: {
                  currencyShortName: formData.crypto.currencyShortName,
                  currencyFullName: formData.crypto.currencyFullName,
                  currencyLogoUrl: formData.crypto.currencyLogoUrl,
                  estimatedExchangeRate: Number(formData.crypto.estimatedExchangeRate),
                },
              }
            : {}),
        });
        if ('error' in response) {
          throw response.error;
        }

        // Currently supported payment methods are not complete until amount has been saved, check if suggested payment type needs to be made selected after this has happened
        if (!formData.directDebit) await setSelectedPaymentType();

        if (
          !auth?.isUserLoggedIn() &&
          formData.transactionType === TransactionTypes.RECURRING &&
          optimizely?.decide(getAbTestKey({ abTest: 'DIRECT_DEBIT_LOGIN' })).enabled
        ) {
          return window.location.assign(redirectToLogin ? loginURL : signUpURL);
        }

        await routeNext({ beforeNavigation: collapseAccordion });
      } catch (error) {
        if (error.message?.includes('Bank account details not valid')) {
          setError('directDebit.accountDetails', {
            type: 'manual',
            message: t('validation|invalidBankDetailsError', { ns: 'recurring' }),
          });
        } else if (error instanceof InvalidPostcodeError) {
          setError('directDebit.address.postalCode', {
            type: 'manual',
            message: t('zipOrPostalCode|mandatory', { ns: 'validation' }),
          });
        } else {
          setSubmitFailure('RecoverableError');
        }
      }
    });

  return (
    <>
      {submitFailure === 'RecoverableError' && <AlertRecoverableError />}
      <FormProvider {...formMethods}>
        <AccordionPages collapse={isAccordionCollapsed} pageLoading={pageLoading}>
          <div className="donate-main">
            <form
              noValidate
              method="post"
              onSubmit={onSubmit()}
              onChange={() => {
                if (errors.directDebit?.accountDetails) {
                  clearErrors('directDebit.accountDetails');
                }
              }}
            >
              <PageHeading>{t('heading', { ns: 'amount' })}</PageHeading>
              <input type="hidden" {...register('causeTipScheme')} />
              <TransactionTypeTabs />
              <SuggestedAmounts />
              <DonationValue />
              <AmountBoostPrompt />
              <RecurrenceUpgrade />
              <RecurringCardPayments />
              <CryptoWrapper />
              <DonationAmountIsPrivate />
              <TipJarWrapper hide />
              <DonorPaysFeesWrapper />
              <DirectDebitForm />
              <AmountContinue onSubmit={onSubmit} />
              <div>
                <TotalAmountToPay />
                {!accordionEnabled && <TrustMark />}
                <AmountPageFooter />
              </div>
            </form>
          </div>
        </AccordionPages>
      </FormProvider>
    </>
  );
};

export default AmountPage;
