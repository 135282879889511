import React, { FunctionComponent, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

import * as styles from './FormError.css';

type ErrorProps = {
  additionalClassName?: string;
  children: ReactNode;
  withIcon?: Boolean;
};

export const ErrorWrapper: FunctionComponent<ErrorProps> = ({ children, additionalClassName, withIcon = false }) => {
  return (
    <p className={classNames(styles.error, additionalClassName, { [styles.withIcon]: withIcon })} role="alert">
      {children}
    </p>
  );
};

type FormErrorProps<T> = {
  fieldName: keyof T & string;
  additionalClassName?: string;
  customErrorRender?: (message: string) => JSX.Element;
};

export const FormError = <T extends Record<string, unknown>>({
  fieldName,
  additionalClassName,
  customErrorRender,
}: FormErrorProps<T>) => {
  const { formState } = useFormContext();
  return (
    <ErrorMessage
      name={fieldName}
      errors={formState.errors}
      render={({ message }) => {
        if (customErrorRender) return customErrorRender(message);
        return <ErrorWrapper additionalClassName={additionalClassName}>{message}</ErrorWrapper>;
      }}
    />
  );
};
