import React, { FunctionComponent } from 'react';

import classNames from 'classnames';

import * as styles from './SkeletonLoader.css';

type Props = {
  width?: string;
  height?: string;
  fontSize?: string;
  className?: string;
  animationDelay?: string;
  isAnimated?: boolean;
};

const SkeletonLoader: FunctionComponent<Props> = ({
  width = '100%',
  height = '1rem',
  fontSize,
  className,
  animationDelay,
  isAnimated = true,
}) => {
  return (
    <div
      style={{ width, height: fontSize || height, fontSize, borderRadius: height, animationDelay }}
      className={classNames(isAnimated ? styles.loader : styles.notAnimated, className)}
    ></div>
  );
};

export default SkeletonLoader;
