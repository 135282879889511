import React, { FunctionComponent, ReactNode } from 'react';

import classNames from 'classnames';

import * as styles from './TransitionContent.css';

type TransitionContentProps = {
  showContent: boolean;
  children: ReactNode;
  mode?: 'fade' | 'slideUpFade';
};
const TransitionContent: FunctionComponent<TransitionContentProps> = ({
  children,
  showContent,
  mode = 'slideUpFade',
}) => {
  const currentModeClass = mode === 'fade' ? styles.fade : styles.slideUpFade;
  return <div className={classNames(currentModeClass, { [styles.showContent]: showContent })}>{children}</div>;
};

export default TransitionContent;
