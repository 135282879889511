export const getTipIsReady = (state: State) => state.tip.isReady;

export const getTipSelectionValue = (state: State) => state.tip.selectionValue;

export const getTipOtherValue = (state: State) => state.tip.otherValue;

export const getTipInputMode = (state: State) => state.tip.inputMode;

export const getTipUserSelectionMade = (state: State) => state.tip.userSelectionMade;

export const getTipModalOpen = (state: State) => state.tip.tipModalOpen;

export const getTipValues = (state: State) => state.tip.tipValues;

export const getTipValueType = (state: State) => state.tip.valueType;

export const getTipDefaultValue = (state: State) => state.tip.defaultValue;

export const getTipLastSelectedAmountValue = (state: State) => state.tip.lastSelectedAmountValue;

export const getTipLastSelectedPercentValue = (state: State) => state.tip.lastSelectedPercentageValue;
