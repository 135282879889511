import { Reducer } from 'redux';

import { ABTestActionTypes } from './abTests.actions';

const initialState = {
  enrolledInOptimizely: {},
};

export const AB_TEST: Reducer<State['AB_TEST'], ABTestActionTypes> = (
  state = initialState,
  action: ABTestActionTypes,
) => {
  switch (action.type) {
    case 'abTest/updateEnrolledInOptimizely':
      return {
        ...state,
        enrolledInOptimizely: {
          ...state.enrolledInOptimizely,
          [action.slug]: { variation: action.variation, slug: action.slug, data: action.data },
        },
      };
    default:
      return state;
  }
};
