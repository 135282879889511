import React, { FunctionComponent, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import Checkbox from '../../components/Form/Checkbox/Checkbox';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLDisplayHideAmountOption } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { useIsRecurringPaymentAvailable } from '../Recurrence/useRecurringAvailable';

export const DonationAmountIsPrivate: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const transactionType = useWatch<AmountPageFormValues>({ name: 'transactionType' });

  const { t } = useTranslation();
  const displayHideAmountOption = getGQLDisplayHideAmountOption(data);

  const { isDirectDebitAvailable } = useIsRecurringPaymentAvailable();

  const shouldDisplayHideAmountOption = useMemo(() => {
    // Hide privacy option if direct debit. API has a bug where this value isn't persited
    if (isDirectDebitAvailable && transactionType === TransactionTypes.RECURRING) return false;
    return displayHideAmountOption;
  }, [displayHideAmountOption, isDirectDebitAvailable, transactionType]);

  if (!shouldDisplayHideAmountOption) return null;

  return (
    <Checkbox
      name="isAmountPrivate"
      id="isAmountPrivate"
      data-qa="isAmountPrivate"
      className={sprinkles({ marginTop: { mobile: 'sizeSpacing04', desktop: 'sizeSpacing05' } })}
    >
      {t('hideAmountLabel', { ns: 'amount' })}
    </Checkbox>
  );
};
