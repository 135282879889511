import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { useDelayedRender } from '../../hooks/useDelayedRender';

const LoadingPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const delayed = useDelayedRender(300);

  if (delayed) return null;

  return (
    <div className="loading-page" data-qa="app-processing">
      <VisuallyHidden>
        {t('accessibleIsLoadingText', { defaultValue: 'Processing, please wait a moment' })}
      </VisuallyHidden>
    </div>
  );
};

export default LoadingPage;
