export const updateIsLoggedIn = (isLoggedIn: boolean) => ({
  type: 'auth/updateIsLoggedIn' as const,
  isLoggedIn,
});

export const updateUserDetails = ({
  profilePictureUrl,
  guid,
  givenName,
  familyName,
}: {
  guid: string;
  profilePictureUrl: string;
  givenName: string;
  familyName: string;
}) => ({
  type: 'auth/updateUserDetails' as const,
  profilePictureUrl,
  guid,
  givenName,
  familyName,
});

export type AuthActionTypes = ReturnType<typeof updateUserDetails> | ReturnType<typeof updateIsLoggedIn>;
