import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { paymentIcon } from '../../../styles/icons.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import Radio from '../../components/Form/Radio/Radio';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { cardTypeToCardNameMap } from '../../config/cardMap';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLPaymentCardDetails } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLSortedSavedPaymentCards } from '../../redux/checkoutApiSlice/selectors/transactingUser.selectors';
import { getPaymentCardIcon } from '../../utils/browser';

type Props = { itemClassName?: string; labelClassName?: string };

const SavedPaymentCardsRadios: FunctionComponent<Props> = ({ itemClassName, labelClassName }) => {
  const { data } = useFetchCheckoutQuery();

  const savedCards = getGQLSortedSavedPaymentCards(data);
  const selectdPaymentCardId = getGQLPaymentCardDetails(data)?.paymentCardId;

  const { t } = useTranslation();

  if (!savedCards?.length) return null;

  const cards = savedCards.map(card => {
    const { paymentCardId, cardType, lastFourDigits, disabled, expired, nameOnCard } = card;
    if (disabled || expired) return null;

    const cardTypeName = cardTypeToCardNameMap(cardType) ?? cardType;

    return (
      <li key={paymentCardId} className={classNames(itemClassName)}>
        <Radio
          name="paymentType"
          className={labelClassName}
          value={`SavedCard|${paymentCardId!}`}
          id={`savedCard-${paymentCardId}`}
          data-qa={paymentCardId}
          defaultChecked={selectdPaymentCardId === paymentCardId}
          registerMethods={{
            required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
          }}
        >
          <span className={sprinkles({ display: 'flex' })}>
            {t('savedCardLabel', { lastFourDigits, ns: 'payment' })}
            <span
              className={classNames(
                paymentIcon({ type: getPaymentCardIcon(cardType) ?? 'cardFront' }),
                sprinkles({ marginLeft: 'sizeSpacingAuto' }),
              )}
              data-qa="cardTypeIcon"
            >
              <VisuallyHidden>
                {' '}
                {nameOnCard} {t('cardType', { cardType: cardTypeName })}
              </VisuallyHidden>
            </span>
          </span>
        </Radio>
      </li>
    );
  });

  return <>{cards}</>;
};

export default SavedPaymentCardsRadios;
