import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import DonationTargetBar from '../../components/DonationTargetBar/DonationTargetBar';
import { usePageName } from '../../hooks/usePageName';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCrowdfunding } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLCharityName,
  getGQLPageOwnerName,
  getGQLCampaignName,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import ThankYouInAdvance from '../ThankYouInAdvance/ThankYouInAdvance';
import * as styles from './Header.css';
import HeaderImage from './HeaderImage';

const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const { accordionEnabled } = useAccordionExperiment();
  const { data } = useFetchCheckoutQuery();
  const orderType = getGQLOrderType(data);
  const crowdfunding = getGQLCrowdfunding(data);
  const pageOwnerName = getGQLPageOwnerName(data);
  const campaignName = getGQLCampaignName(data);
  const charityName = getGQLCharityName(data);

  // Note: "unclaimed" pages for Digital Services projects have the name "- -", which is suppressed by the selector
  const isExpectedFundraiserNameMissing = Boolean(
    orderType &&
      [LineItemType.DonationCampaignFrp, LineItemType.DonationCharityFrp].includes(orderType) &&
      !pageOwnerName,
  );

  const pageName = usePageName();
  if (pageName === 'ThankYouPage') return null;

  return (
    <header>
      <div className={accordionEnabled ? styles.accordionWrapper : styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.profile}>
            <div className={styles.image}>
              <HeaderImage />
            </div>
            <p>
              {t('donateTo', {
                name: pageOwnerName ?? campaignName ?? charityName,
                ns: 'page',
              })}
            </p>
            {orderType !== LineItemType.DonationCharityDirect && !isExpectedFundraiserNameMissing && (
              <p className={styles.extra}>
                {orderType === LineItemType.DonationCrowdfunding
                  ? t(`crowdfunding|for`, {
                      name: crowdfunding?.category,
                      ns: 'page',
                    })
                  : t(`fundraising|for`, {
                      name: charityName,
                      ns: 'page',
                    })}
              </p>
            )}
          </div>
          <DonationTargetBar />
          <ThankYouInAdvance />
        </div>
      </div>
    </header>
  );
};

export default Header;
