import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import { PAGES } from '../../../config/pages';
import { AccordionPageCurrentStep } from '../AccordionPageCurrentStep';
import { AccordionPageLoadingStep } from '../AccordionPageLoadingStep';
import { AccordionPageNewStep } from '../AccordionPageNewStep';
import { AccordionPagePreviewEmerge } from '../AccordionPagePreviewEmerge';
import { AccordionPageSectionWrapper } from '../AccordionPageSectionWrapper';
import type { PageSectionProps } from './AccordionSection';

const SummaryStepPreview: FunctionComponent = () => {
  const { t } = useTranslation();

  return <AccordionPageNewStep>{t('summary|heading', { ns: 'ab' })}</AccordionPageNewStep>;
};

const SummaryPageSection: FunctionComponent<PageSectionProps> = ({ children, currentPageCollapsed, isLoading }) => {
  const isCurrentPage = useMatch(`/:pageType?/:pageSlug?/${PAGES.SummaryPage}`);

  if (isLoading) return <AccordionPageLoadingStep />;

  if (isCurrentPage) {
    return (
      <li>
        <AccordionPageSectionWrapper>
          {currentPageCollapsed && (
            <AccordionPagePreviewEmerge>
              <SummaryStepPreview />
            </AccordionPagePreviewEmerge>
          )}
          <AccordionPageCurrentStep collapsed={currentPageCollapsed}>{children}</AccordionPageCurrentStep>
        </AccordionPageSectionWrapper>
      </li>
    );
  }

  return (
    <li>
      <SummaryStepPreview />
    </li>
  );
};

export default SummaryPageSection;
