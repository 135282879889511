import { LineItemType } from '../../../types/__generated-graphQL__';

export const getQualarooIdentifyValue = (isGuest: boolean) => (isGuest ? '' : 'known');

export const getQualarooCauseTypeValue = (orderType?: LineItemType) => {
  switch (orderType) {
    case LineItemType.DonationCrowdfunding:
      return 'Crowdfunding';
    case LineItemType.DonationCharityFrp:
      return 'Charity FRP';
    case LineItemType.DonationCharityDirect:
      return 'Charity Direct Giving';
    case LineItemType.DonationCampaignFrp:
      return 'Campaign FRP';
    case LineItemType.DonationCampaignDirect:
      return 'Campaign Direct';
    default:
      return '';
  }
};
