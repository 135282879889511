import { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '@justgiving/auth-react';

import getConsents from '../../modules/Consents/getConsents.thunk';
import { getIsLoggedIn } from '../../redux/auth/auth.selectors';
import { setSiftScienceUserId } from '../../utils/vendorAPIs';

const HandleLogin: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoggedin = useSelector(getIsLoggedIn);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedin) {
      dispatch(getConsents());
      setSiftScienceUserId(auth?.getAccessToken()?.guid);
    }
  }, [auth, dispatch, isLoggedin]);

  return null;
};

export default HandleLogin;
