import { useWatch } from 'react-hook-form';

import { AcceptedCurrencyCode } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../types/formValues';
import { HACK_getForceDescriptionsOnAllCurrencies } from '../../config/hacks';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCauseId } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLCurrencyCodeContainingSuggestedAmountDescriptions,
  getGQLSuggestedAmountsForCurrencyInPounds,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';

type Description = {
  id: string;
  description: string;
  selected: boolean;
};

export type DonationDescriptions = {
  hasDescription: boolean;
  descriptions: Description[];
  selectedIndex: number;
};

export const useDonationDescriptions = (): DonationDescriptions => {
  const [donationCurrencyCode, donationValue] = useWatch<AmountPageFormValues>({
    name: ['donationCurrencyCode', 'donationValue'],
  });
  const { data } = useFetchCheckoutQuery();
  const causeId = getGQLCauseId(data);

  let forceSuggestedAmountsToCurrency: AcceptedCurrencyCode | undefined;
  if (HACK_getForceDescriptionsOnAllCurrencies(causeId)) {
    forceSuggestedAmountsToCurrency = getGQLCurrencyCodeContainingSuggestedAmountDescriptions(data);
  }

  const suggestedAmountsWithDescriptions = getGQLSuggestedAmountsForCurrencyInPounds(
    data,
    forceSuggestedAmountsToCurrency ?? donationCurrencyCode,
  );

  const hasDescription = () => {
    if (suggestedAmountsWithDescriptions.length === 0) return false;
    return (
      suggestedAmountsWithDescriptions.filter(suggestedAmount => Boolean(suggestedAmount?.description)).length ===
      suggestedAmountsWithDescriptions.length
    );
  };

  const descriptionAmounts = suggestedAmountsWithDescriptions.map(suggestedAmount => suggestedAmount?.value);

  const getSelectedDescriptionIndex = () => {
    const donationValueNumber = Number(donationValue);
    let selectedIndex = descriptionAmounts.length - 1;

    descriptionAmounts.every((descriptionAmount, i) => {
      if (donationValueNumber === descriptionAmount) {
        selectedIndex = i;
        return false;
      }

      if (donationValueNumber > descriptionAmount && donationValueNumber < descriptionAmounts[i + 1]) {
        selectedIndex = i + 1;
        return false;
      }

      if (donationValueNumber < descriptionAmount) {
        selectedIndex = 0;
        return false;
      }

      return true;
    });

    return selectedIndex;
  };

  const selectedIndex = getSelectedDescriptionIndex();

  return {
    hasDescription: hasDescription(),
    descriptions: suggestedAmountsWithDescriptions?.map((sa, index) => {
      return {
        id: `donationSuggestedAmounts-${index}-description`,
        description: sa.description ?? '',
        selected: index === selectedIndex,
      };
    }),
    selectedIndex,
  };
};
