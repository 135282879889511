import bankersRounding from 'bankers-rounding';

export const calculateTotalAmount = (value: string, fee = 5) => {
  const tip = calculateTip(value, fee);
  return bankersRounding(Number(value) + tip, 2);
};

export const calculateTip = (value: string, fee = 5) => {
  const rate = fee / 100;
  return bankersRounding(Number(value) * rate, 2);
};
