import { TipJarConfigSet } from '../../../types/tipjarConfig';
import { HACK_getGQLIsViralPageBySlug } from '../config/hacks';
import { tipJarConfigDefault16 } from '../config/tipjar/tipjarConfigDefault16';
import { tipJarConfigDefault16_low0 } from '../config/tipjar/tipjarConfigDefault16_low0';
import { tipJarConfigDefault17 } from '../config/tipjar/tipjarConfigDefault17';
import { tipJarConfigMacmillan } from '../config/tipjar/tipjarConfigMacmillan';
import { tipjarConfigViralPage } from '../config/tipjar/tipjarConfigViralPage';
import { useFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLLegacyCharityId } from '../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLIsAmountLocked } from '../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import { getGQLisTipJarEnabled } from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { useTipJarAbTest } from './useTipJarAbTest';

export type ShowTipOnOptions = 'summary' | 'disabled';
export type ShowTipAsOptions = 'slider' | 'radios' | 'disabled';

type TipJarModeConfig = {
  showTipOn: ShowTipOnOptions;
  showTipAs: ShowTipAsOptions;
  tipJarValuesOverride?: TipJarConfigSet;
  isFlippedSummary?: boolean;
  showTotal?: boolean;
};

export const useTipJarModeConfig = (): TipJarModeConfig => {
  const { data } = useFetchCheckoutQuery();
  const isViralPage = HACK_getGQLIsViralPageBySlug(data);

  const isAmountLocked = getGQLIsAmountLocked(data);
  const tipJarEnabled = getGQLisTipJarEnabled(data);

  const [{ variationKey: standardVariationKey }] = useTipJarAbTest();

  const charityId = getGQLLegacyCharityId(data);
  if (!tipJarEnabled) {
    return { showTipOn: 'disabled', showTipAs: 'disabled' };
  }

  if (isAmountLocked) {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
    };
  }

  if (isViralPage) {
    return {
      showTipOn: 'summary',
      showTipAs: 'radios',
      tipJarValuesOverride: tipjarConfigViralPage,
    };
  }

  // Macmillan behaviour
  if (charityId === '2116' || charityId === '3420646') {
    return {
      showTipOn: 'summary',
      showTipAs: 'radios',
      tipJarValuesOverride: tipJarConfigMacmillan,
    };
  }

  // Standard tipjar ab test start
  if (standardVariationKey === 'b') {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
      tipJarValuesOverride: tipJarConfigDefault16,
      showTotal: true,
    };
  }
  if (standardVariationKey === 'c') {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
      tipJarValuesOverride: tipJarConfigDefault17,
      showTotal: true,
    };
  }
  if (standardVariationKey === 'd') {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
      tipJarValuesOverride: tipJarConfigDefault16,
    };
  }
  if (standardVariationKey === 'e') {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
      tipJarValuesOverride: tipJarConfigDefault16_low0,
      isFlippedSummary: true,
    };
  }
  if (standardVariationKey === 'f') {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
      tipJarValuesOverride: tipJarConfigDefault16,
      isFlippedSummary: true,
    };
  }
  // Standard tipjar ab test end

  // Default behaviour
  return {
    showTipOn: 'summary',
    showTipAs: 'slider',
  };
};
