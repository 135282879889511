import React, { FunctionComponent, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentType } from '../../../../types/__generated-graphQL__';
import { AddPaymentCardPageFormValues, SelectPaymentTypeFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import { PageHeading } from '../../components/PageHeading/PageHeading';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import Alert from '../../components/alert/Alert';
import AlertAddCardPaymentRecoverableError from '../../components/alert/AlertAddCardPaymentRecoverableError';
import AlertRecoverableError from '../../components/alert/AlertRecoverableError';
import Button from '../../components/button/Button';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import AccordionPages from '../../modules/AccordionPages/AccordionPages';
import useAccordionPage from '../../modules/AccordionPages/useAccordionPage';
import { resetAmexPoints } from '../../modules/AmexPayWithPoints/AmexPayWithPoints.actions';
import { getAmexPWPAppliedPoints } from '../../modules/AmexPayWithPoints/AmexPayWithPoints.selectors';
import FixedAmount from '../../modules/FixedAmount/FixedAmount';
import { usePaymentTypesAvailable } from '../../modules/Payment/usePaymentTypesAvailable';
import { PaymentMethodsRadios } from '../../modules/PaymentMethodRadios/PaymentMethodsRadios';
import { useMutatePaymentCardMutation } from '../../redux/checkoutApiSlice/mutatePaymentCard';
import { useMutatePaymentMethodMutation } from '../../redux/checkoutApiSlice/mutatePaymentMethod';
import { useMutateSelectedCardMutation } from '../../redux/checkoutApiSlice/mutateSelectedCard';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsAmountLocked } from '../../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import { getGQLIsAmexPayWithPointsAvailable } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLPaymentCardDetails } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { useRoutes } from '../../router/useRoutes';
import { isPaymentCard } from '../../utils/typeGuards';

const SelectPaymentPage: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();
  useDocumentTitle(t('selectPayment|heading', { ns: 'payment' }));
  const { isAnyAlternativePaymentsAvailable, hasSavedPaymentCards } = usePaymentTypesAvailable();

  const isAmountLocked = getGQLIsAmountLocked(data);
  const [isRecoverableError, setIsRecoverableError] = useState(false);
  const [addCardErrorType, setAddCardErrorType] = useState<null | string>(null);

  const hasAmexPWPAppliedPoints = Boolean(useSelector(getAmexPWPAppliedPoints));
  const selectedPaymentCard = getGQLPaymentCardDetails(data);

  const [updateSelectedCard] = useMutateSelectedCardMutation();

  const [mutatePaymentCard] = useMutatePaymentCardMutation();

  const { isAccordionCollapsed, collapseAccordion, pageLoading } = useAccordionPage();

  const isOnlyNewCardAvailable = !isAnyAlternativePaymentsAvailable && !hasSavedPaymentCards;
  const formMethods = useForm<SelectPaymentTypeFormValues>({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: {
      paymentType: isOnlyNewCardAvailable ? PaymentType.PaymentCard : undefined,
    },
  });

  const {
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
  } = formMethods;

  const paymentType = useWatch({ control, name: 'paymentType' });

  const [mutatePaymentMethod] = useMutatePaymentMethodMutation();
  const isAmexPayWithPointsAvailable = getGQLIsAmexPayWithPointsAvailable(data);

  const { routeNext } = useRoutes();
  const dispatch = useDispatch();

  const submitCardData = async (formData: AddPaymentCardPageFormValues) => {
    try {
      if (isAmexPayWithPointsAvailable) dispatch(resetAmexPoints());

      const response = await mutatePaymentCard(formData);
      if (response.error) {
        const apiError = new Error(response.error.message);
        // @ts-ignore
        apiError.isInformational = true;
        throw apiError;
      }

      const paymentDetails = response.data?.payment?.details;
      const paymentCardId = isPaymentCard(paymentDetails) ? paymentDetails.paymentCardId : null;
      if (!paymentCardId) {
        throw new Error('No paymentCardId available after saving payment card');
      }

      await routeNext({ beforeNavigation: collapseAccordion });
    } catch (err) {
      window.Sentry?.captureException?.(new Error(err));
      setAddCardErrorType(err.message || 'Unknown error');
    }
  };

  const selectSavedCard = async (paymentCardId: string) => {
    if (paymentCardId !== selectedPaymentCard?.paymentCardId) {
      if (hasAmexPWPAppliedPoints) dispatch(resetAmexPoints());

      const response = await updateSelectedCard({ selectedPaymentCardId: paymentCardId });
      if ('error' in response) {
        throw response.error;
      }
      window.heap?.addEventProperties?.({ selectedPaymentType: 'paymentcard' });
    }
    await routeNext({ beforeNavigation: collapseAccordion });
  };

  const onSubmit = handleSubmit(async formData => {
    try {
      const { paymentType, ...paymentCard } = formData;
      setIsRecoverableError(false);
      setAddCardErrorType(null);

      if (paymentType.match(/SavedCard/)) return await selectSavedCard(paymentType.split('|')[1]);

      if (paymentType === PaymentType.PaymentCard) return await submitCardData(paymentCard);

      const response = await mutatePaymentMethod({ selectedPaymentType: paymentType });
      if ('error' in response) {
        throw response.error;
      }
      if (hasAmexPWPAppliedPoints) dispatch(resetAmexPoints());
      await routeNext({ beforeNavigation: collapseAccordion });
    } catch (error) {
      setIsRecoverableError(true);
    }
  });

  return (
    <>
      {errors.paymentType?.type === 'required' && (
        <Alert alertType="amber" qaSelector="alertRecoverableError">
          {errors.paymentType.message}
        </Alert>
      )}
      {isRecoverableError && <AlertRecoverableError />}
      {addCardErrorType && <AlertAddCardPaymentRecoverableError errorType={addCardErrorType ?? undefined} />}
      <FormProvider {...formMethods}>
        <AccordionPages collapse={isAccordionCollapsed} pageLoading={pageLoading}>
          <div className="donate-main select-payment">
            {isAmountLocked && <FixedAmount />}

            <form noValidate action="post" onSubmit={onSubmit}>
              <PageHeading>{t('selectPayment|heading', { ns: 'payment' })}</PageHeading>
              <PaymentMethodsRadios />
              {paymentType !== PaymentType.PaymentCard && (
                <Button
                  className={sprinkles({ marginTop: 'sizeSpacing06' })}
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  isLoading={isSubmitting}
                  qaSelector="selectPaymentPageContinueButton"
                >
                  {t('continueLabel')} <VisuallyHidden>{t('continueLabelAccessibleExtension')}</VisuallyHidden>
                </Button>
              )}
            </form>
          </div>
        </AccordionPages>
      </FormProvider>
    </>
  );
};

export default SelectPaymentPage;
