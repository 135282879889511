import { useWatch } from 'react-hook-form';

import { AmountPageFormValues } from '../../../types/formValues';
import { useFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLMinAndMaxAmountsForCurrencyInPounds } from '../redux/checkoutApiSlice/selectors/order.selectors';

const FALLBACK_MINIMUM_DONATION_AMOUNT_IN_POUNDS = 2;
const FALLBACK_MAXIMUM_DONATION_AMOUNT_IN_POUNDS = 100000;

type MinMax = {
  min: number;
  max: number;
};

export const useMinMaxDonationAmounts = (): MinMax => {
  const { data } = useFetchCheckoutQuery();
  const donationCurrencyCode = useWatch<AmountPageFormValues, 'donationCurrencyCode'>({
    name: 'donationCurrencyCode',
  });
  const { minimum, maximum } = getGQLMinAndMaxAmountsForCurrencyInPounds(data, donationCurrencyCode) ?? {};

  const min = minimum ?? FALLBACK_MINIMUM_DONATION_AMOUNT_IN_POUNDS;
  const max = maximum ?? FALLBACK_MAXIMUM_DONATION_AMOUNT_IN_POUNDS;

  return { min, max };
};
