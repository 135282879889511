import bankersRounding from 'bankers-rounding';

import { AcceptedCurrencyCode, TipScheme, TransactionStatus } from '../../../types/__generated-graphQL__';

export const mapTipSchemeToLinkServiceFormat = (tipScheme?: TipScheme | null): string | null => {
  switch (tipScheme) {
    case TipScheme.TipJar2_1: {
      return 'TipJar2.1';
    }
    case TipScheme.TipJar2_3: {
      return 'TipJar2.3';
    }
    case TipScheme.Dpf1_2: {
      return 'Dpf1.2';
    }
    case TipScheme.Disabled: {
      return 'Disabled';
    }
    default: {
      return tipScheme ?? null;
    }
  }
};

export const convertPoundsToPennies = (value: number | null): number => {
  return value ? bankersRounding(value * 100) : 0;
};

export const convertPenniesToPounds = (value: number | null): number => {
  return value ? Math.floor(value) / 100 : 0;
};

export const mapTransactionStatusToDonationsApiStatus = (status: TransactionStatus): DonationStatus => {
  switch (status) {
    case TransactionStatus.Succeeded:
      return 'succeeded';
    case TransactionStatus.Pending:
      return 'pending';
    case TransactionStatus.Declined:
      return 'invalid';
  }
};

export const stringToAcceptedCurrencyCodeMap = (currencyCode: string | undefined): AcceptedCurrencyCode | undefined => {
  switch (currencyCode?.toUpperCase()) {
    case 'AUD': {
      return AcceptedCurrencyCode.Aud;
    }
    case 'CAD': {
      return AcceptedCurrencyCode.Cad;
    }
    case 'EUR': {
      return AcceptedCurrencyCode.Eur;
    }
    case 'GBP': {
      return AcceptedCurrencyCode.Gbp;
    }
    case 'NZD': {
      return AcceptedCurrencyCode.Nzd;
    }
    case 'USD': {
      return AcceptedCurrencyCode.Usd;
    }
    default: {
      return;
    }
  }
};
