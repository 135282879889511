import { useWatch } from 'react-hook-form';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../types/formValues';
import { CONSTANTS } from '../../config/constants';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLSuggestedAmountsForCurrencyInPounds } from '../../redux/checkoutApiSlice/selectors/order.selectors';

export const useGetSuggestedAmountValues = () => {
  const donationCurrencyCode = useWatch<AmountPageFormValues, 'donationCurrencyCode'>({ name: 'donationCurrencyCode' });

  const { data } = useFetchCheckoutQuery();
  const suggestedAmountsForCurrencyInPounds = getGQLSuggestedAmountsForCurrencyInPounds(data, donationCurrencyCode);
  const orderType = getGQLOrderType(data);

  if (orderType === LineItemType.DonationCrowdfunding) {
    return CONSTANTS.CROWDFUNDING_SUGGESTED_AMOUNTS.VALUES;
  }
  if (suggestedAmountsForCurrencyInPounds.length === 0) return null;
  return suggestedAmountsForCurrencyInPounds.map(suggestedAmount => suggestedAmount.value) as number[];
};
