import React, { FunctionComponent, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Label from '../../components/Form/Label/Label';
import Select from '../../components/Form/Select/Select';
import { FormError } from '../../components/FormError/FormError';
import { getCountryOptionsList } from '../../utils/getCountry';

type CountryData = { value: string; label: string };
type Props = { formPrefix?: string };

const AddressLookUpCountryList: FunctionComponent<Props> = ({ formPrefix = '' }) => {
  const { t } = useTranslation();
  const { setValue, clearErrors } = useFormContext<any>();
  const countryOptions: CountryData[] = getCountryOptionsList();

  const options = useMemo(
    () =>
      countryOptions.map(({ label, value }: CountryData, i) => (
        <option key={`country${i}-${value}`} value={value}>
          {label}
        </option>
      )),
    [countryOptions],
  );

  return (
    <>
      <Label htmlFor="address-lookup-countryList">{t('countryLabel', { ns: 'addressLookup' })}</Label>
      <Select
        id="address-lookup-countryList"
        data-qa="address-lookup-countryList"
        name={`${formPrefix}.address.country`}
        registerMethods={{
          onChange: _countryCode => {
            setValue(`${formPrefix}.address.countyStateRegion`, '');
            clearErrors(`${formPrefix}.address.countyStateRegion`);
          },
          required: { value: true, message: t('country|mandatory', { ns: 'validation' }) },
        }}
        required
      >
        <option value="">{t('selectListPlaceholder')}</option>
        {options}
      </Select>
      <FormError<any> fieldName={`${formPrefix}.address.country`} />
    </>
  );
};

export default AddressLookUpCountryList;
