import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import { FormError } from '../../components/FormError/FormError';
import { isNotPan } from '../../utils/isNotPAN';

const PaymentNameOnCard: FunctionComponent = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Label htmlFor="nameOnCard">{t('paymentCard|nameOnCard|label', { ns: 'payment' })}</Label>
      <Input
        name="nameOnCard"
        id="nameOnCard"
        autoCorrect="off"
        autoComplete="off"
        spellCheck={false}
        required
        registerMethods={{
          setValueAs: value => value?.trim(),
          required: { value: true, message: t('paymentCard|nameOnCard|requiredError', { ns: 'validation' }) },
          minLength: { value: 2, message: t('paymentCard|nameOnCard|minError', { ns: 'validation' }) },
          maxLength: { value: 30, message: t('paymentCard|nameOnCard|maxError', { ns: 'validation' }) },
          validate: {
            isNotPAN: value => isNotPan(value, t('paymentCard|nameOnCard|requiredError', { ns: 'validation' })),
          },
        }}
      />
      <FormError<AddPaymentCardPageFormValues> fieldName="nameOnCard" />
    </>
  );
});

export default PaymentNameOnCard;
