import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { CONSTANTS } from '../../config/constants';
import Alert from './Alert';

type Props = {
  errorType?: string;
};

const AlertAddCardPaymentRecoverableError: FunctionComponent<Props> = ({ errorType }) => {
  const { t } = useTranslation();

  if (errorType === CONSTANTS.CARD_VERIFICATION_DECLINED) {
    return (
      <Alert qaSelector="alertPaymentRecoverableHTMLErrorZeroAuthHardDecline" alertType="amber">
        {t('paymentCard|zeroAuthHardDecline', { ns: 'validation' })}
      </Alert>
    );
  }

  return (
    <Alert qaSelector="alertPaymentRecoverableError" alertType="amber">
      {t('paymentCardUnavailable', { ns: 'alert' })}
    </Alert>
  );
};

export default AlertAddCardPaymentRecoverableError;
