import React, { FunctionComponent } from 'react';

import classNames from 'classnames';

import * as styles from './AnimatedChevron.css';

type AnimatedChevronProps = {
  isOpen?: boolean;
};

export const AnimatedChevron: FunctionComponent<AnimatedChevronProps> = ({ isOpen = false }) => {
  return (
    <div className={styles.container}>
      {[styles.leftWing, styles.rightWing].map(wingClassName => (
        <div className={wingClassName} key={wingClassName}>
          <div
            data-qa="wingWrapper"
            className={classNames(styles.wing, {
              [styles.wingOpen]: isOpen,
            })}
          >
            <svg viewBox="0 0 12 12" data-qa="wing" className={isOpen ? styles.lineDark : styles.lineLight}>
              <path d="M 0 6 L 6 6" />
            </svg>
          </div>
        </div>
      ))}
    </div>
  );
};
