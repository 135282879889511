export const getValidUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');
  if (new RegExp('^://').test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!new RegExp('^(ht)tps?://', 'i').test(newUrl)) {
    return `http://${newUrl}`;
  }
  return newUrl;
};
