import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { sprinkles } from '../../../styles/sprinkles.css';
import Label from '../../components/Form/Label/Label';
import Select from '../../components/Form/Select/Select';
import { FormError } from '../../components/FormError/FormError';
import { USStatesSelectList } from '../../config/country/USStates.ISO3166-2';
import { canadaProvincesSelectList } from '../../config/country/canadaProvinces.ISO3166-2';

type Props = { name: string; countryCode: 'US' | 'CA' };

const AddressLookupCountyStateRegion: FunctionComponent<Props> = ({ name, countryCode }) => {
  const { t } = useTranslation();
  const isUS = countryCode === 'US';
  const options = useMemo(() => {
    const optionsData = isUS ? USStatesSelectList : canadaProvincesSelectList;
    return optionsData.map(({ label, value }, i) => (
      <option key={`country${i}-${value}`} value={value}>
        {label}
      </option>
    ));
  }, [isUS]);

  return (
    <div className={sprinkles({ marginBottom: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}>
      <Label htmlFor={name}>{t(`billingAddress|${isUS ? 'state' : 'province'}Label`, { ns: 'payment' })}</Label>
      <Select
        id={name}
        name={name}
        registerMethods={{
          required: {
            value: true,
            message: t(`billingAddress|${isUS ? 'state' : 'province'}|mandatory`, { ns: 'validation' }),
          },
        }}
        required
      >
        <option value="">{t('selectListPlaceholder')}</option>
        {options}
      </Select>
      <FormError fieldName={name} />
    </div>
  );
};

export default AddressLookupCountyStateRegion;
