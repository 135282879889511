import { Auth } from '@justgiving/auth';

import APP_SETTINGS from '../../config';

export const manageAnalyticsCookie = (auth: Auth) => {
  const domain = 'domain=.justgiving.com;';
  const path = `path=/;`;
  const maxAge = `max-age=${60 * 60 * 24 * 365 * 10};`; // 10 Years
  if (!document.cookie.includes(`${APP_SETTINGS.ANALYTICS_COOKIE_NAME}=`)) {
    document.cookie = `${APP_SETTINGS.ANALYTICS_COOKIE_NAME}=logged_in_guid=${getLoggedInGuid(
      auth,
    )}&logged_out_guid=${generateLoggedOutGUID()};${domain}${path}${maxAge}`;
  }
};

const getLoggedInGuid = (auth: Auth): string | undefined => {
  if (auth.isUserLoggedIn()) {
    return auth.getAccessToken()?.guid;
  }
  return '00000000-0000-0000-0000-000000000000';
};

const generateLoggedOutGUID = (): string => {
  const lut = [];
  for (let i = 0; i < 256; i++) {
    lut[i] = (i < 16 ? '0' : '') + i.toString(16);
  }

  const d0 = (Math.random() * 0xffffffff) | 0;
  const d1 = (Math.random() * 0xffffffff) | 0;
  const d2 = (Math.random() * 0xffffffff) | 0;
  const d3 = (Math.random() * 0xffffffff) | 0;
  return `${lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]}-${
    lut[d1 & 0xff]
  }${lut[(d1 >> 8) & 0xff]}-${lut[((d1 >> 16) & 0x0f) | 0x40]}${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${
    lut[(d2 >> 8) & 0xff]
  }-${lut[(d2 >> 16) & 0xff]}${lut[(d2 >> 24) & 0xff]}${lut[d3 & 0xff]}${lut[(d3 >> 8) & 0xff]}${
    lut[(d3 >> 16) & 0xff]
  }${lut[(d3 >> 24) & 0xff]}`;
};
