import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-tag';

import { TransactionStatus, TransactionStatusInput } from '../../../../types/__generated-graphQL__';
import { httpErrorMessage } from '../../config/httpErrorMessage';
import { getLoginURL } from '../../utils/getLoginURL';
import { graphqlRequestBaseQuery } from '../../utils/graphqlRequestBaseQuery';
import { updateErrorType } from '../session/session.actions';
import { getIsEmbedded } from '../session/session.selectors';

const withRetry = retry((args, api, extraOptions) => graphqlRequestBaseQuery(args, api, { ...extraOptions }), {
  retryCondition: (error: BaseQueryError<any>, _args, extraArgs) => {
    const state = extraArgs.baseQueryApi.getState() as State;
    const isEmbedded = getIsEmbedded(state);
    const loginURL = getLoginURL(isEmbedded);

    if (error.status === 401 && error.message === httpErrorMessage.UNAUTHORIZED_RECOVERABLE) {
      if (extraArgs.attempt <= 1) {
        return true;
      }
      window.location.assign(loginURL);
    }
    if (error.status === 401 && error.message === httpErrorMessage.UNAUTHORIZED_NOT_RECOVERABLE) {
      extraArgs.baseQueryApi.dispatch(updateErrorType('GuestAuthFailure'));
    }
    return false;
  },
});

export const transactionStatusApiSlice = createApi({
  reducerPath: 'transactionStatusApi',
  baseQuery: withRetry,
  tagTypes: ['TransactionStatus'],
  endpoints: builder => ({
    queryTransationStatus: builder.query<TransactionStatus, TransactionStatusInput>({
      query(data) {
        return {
          variables: {
            input: data,
          },
          document: gql`
            query transactionStatus($input: TransactionStatusInput!) {
              transactionStatus(input: $input)
            }
          `,
        };
      },
      transformResponse: (response: { transactionStatus: TransactionStatus }) => response.transactionStatus,
    }),
  }),
});

export const useLazyQueryTransationStatusQuery = transactionStatusApiSlice.useLazyQueryTransationStatusQuery;
