import { CardType } from '../../../types/__generated-graphQL__';

export const cardTypeToCardNameMap = (cardType: CardType): string => {
  switch (cardType) {
    case CardType.AmericanExpress: {
      return 'American Express';
    }
    case CardType.Mastercard: {
      return 'Mastercard';
    }
    case CardType.Visa: {
      return 'Visa';
    }
    default: {
      return cardType;
    }
  }
};

export const libraryCardTypeToStateCardTypeMap = (cardType: string): CardType | null => {
  switch (cardType) {
    case 'american-express': {
      return CardType.AmericanExpress;
    }
    case 'maestro':
    case 'mastercard': {
      return CardType.Mastercard;
    }
    case 'visa': {
      return CardType.Visa;
    }
    default: {
      return null;
    }
  }
};
