export type AmexAppliedPoints = {
  appliedPoints: number;
  pointCurrencyValue: number;
};

export const updateAmexPoints = (appliedPoints: AmexAppliedPoints) =>
  ({
    type: 'amexPWP/updateAppliedPoints',
    appliedPoints,
  }) as const;

export const resetAmexPoints = () =>
  ({
    type: 'amexPWP/resetAppliedPoints',
  }) as const;

export type AmexpayWithPointsActionTypes = ReturnType<typeof updateAmexPoints> | ReturnType<typeof resetAmexPoints>;
