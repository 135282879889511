import React, { FunctionComponent, ReactNode } from 'react';

import classNames from 'classnames';

import * as styles from './AccordionPageCurrentStep.css';

type AccordionPageCurrentStepProps = {
  children: ReactNode;
  collapsed?: boolean;
};

export const AccordionPageCurrentStep: FunctionComponent<AccordionPageCurrentStepProps> = ({ children, collapsed }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.collapsed]: collapsed })}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
