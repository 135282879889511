import APP_SETTINGS from '../../config';

type EncodedOptions = {
  isEmbedded?: boolean;
};

export const getLoginURL = (isEmbedded: boolean) => {
  const { LOGIN_URL, DONATION_APP_URL } = APP_SETTINGS;
  const returnUrl = encodeURIComponent(`${DONATION_APP_URL}${window.location.search}`);
  const options: EncodedOptions = {};
  let encodedOptionsQueryParams = '';

  if (isEmbedded) {
    options.isEmbedded = true;
  }
  if (Object.keys(options).length > 0) {
    encodedOptionsQueryParams = `&encodedOptions=${btoa(JSON.stringify(options))}`;
  }
  return `${LOGIN_URL}${returnUrl}${encodedOptionsQueryParams}`;
};

export const getSignUpURL = (isEmbedded: boolean) => {
  const { SIGN_UP_URL, DONATION_APP_URL } = APP_SETTINGS;
  const returnUrl = encodeURIComponent(`${DONATION_APP_URL}${window.location.search}`);
  const options: EncodedOptions = {};
  let encodedOptionsQueryParams = '';

  if (isEmbedded) {
    options.isEmbedded = true;
  }
  if (Object.keys(options).length > 0) {
    encodedOptionsQueryParams = `&encodedOptions=${btoa(JSON.stringify(options))}`;
  }

  return `${SIGN_UP_URL}${returnUrl}${encodedOptionsQueryParams}`;
};
