const luhnChecksum = (pan: string) =>
  pan
    .split('')
    .reverse()
    .map((x, i) => (i % 2 ? Number(x) * 2 : Number(x)))
    .map(x => (x > 9 ? x - 9 : x))
    .reduce((a, c) => a + c, 0) %
    10 ===
  0; // eslint-disable-line

export const isValidLuhn = (pan?: string | null): boolean => {
  const trimmedPan = (pan || '').replace(/\s/g, '');
  const result = !trimmedPan || !/^\d+$/.test(trimmedPan) || luhnChecksum(trimmedPan);
  return result;
};
