import React, { FunctionComponent, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import { FormError } from '../../components/FormError/FormError';

const PaymentCardExpired: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setError, clearErrors } = useFormContext<AddPaymentCardPageFormValues>();
  const [cardExpiryMonth, cardExpiryYear] = useWatch<
    AddPaymentCardPageFormValues,
    ['cardExpiryMonth', 'cardExpiryYear']
  >({ name: ['cardExpiryMonth', 'cardExpiryYear'] });

  useEffect(() => {
    if (!cardExpiryMonth || !cardExpiryYear) return;

    const currentDate = new Date();
    const currentYear =
      cardExpiryYear.length === 2 ? Number(currentDate.getFullYear().toString().slice(-2)) : currentDate.getFullYear();
    if (Number(cardExpiryYear) === currentYear && Number(cardExpiryMonth) < currentDate.getMonth() + 1) {
      setError('cardExpiryMonthAndYear', {
        type: 'manual',
        message: t('paymentCard|invalidMonthAndYear', { ns: 'validation' }),
      });
    } else {
      clearErrors('cardExpiryMonthAndYear');
    }
  }, [cardExpiryMonth, cardExpiryYear, setError, clearErrors, t]);
  return <FormError<AddPaymentCardPageFormValues> fieldName="cardExpiryMonthAndYear" />;
};

export default PaymentCardExpired;
