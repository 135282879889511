import { useLocation } from 'react-router-dom';

import { PAGES } from '../config/pages';

export const usePageName = (): PageName | null => {
  const { pathname } = useLocation();
  return (
    (Object.keys(PAGES) as (keyof typeof PAGES)[]).find(
      key => PAGES[key] === (pathname?.split('/').pop() ?? pathname),
    ) ?? null
  );
};
