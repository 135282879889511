import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { heading } from '../../../styles/recipes/headings.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useHeadingSetFocusRef } from '../../hooks/useHeadingSetFocusRef';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityOrPageOwnerName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLDirectDebitMandateReference } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLTransactionReference } from '../../redux/checkoutApiSlice/selectors/transaction.selectors';
import { separateCharacters } from '../../utils/separateCharacters';
import { formatCurrency } from '../../utils/value';

type Props = {
  donationStatus: DonationStatus;
};

const DonationStatusHeading: FunctionComponent<Props> = memo(({ donationStatus }) => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();
  const headingRef = useHeadingSetFocusRef();

  const directDebitMandateReference = getGQLDirectDebitMandateReference(data);
  const transactionReference = getGQLTransactionReference(data);
  const currencyCode = getGQLCurrencyCode(data);
  const charityOrPageOwnerName = getGQLCharityOrPageOwnerName(data);
  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);

  const headingTitle = t(`${donationStatus}|heading`, { ns: 'status' });
  const { setTitle } = useDocumentTitle(headingTitle);

  setTitle(headingTitle);

  return (
    <div role="status">
      <h1 ref={headingRef} tabIndex={-1} data-qa={`heading-1-${donationStatus}`} className={heading({ level: 1 })}>
        {headingTitle}
        {directDebitMandateReference && (
          <VisuallyHidden>
            {t('donationReferenceLabel', {
              ns: 'recurring',
              reference: separateCharacters(directDebitMandateReference),
            })}
            .
          </VisuallyHidden>
        )}
        {transactionReference && (
          <VisuallyHidden>
            {t('donationReferenceLabel', { reference: separateCharacters(`D${transactionReference}`) })}.
          </VisuallyHidden>
        )}
      </h1>
      <p data-qa={`heading-2-${donationStatus}`} className={sprinkles({ marginTop: 'sizeSpacing05', color: 'light' })}>
        {t(`${donationStatus}|subHeading`, { ns: 'status' })}
        {donationStatus === 'succeeded' ? (
          <>
            <strong className={sprinkles({ color: 'themed' })}>
              {formatCurrency(currencyCode, lineItemValueInPounds)}
            </strong>
            {t('succeeded|subHeading2', { causeName: charityOrPageOwnerName, ns: 'status' })}
            <br />
            {t('succeeded|subHeading3', { ns: 'status' })}{' '}
          </>
        ) : (
          <></>
        )}
      </p>
    </div>
  );
});

export default DonationStatusHeading;
