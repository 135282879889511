import React, { ReactNode } from 'react';

import classNames from 'classnames';

import * as styles from './InfoBox.css';

type Props = {
  children: ReactNode;
  type?: 'heart' | 'info' | 'handsAndHeart' | 'star';
  noMarginTop?: boolean;
  alignCenter?: boolean;
};

export const InfoBox = ({ children, type = 'heart', noMarginTop = false, alignCenter = false }: Props) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.typeInfo]: type === 'info',
        [styles.typeHandsAndHeart]: type === 'handsAndHeart',
        [styles.typeStar]: type === 'star',
        [styles.noMarginTop]: noMarginTop,
        [styles.alignCenter]: alignCenter,
      })}
    >
      <div className={styles.logo} />
      <div className={styles.copy}>{children}</div>
    </div>
  );
};
