import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './SkipLink.css';

export const SkipLink: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <a className={styles.skipLink} href="#maincontent">
      {t('skipToMain')}
    </a>
  );
};
