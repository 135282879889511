import React, { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext, get as getCurrentError } from 'react-hook-form';

import classNames from 'classnames';

import * as styles from './Input.css';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  name: string;
  registerMethods?: RegisterOptions;
  isExternalError?: boolean;
};

const Input: FunctionComponent<Props> = ({
  className,
  registerMethods,
  name,
  type = 'text',
  isExternalError = false,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const isError = Boolean(getCurrentError(errors, name)) || isExternalError;

  return (
    <input
      type={type}
      className={classNames(className, styles.input, { 'is-error': isError })}
      {...register(name, registerMethods)}
      aria-invalid={isError}
      {...props}
    />
  );
};

export default Input;
