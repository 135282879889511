import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { sprinkles } from '../../../styles/sprinkles.css';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLGiftAidIsClaimed, getGQLGiftAidValue } from '../../redux/checkoutApiSlice/selectors/giftAid.selectors';
import { getGQLCurrencyCode } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { formatCurrency } from '../../utils/value';

const GiftAidEstimatedAmount: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data } = useFetchCheckoutQuery();

  const giftAidIsClaimed = getGQLGiftAidIsClaimed(data);
  const estimatedAmount = getGQLGiftAidValue(data);
  const currencyCode = getGQLCurrencyCode(data);
  const charityName = getGQLCharityName(data);

  if (!giftAidIsClaimed) return null;

  const giftAidamountWording = `${t('thankyou|thanksGiftAidMessage', {
    ns: 'giftAid',
    causeName: charityName,
  })} ${formatCurrency(currencyCode, estimatedAmount)} ${t('thankyou|plusGiftAidMessage', { ns: 'giftAid' })}`;

  return (
    <>
      <VisuallyHidden as="p">{giftAidamountWording}</VisuallyHidden>
      <p
        data-qa="donation-gift-aid"
        className={sprinkles({ color: 'light', marginTop: 'sizeSpacing04' })}
        aria-hidden="true"
      >
        {t('thankyou|thanksGiftAidMessage', { ns: 'giftAid', causeName: charityName })}{' '}
        <strong className={sprinkles({ color: 'default' })}>
          {formatCurrency(currencyCode, estimatedAmount)} {t('thankyou|plusGiftAidMessage', { ns: 'giftAid' })}
        </strong>
      </p>
    </>
  );
};

export default GiftAidEstimatedAmount;
