import React, { FunctionComponent, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import Select from '../../components/Form/Select/Select';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { RECURRENCE_DAYS_OF_MONTH } from '../../config/recurrenceDaysOfMonth';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { formatCurrency } from '../../utils/value';
import * as styles from './RecurringCardPayments.css';
import { useIsRecurringPaymentAvailable } from './useRecurringAvailable';

const RecurringCardPayments: FunctionComponent = () => {
  const [donationCurrencyCode, donationValue, totalAmountToPay, transactionType] = useWatch<
    AmountPageFormValues,
    ['donationCurrencyCode', 'donationValue', 'totalAmountToPay', 'transactionType']
  >({
    name: ['donationCurrencyCode', 'donationValue', 'totalAmountToPay', 'transactionType'],
  });

  const { data } = useFetchCheckoutQuery();
  const isCrowdfundingCause = getGQLOrderType(data) === LineItemType.DonationCrowdfunding;

  const { t } = useTranslation();

  const recurrenceSelectList: JSX.Element[] = useMemo(
    () =>
      RECURRENCE_DAYS_OF_MONTH.map(({ label, value }) => (
        <option key={value} value={value} title={`${label} ${t('everyMonth', { ns: 'recurring' })}`}>
          {label}
        </option>
      )),
    [t],
  );

  const { isRecurringCardAvailable } = useIsRecurringPaymentAvailable();

  if (transactionType !== TransactionTypes.RECURRING || !isRecurringCardAvailable) return null;

  return (
    <div data-qa="recurrence-day-selector">
      <div className={styles.wrapper}>
        <span aria-hidden className={sprinkles({ color: 'light' })}>
          {t('takenOn', { ns: 'recurring' })}
        </span>
        <label htmlFor="recurringDonationsDates" className="recurrence-day-selector__select">
          <VisuallyHidden>{t('datesLegend', { ns: 'recurring' })}</VisuallyHidden>
          <Select name="recurrenceDayOfMonth" data-qa="recurringDonationsDates" id="recurringDonationsDates">
            {recurrenceSelectList}
          </Select>
        </label>
        <span aria-hidden className={sprinkles({ color: 'light' })}>
          {t('everyMonth', { ns: 'recurring' })}
        </span>
      </div>

      {isCrowdfundingCause && (
        <p data-qa="crowdfunding-recurring-donations-message" className={sprinkles({ marginV: 'sizeSpacing05' })}>
          <strong>{t('informationBold', { ns: 'recurring' })}</strong>{' '}
          {t('informationMessage', {
            totalAmountToPay: formatCurrency(donationCurrencyCode, totalAmountToPay || donationValue),
            ns: 'recurring',
          })}
        </p>
      )}
    </div>
  );
};

export default RecurringCardPayments;
