import APP_SETTINGS from '../../config';
import { IMAGE_TEMPLATES } from '../config/imageTemplates';

const buildAvatarUrl = (avatar?: String | null, isLogo?: boolean) => {
  // No avatar so return empty string
  if (!avatar) {
    return '';
  }

  const templateString = isLogo ? IMAGE_TEMPLATES.Logo : IMAGE_TEMPLATES.Avatar;

  // Avatar is absolute so just add the template string
  if (avatar.indexOf('://') !== -1) {
    return `${avatar}?template=${templateString}`;
  }

  // Avatar is relative so construct full url
  return `${APP_SETTINGS.IMAGE_SERVICE_URL}/image/${avatar}?template=${templateString}`;
};

export default buildAvatarUrl;
