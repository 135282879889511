import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';

import classNames from 'classnames';

import { Cryptocurrency } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import { colors } from '../../../styles/variables/tokens.css';
import { Image } from '../../components/Image/Image';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';
import * as styles from './Cryptocurrency.css';
import { calculateCryptoAmount } from './cryptoUtils';

type CryptocurrencySelectProps = {
  cryptocurrencyData: Cryptocurrency[];
  focusOnMount: boolean;
};

type SelectEntry = {
  value: string;
  label: string;
  currencyName: string;
  logoUrl: string;
  exchangeRate: number;
};

export const CryptocurrencySelect: FunctionComponent<CryptocurrencySelectProps> = ({
  cryptocurrencyData,
  focusOnMount,
}) => {
  const { t } = useTranslation();
  const { control, setValue, trigger, getValues } = useFormContext<AmountPageFormValues>();

  const options: SelectEntry[] = cryptocurrencyData.map(crypto => ({
    value: crypto.shortCode,
    label: `${crypto.name} (${crypto.shortCode})`,
    currencyName: crypto.name,
    logoUrl: crypto.logoUrl,
    exchangeRate: crypto.exchangeRate,
  }));

  const handleChange = (newCrypto?: SelectEntry) => {
    const fiatAmount = getValues('crypto.fiatAmount');
    setValue('crypto.currencyFullName', newCrypto?.currencyName ?? '');
    setValue('crypto.currencyLogoUrl', newCrypto?.logoUrl ?? '');
    setValue('crypto.estimatedExchangeRate', newCrypto?.exchangeRate.toString() ?? '');
    setValue('crypto.cryptoAmount', newCrypto ? calculateCryptoAmount(fiatAmount, newCrypto.exchangeRate) : '');
  };

  return (
    <label htmlFor={'crypto.currencyShortName'} className={styles.label}>
      {t('selectYourCryptocurrency', { ns: 'crypto' })}
      <Controller<AmountPageFormValues>
        name="crypto.currencyShortName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            aria-label={t('selectYourCryptocurrency', { ns: 'crypto' })}
            id="crypto.currencyShortName"
            value={options.find(option => option.value === value)}
            onChange={newValue => {
              onChange((newValue as SelectEntry)?.value);
              handleChange(newValue as SelectEntry);
              trigger('crypto.fiatAmount');
            }}
            autoFocus={focusOnMount}
            components={{ Option, SingleValue }}
            isClearable
            options={options}
            classNames={{
              container: () => styles.container,
              control: () => styles.control,
              option: () => styles.option,
              singleValue: () => styles.option,
            }}
            styles={{
              clearIndicator: (baseStyles, _state) => ({
                ...baseStyles,
                color: colors.nobel,
                marginRight: 6,
                cursor: 'pointer',
              }),
              dropdownIndicator: (baseStyles, _state) => ({
                ...baseStyles,
                color: colors.nobel,
                marginLeft: 6,
                marginRight: 6,
                cursor: 'pointer',
              }),
            }}
            placeholder={t('cryptoSelectPlaceholder', { ns: 'crypto' })}
          />
        )}
      />
    </label>
  );
};

const OptionContent: FunctionComponent<SelectEntry> = ({ logoUrl, label }) => {
  return (
    <>
      <Image src={logoUrl} alt="" className={styles.selectLogo} width={24} height={24} />
      <span>{label}</span>
    </>
  );
};

const Option: FunctionComponent<OptionProps<SelectEntry>> = props => {
  return (
    <components.Option {...props}>
      <OptionContent {...props.data} />
    </components.Option>
  );
};

const SingleValue: FunctionComponent<SingleValueProps<SelectEntry>> = props => {
  return (
    <components.SingleValue {...props}>
      <OptionContent {...props.data} />
    </components.SingleValue>
  );
};

export const CryptocurrencySelectLoader: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={classNames(styles.label, sprinkles({ marginTop: 'sizeSpacing04' }))}>
        {t('selectYourCryptocurrency', { ns: 'crypto' })}
      </div>
      <div className={styles.loaderWrapper}>
        <div className={styles.loaderContent}>
          <SkeletonLoader width="24px" height="24px" className={styles.logoLoader} />
          <SkeletonLoader animationDelay="0.15s" />
        </div>
      </div>
    </>
  );
};
