import { Auth } from '@justgiving/auth';

export const getAuthHeader = (auth: Auth): string | null => {
  const accessToken = auth?.getParsedAuthCookie()?.accessToken;

  if (accessToken && typeof accessToken === 'string') {
    return `Bearer ${accessToken}`;
  }

  return null;
};
