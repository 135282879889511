import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { PaymentType } from '../../../../types/__generated-graphQL__';
import { SelectPaymentTypeFormValues } from '../../../../types/formValues';
import APP_SETTINGS from '../../../config';
import { paymentIcon } from '../../../styles/icons.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import Radio from '../../components/Form/Radio/Radio';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import Alert from '../../components/alert/Alert';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { HACK_isAwardsPage, HACK_isAwardsTeam, HACK_isGoCardlessStaffPage } from '../../config/hacks';
import { useFetchPageQuery } from '../../redux/additionalPageDataApiSlice/additionalPageDataApiSlice';
import { getGQLAdditionalPageDataParentTeamName } from '../../redux/additionalPageDataApiSlice/selectors/parents.selectors';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLLegacyCharityId } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLPage, getGQLSourceUrl } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLSelectedPaymentType } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import AddPaymentCardForm from '../AddPaymentCard/AddPaymentCardForm';
import AcceptedPaymentCardsInline from '../Payment/AcceptedPaymentCardsInline';
import { usePaymentTypesAvailable } from '../Payment/usePaymentTypesAvailable';
import SavedPaymentCardsRadios from '../SavedPaymentCards/SavedPaymentCardsRadios';
import GoCardlessTeamsPageRadio from './GoCardlessTeamsPageRadio';
import * as styles from './paymentMethodsRadios.css';

type Props = {};
export const PaymentMethodsRadios: FunctionComponent<Props> = () => {
  const {
    isApplePayAvailable,
    isGooglePayAvailable,
    isPayPalAvailable,
    isVenmoAvailable,
    isGoCardlessInstantBankPaymentAvailable,
  } = usePaymentTypesAvailable();

  const { t } = useTranslation();

  const {
    formState: { isSubmitting: isDisabled },
  } = useFormContext<SelectPaymentTypeFormValues>();
  const paymentType = useWatch({ name: 'paymentType' });

  const { data } = useFetchCheckoutQuery();
  const selectedPaymentType = getGQLSelectedPaymentType(data);
  const charityId = getGQLLegacyCharityId(data);
  const isCardPaymentDisabled = APP_SETTINGS.DISABLE_PAYMENT_CARD && charityId !== '2050';

  const page = getGQLPage(data);
  const isGcTeam = HACK_isGoCardlessStaffPage(page?.slug);

  const sourceUrl = getGQLSourceUrl(data);
  const isAwardsPage = HACK_isAwardsPage(sourceUrl);

  const { data: additionalPageData } = useFetchPageQuery();
  const parentTeamName = getGQLAdditionalPageDataParentTeamName(additionalPageData);
  const isAwardsTeam = HACK_isAwardsTeam(parentTeamName);

  const isAwardsOrGcPage = isGcTeam || isAwardsPage || isAwardsTeam;

  return (
    <>
      {isCardPaymentDisabled && (
        <Alert alertType="amber">
          <p>{t('selectPayment|cardPaymentUnavailable', { ns: 'alert' })}</p>
        </Alert>
      )}
      <VisuallyHidden as="h2" id="payment-list">
        {t('selectPayment|choose', { ns: 'payment' })}
      </VisuallyHidden>
      <ul className={styles.radioList} aria-describedby="payment-list" data-qa="payment-list">
        {isGoCardlessInstantBankPaymentAvailable && isAwardsOrGcPage && (
          <li className={styles.listItem}>
            <GoCardlessTeamsPageRadio recommended icon={isAwardsPage || isAwardsTeam ? 'bank' : 'goCardless'} />
          </li>
        )}
        {!isCardPaymentDisabled && (
          <li className={styles.listItem}>
            <Radio
              name="paymentType"
              value={PaymentType.PaymentCard}
              disabled={isDisabled}
              id="addNewCard"
              data-qa="addNewCard"
              registerMethods={{
                required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
              }}
            >
              <span className={styles.labelWrapper}>
                {t('cardPaymentLabel', { ns: 'payment' })}
                <span className={sprinkles({ marginLeft: 'sizeSpacingAuto' })}>
                  <AcceptedPaymentCardsInline />
                </span>
              </span>
            </Radio>
            {paymentType === PaymentType.PaymentCard && (
              <div className={styles.cardFormWrapper} data-qa="card-form">
                <AddPaymentCardForm />
              </div>
            )}
          </li>
        )}
        {isApplePayAvailable && (
          <li className={styles.listItem}>
            <Radio
              name="paymentType"
              value={PaymentType.BraintreeApplePay}
              disabled={isDisabled}
              id="applePay"
              defaultChecked={selectedPaymentType === PaymentType.BraintreeApplePay}
              registerMethods={{
                required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
              }}
            >
              <span className={styles.labelWrapper}>
                {t('payByApplePayLabel')}
                <span className={classNames(paymentIcon({ type: 'applePay' }), styles.icon)} aria-hidden />
              </span>
            </Radio>
          </li>
        )}
        {isGooglePayAvailable && (
          <li className={styles.listItem}>
            <Radio
              name="paymentType"
              value={PaymentType.BraintreeGooglePay}
              disabled={isDisabled}
              id="googlepay"
              defaultChecked={selectedPaymentType === PaymentType.BraintreeGooglePay}
              registerMethods={{
                required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
              }}
            >
              <span className={styles.labelWrapper}>
                {t('payByGooglePayLabel')}
                <span className={classNames(paymentIcon({ type: 'googlePay' }), styles.icon)} aria-hidden />
              </span>
            </Radio>
          </li>
        )}
        {isVenmoAvailable && (
          <li className={styles.listItem}>
            <Radio
              name="paymentType"
              value={PaymentType.BraintreeVenmo}
              disabled={isDisabled}
              id="venmo"
              defaultChecked={selectedPaymentType === PaymentType.BraintreeVenmo}
              registerMethods={{
                required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
              }}
            >
              <span className={styles.labelWrapper}>
                {t('payByVenmoLabel')}
                <span className={classNames(paymentIcon({ type: 'venmo' }), styles.icon)} aria-hidden />
              </span>
            </Radio>
          </li>
        )}
        {isPayPalAvailable && (
          <li className={styles.listItem}>
            <Radio
              name="paymentType"
              value={PaymentType.BraintreePayPal}
              disabled={isDisabled}
              id="payPal"
              defaultChecked={selectedPaymentType === PaymentType.BraintreePayPal}
              registerMethods={{
                required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
              }}
            >
              <span className={styles.labelWrapper}>
                {t('payByPayPalLabel')}
                <span className={classNames(paymentIcon({ type: 'payPal' }), styles.icon)} aria-hidden />
              </span>
            </Radio>
          </li>
        )}
        {!isCardPaymentDisabled && <SavedPaymentCardsRadios itemClassName={styles.listItem} />}
        {isGoCardlessInstantBankPaymentAvailable && !isAwardsOrGcPage && (
          <li className={styles.listItem}>
            <Radio
              name="paymentType"
              id="instantBank"
              value={PaymentType.GoCardlessInstantBankPayment}
              disabled={isDisabled}
              defaultChecked={selectedPaymentType === PaymentType.GoCardlessInstantBankPayment}
              registerMethods={{
                required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
              }}
            >
              <span className={styles.labelWrapper}>
                {t('instantBankPay')}
                <Tooltip
                  id="instantBankPayment-tooltip"
                  buttonAccessibleLabel={t('instantBankTransfer|HelpAccessibleLabel')}
                  buttonClassName={styles.tooltipButton}
                >
                  <p className={sprinkles({ color: 'light' })}>{t('instantBankTransfer|HelpText')}</p>
                </Tooltip>
                <span className={classNames(styles.icons)}>
                  <span className={classNames(paymentIcon({ type: 'natwest' }), styles.icon)} aria-hidden />
                  <span className={classNames(paymentIcon({ type: 'barclays' }), styles.icon)} aria-hidden />
                  <span className={classNames(paymentIcon({ type: 'bankCount' }), styles.icon)} aria-hidden />
                </span>
              </span>
            </Radio>
          </li>
        )}
      </ul>
    </>
  );
};
