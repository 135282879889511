import "src/app/modules/Layout/Layout.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/modules/Layout/Layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVwY6bMBC95ytG2svuAQQEWEoulXrpH/RYObYBK8ZG4KRkq/x7hQMEG0iyW3GJ7Jc3M2/ejF0iBVLUKRET8HcDUKLW+cOIKlKIkrBqdxuAChHCRO5wmqkU/Ng8rVleTI5LVOdMpBCEVQvoqKT+tdtcNu5v/9SW7xHydKQ9woe8lkdBHCy5rFN4yVD3aXLZMMWkSKGmHCl2opqbCaeg13hh5Fm0vqbFUijEBK0dda5oCkxwJqjTsA9NcbsWqKQp4ILigzyqXzWqKlp3kA+HCULbFAKDPtD0hDUVR+cUMk77ekfB/CDwTBE8rYBBswXX1vwT+k6IQv3fLg0702hy47uhlWXk9UkuazwWKKTQB0t9yjK7HRhx/Op73qkAB2Kvat/0f2VNaN0X1kjOCPhVCy806b4Joi9yETL2w9dVsuYnRYTTpvnR9w6syqd56ZQMdWKNUbJKwTNlaBTDh7MBfjebvucSHwxA8gjwTQNWPIVWJyHovq9Owt4e5ThaMSZAjqoFc+FVc5HJjQcebOO7droGtJeE1dKBm36mNZmp/GO7DgO1l0rJMp3vpdyYRp2GPYzXbXI7V7RVDuIsFylgKlS3QSaMRd8IrcFThD12OJ6tD/af6+N7SQlDIAU/Q4NrSgUgQeC1M1RvljCh5Ztmvre0DH8xwRRDfKfPzXyC/hGZZTRcXKaBop7bHigL9m7Dhu5fuhLHHW+UpZ+zvq55MZkUSj8SNwUB9MsxbhI3ioNoDPJYxyic6Zis5z1B7ecC+96wtReeINNhc8FNw1vB8BhMU5gmMpBkntY4/AYwu9PEp5Tbxm40027B+c96MExWPDhcXDaXf2mqU+EKCQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var accordionCauseInfo = '_1vxm75ag';
export var accordionCheckoutContainer = '_1vxm75a9';
export var accordionSbsCheckout = '_1vxm75ac';
export var accordionSbsHeader = '_1vxm75a8';
export var accordionSbsPage = '_1vxm75ad';
export var accordionSbsPageContents = '_1vxm75af';
export var accordionSbsPageSticky = '_1vxm75ae';
export var accordionSbsWrapper = '_1vxm75ab';
export var accordionTrustmarks = '_1vxm75ah';
export var accordionWrapper = '_1vxm75aa';
export var checkoutContainer = '_1vxm75a1';
export var hasAccordion = '_1vxm75ai';
export var hasPageData = '_1vxm75a3';
export var sbsCheckout = '_1vxm75a4';
export var sbsHeader = '_1vxm75a7';
export var sbsPage = '_1vxm75a5';
export var sbsPageSticky = '_1vxm75a6';
export var sbsWrapper = '_1vxm75a2';
export var wrapper = '_1vxm75a0';