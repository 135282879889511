import { Reducer } from 'redux';

import buildAvatarUrl from '../../utils/buildAvatarUrl';
import { AuthActionTypes } from './auth.actions';

const initialState = {
  isLoggedIn: false,
  avatarImageUrl: null,
  avatarName: '',
  familyName: '',
  givenName: '',
};

export const auth: Reducer<State['auth'], AuthActionTypes> = (state = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case 'auth/updateIsLoggedIn':
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    case 'auth/updateUserDetails': {
      const { givenName, familyName, profilePictureUrl } = action;

      const avatarImageUrl = buildAvatarUrl(profilePictureUrl, false);

      const avatarName = [givenName, familyName].filter(name => Boolean(name)).join(' ');

      return {
        ...state,
        avatarImageUrl,
        avatarName,
        givenName,
        familyName,
      };
    }
    default:
      return state;
  }
};

export default auth;
