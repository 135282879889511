import "src/app/modules/TrustMark/trustMark.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/modules/TrustMark/trustMark.css.ts.vanilla.css\",\"source\":\"LnlzZnc3cjAgewogIG1hcmdpbjogYXV0bzsKICBoZWlnaHQ6IDI0cHg7Cn0KLnlzZnc3cjEgewogIG1hcmdpbi10b3A6IDhweDsKICBmb250LXNpemU6IDEzcHg7CiAgY29sb3I6ICM2NjcxODA7Cn0KLnlzZnc3cjIgewogIG1hcmdpbi10b3A6IDMycHg7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9Ci55c2Z3N3IzIHsKICB0ZXh0LWFsaWduOiBjZW50ZXI7CiAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZTsKICBmb250LXNpemU6IDEycHg7CiAgZm9udC13ZWlnaHQ6IDUwMDsKICBjb2xvcjogIzY2NzE4MDsKICBsZXR0ZXItc3BhY2luZzogMS42cHg7Cn0KLnlzZnc3cjQgewogIG1heC13aWR0aDogMzUwcHg7CiAgdGV4dC1hbGlnbjogY2VudGVyOwogIGZsZXgtZGlyZWN0aW9uOiByb3c7CiAgZGlzcGxheTogZmxleDsKICBtYXJnaW4taW5saW5lOiBhdXRvOwogIG1hcmdpbi10b3A6IDI0cHg7CiAgbWFyZ2luLWJvdHRvbTogMDsKICBhbGlnbi1pdGVtczogYmFzZWxpbmU7CiAgZ2FwOiA4cHg7Cn0KLnlzZnc3cjUgewogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZGlzcGxheTogZmxleDsKICBmbGV4OiAxOwp9Ci55c2Z3N3I2IHsKICBtYXJnaW4tdG9wOiAzMnB4OwogIG1hcmdpbi1yaWdodDogYXV0bzsKICBtYXJnaW4tbGVmdDogYXV0bzsKfQoueXNmdzdyNyB7CiAgdGV4dC1hbGlnbjogY2VudGVyOwogIHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2U7CiAgZm9udC1zaXplOiAxMnB4OwogIGZvbnQtd2VpZ2h0OiA1MDA7CiAgY29sb3I6ICM0NDRkNTk7CiAgbGV0dGVyLXNwYWNpbmc6IDEuNnB4Owp9Ci55c2Z3N3I4IHsKICBtYXJnaW4tdG9wOiA4cHg7CiAgZm9udC1zaXplOiAxM3B4OwogIGNvbG9yOiAjNDQ0ZDU5Owp9Ci55c2Z3N3I5IHsKICBtYXJnaW4tdG9wOiA4cHg7CiAgbWFyZ2luLXJpZ2h0OiBhdXRvOwogIG1hcmdpbi1sZWZ0OiBhdXRvOwp9\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var accordionFundraisingRegulator = 'ysfw7r9';
export var accordionIconLabel = 'ysfw7r8';
export var accordionTitle = 'ysfw7r7';
export var fundraisingRegulator = 'ysfw7r6';
export var icon = 'ysfw7r5';
export var iconImg = 'ysfw7r0';
export var iconLabel = 'ysfw7r1';
export var iconWrapper = 'ysfw7r4';
export var title = 'ysfw7r3';
export var wrapper = 'ysfw7r2';