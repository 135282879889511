import { Reducer } from 'redux';

import { GuestActionTypes } from './guest.actions';

const initialState = {
  emailAddress: '',
  firstName: '',
  lastName: '',
};

export const guest: Reducer<State['guest'], GuestActionTypes> = (state = initialState, action: GuestActionTypes) => {
  switch (action.type) {
    case 'guest/updateEmailAddress':
      return {
        ...state,
        emailAddress: action.emailAddress,
      };
    case 'guest/updateGuestFirstName':
      return {
        ...state,
        firstName: action.firstName,
      };
    case 'guest/updateGuestLastName':
      return {
        ...state,
        lastName: action.lastName,
      };
    default:
      return state;
  }
};

export default guest;
