import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Address, AddressesByPostalCodeEdge } from '../../../../types/__generated-graphQL__';
import Label from '../../components/Form/Label/Label';
import Select from '../../components/Form/Select/Select';

type Props = {
  addresses: AddressesByPostalCodeEdge[];
  onSelect: (addressResult: Address) => Promise<void>;
  className?: string;
};

const AddressLookupSelect: FunctionComponent<Props> = ({ className = '', addresses, onSelect = async () => {} }) => {
  const { t } = useTranslation();

  const handleAddressSelect = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedAddress = addresses.find(address => address.node.id === e.target.value);
      if (!selectedAddress) return;
      await onSelect(selectedAddress.node);
    },
    [addresses, onSelect],
  );

  const getAddressOptionList = useMemo(
    () =>
      addresses.map(address => {
        const { id, line1, line2, townCity, postalCode } = address.node;

        return (
          <option key={id} value={id}>
            {[line1, line2, townCity, postalCode].filter(Boolean).join(', ')}
          </option>
        );
      }),
    [addresses],
  );

  return (
    <div className={className}>
      <Label htmlFor="address-select">{t('selectAddressLabel', { ns: 'addressLookup' })}</Label>
      <Select
        name="addressSelect"
        data-qa="address-select-list"
        id="address-select"
        shouldFocusOnMount
        registerMethods={{ onChange: handleAddressSelect }}
      >
        <option key="select-value" value="">
          {t('selectListPlaceholder')}
        </option>
        {getAddressOptionList}
      </Select>
    </div>
  );
};

export default AddressLookupSelect;
