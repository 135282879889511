import { useEffect } from 'react';

import { PaymentType } from '../../../types/__generated-graphQL__';
import { useFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLSelectedPaymentType } from '../redux/checkoutApiSlice/selectors/payment.selectors';
import { loadScript } from '../utils/loadScript';

export function useGooglePay() {
  const { data } = useFetchCheckoutQuery();
  const paymentType = getGQLSelectedPaymentType(data);
  useEffect(() => {
    if (paymentType === PaymentType.BraintreeGooglePay && !window?.google?.payments) {
      loadScript('https://pay.google.com/gp/p/js/pay.js');
    }
  }, [paymentType]);
}
