import React, { FunctionComponent, memo, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { CardType } from '../../../../types/__generated-graphQL__';
import { AddPaymentCardPageFormValues, SummaryPageFormValues } from '../../../../types/formValues';
import { paymentIcon } from '../../../styles/icons.css';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import { FormError } from '../../components/FormError/FormError';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import * as styles from './CvvInput.css';
import { getCardType } from './paymentCard.utils';

type Props = {
  isAmexCard?: boolean;
  className?: string;
};

const CvvInput: FunctionComponent<Props> = memo(({ isAmexCard, className = '' }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<AddPaymentCardPageFormValues>();

  // This makes sure that CVV is not persisted on component unmount
  useEffect(() => {
    return setValue('cvv', '');
  }, [setValue]);

  const cardNumber = useWatch<AddPaymentCardPageFormValues, 'cardNumber'>({ name: 'cardNumber' });
  const activeCards = getCardType(cardNumber);
  const isNewAmexCard = activeCards.length === 1 && activeCards[0] === CardType.AmericanExpress;

  const isAmex = isNewAmexCard || isAmexCard;
  const cvvLength = isAmex ? 4 : 3;

  return (
    <div className={classNames(className)}>
      <Label htmlFor="cardSecurityCode">
        <span aria-hidden={true}>{t('securityCodeLabel')}</span>
        <VisuallyHidden>{t('securityCodeLabelScreenReader', { digits: cvvLength })}</VisuallyHidden>
      </Label>
      <div className={styles.wrapper}>
        <Input
          maxLength={cvvLength}
          name="cvv"
          inputMode="numeric"
          pattern="[0-9]*"
          id="cardSecurityCode"
          autoComplete="off"
          data-qa="cvvInput"
          placeholder={
            isAmex
              ? t('paymentCard|securityCode|placeholder-amex', { ns: 'payment' })
              : t('paymentCard|securityCode|placeholder', { ns: 'payment' })
          }
          required
          registerMethods={{
            required: { value: true, message: t('paymentCard|cardSecurityCode|minLength', { ns: 'validation' }) },
            pattern: {
              value: cvvLength === 3 ? /^\d{3}$/ : /^\d{4}$/,
              message: t('paymentCard|cardSecurityCode|minLength', { ns: 'validation' }),
            },
          }}
        />
        <div className={styles.icon} title={t('cardSecurityCodeHint')} aria-hidden="true">
          <span data-qa="cvvIcon" className={paymentIcon({ type: isAmex ? 'cardCvvAmex' : 'cardCvv' })} />
        </div>
        <VisuallyHidden>{t('cardSecurityCodeHint')}</VisuallyHidden>
      </div>
      <FormError<AddPaymentCardPageFormValues | SummaryPageFormValues> fieldName="cvv" />
    </div>
  );
});

export default CvvInput;
