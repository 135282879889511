import { compose } from 'redux';

import cardTypes from 'credit-card-type';

import { CardType } from '../../../../types/__generated-graphQL__';
import { libraryCardTypeToStateCardTypeMap } from '../../config/cardMap';
import { isAndroid } from '../../utils/getDeviceTypeFromUserAgent';

export const digitsOnly = (s: string) => s.replace(/[^\d]/g, '');

const getAmexFormat = (cardLength: number) => {
  const replacements = ['$1$2$3', '$1 $2$3', '$1 $2 $3'];

  if (cardLength > 10) {
    return replacements[2];
  }

  if (cardLength > 4) {
    return replacements[1];
  }

  return replacements[0];
};

const paymentCardNumberWithSpacesOtherCards = (s: string) => s.replace(/(\d{4})/g, '$1 ');
const paymentCardNumberWithSpacesAmexCard = (s: string) =>
  s.replace(/(\d{4})(\d{6})?(\d{5})?/g, getAmexFormat(s.length)).trim();

const truncate = (length: number) => (s: string) => s.substr(0, length);

export const formatCardNumber = (newValue: string, prevValue: string = ''): string => {
  const first2Digits = compose(truncate(2), digitsOnly)(newValue);
  const isAmexCard = ['34', '37'].includes(first2Digits);

  if (isAndroid()) {
    return truncate(isAmexCard ? 15 : 16)(newValue);
  }

  const charactersWereDeleted = prevValue !== '' && prevValue.startsWith(newValue);

  return charactersWereDeleted
    ? newValue.trim()
    : compose(
        truncate(isAmexCard ? 17 : 19),
        isAmexCard ? paymentCardNumberWithSpacesAmexCard : paymentCardNumberWithSpacesOtherCards,
        digitsOnly,
      )(newValue);
};

export const getCardType = (cardNumber: string): CardType[] => {
  if (!cardNumber) return [];
  return cardTypes(cardNumber)
    .map(({ type }) => libraryCardTypeToStateCardTypeMap(type))
    .filter(Boolean) as CardType[];
};
