import React, { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import classNames from 'classnames';

import Label from '../Label/Label';
import * as styles from './Checkbox.css';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  children: React.ReactNode;
  className?: string;
  name: string;
  id: string;
  registerMethods?: RegisterOptions;
  isRegister?: boolean;
};

const Checkbox: FunctionComponent<Props> = ({
  children,
  className,
  name,
  registerMethods,
  id,
  isRegister = true,
  ...props
}) => {
  const formContext = useFormContext();
  const { register } = isRegister ? formContext : { register: () => {} };
  return (
    <>
      <input
        className={styles.input}
        {...(isRegister && register(name, registerMethods))}
        type="checkbox"
        id={id}
        {...props}
      />
      <Label className={classNames(styles.label, className)} htmlFor={id}>
        {children}
      </Label>
    </>
  );
};

export default Checkbox;
