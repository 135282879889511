import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { formatCurrency } from '../../utils/value';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import * as styles from './fixedAmount.css';

const FixedAmount: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data } = useFetchCheckoutQuery();
  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const donationCurrencyCode = getGQLCurrencyCode(data);

  const { accordionEnabled } = useAccordionExperiment();

  if (accordionEnabled) return null;

  return (
    <div className={styles.wrapper} aria-hidden>
      <span className={styles.label}>{t('label', { ns: 'fixedAmount' })}</span>
      <span>{formatCurrency(donationCurrencyCode, lineItemValueInPounds)}</span>
    </div>
  );
};

export default FixedAmount;
