import { CardType } from '../../../types/__generated-graphQL__';

export const getPaymentCardIcon = (cardType: CardType) => {
  switch (cardType) {
    case CardType.Visa:
      return 'visa';
    case CardType.Mastercard:
      return 'mastercard';
    case CardType.AmericanExpress:
      return 'amex';
    default:
      return undefined;
  }
};
