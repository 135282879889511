import { AcceptedCurrencyCode } from '../../../types/__generated-graphQL__';
import { isWindows } from './getDeviceTypeFromUserAgent';

export const formatCurrencySymbol = (currencyCode: AcceptedCurrencyCode): string => {
  switch (currencyCode) {
    case AcceptedCurrencyCode.Gbp:
      return '£';
    case AcceptedCurrencyCode.Aud:
    case AcceptedCurrencyCode.Cad:
    case AcceptedCurrencyCode.Usd:
    case AcceptedCurrencyCode.Nzd:
      return '$';
    case AcceptedCurrencyCode.Eur:
      return '€';
    default:
      return '£';
  }
};

export const getCurrencyFlag = (currencyCode: AcceptedCurrencyCode): string => {
  if (isWindows()) return '';

  switch (currencyCode) {
    case AcceptedCurrencyCode.Gbp:
      return '🇬🇧';
    case AcceptedCurrencyCode.Aud:
      return '🇦🇺';
    case AcceptedCurrencyCode.Cad:
      return '🇨🇦';
    case AcceptedCurrencyCode.Usd:
      return '🇺🇸';
    case AcceptedCurrencyCode.Nzd:
      return '🇳🇿';
    case AcceptedCurrencyCode.Eur:
      return '🇪🇺';
    default:
      return '';
  }
};
