export type AbTests =
  | 'ACCORDION'
  | 'TIP_JAR_DEFAULT_17'
  | 'DONATE_MORE_PROMPT'
  | 'TIPJAR_OTHER_BIG'
  | 'THANKYOU_ADVANCE'
  | 'DIRECT_DEBIT_LOGIN'
  | 'TIP_JAR_SWAP_VALUES'
  | 'GIFT_AID_SUMMARY_PROMPT';

export const abTestKeys = {
  TIP_JAR_DEFAULT_17: {
    flagKey: 'consumerfintech_jgcheckout_conversion_tipjar_default_17_cta',
    experimentKey: 'consumerfintech_jgcheckout_conversion_tipjar_default_17_cta_exp',
    trackInHeap: true,
  },
  ACCORDION: {
    flagKey: 'consumerfintech_jgcheckout_conversion_accordion_checkout_f46',
    experimentKey: 'consumerfintech_jgcheckout_conversion_accordion_checkout_f_bf0s2',
    trackInHeap: true,
  },
  DONATE_MORE_PROMPT: {
    flagKey: 'consumerfintech_jgcheckout_atv_prompt_to_donate_more_h13',
    experimentKey: 'consumerfintech_jgcheckout_atv_prompt_to_donate_more_h13_exp',
  },
  TIPJAR_OTHER_BIG: {
    flagKey: 'consumerfintech_jgcheckout_conversion_tipjar_other_default_9f2sb',
    experimentKey: 'consumerfintech_jgcheckout_conversion_tipjar_other_default_3fds7',
    trackInHeap: true,
  },
  THANKYOU_ADVANCE: {
    flagKey: 'consumerfintech_jgcheckout_conversion_thankyoufordonating_f48',
    experimentKey: 'consumerfintech_jgcheckout_conversion_thankyoufordonating__ff1sa',
  },
  DIRECT_DEBIT_LOGIN: {
    flagKey: 'consumerfintech_jgcheckout_recurring_adoption_dd_login_g15',
    experimentKey: 'consumerfintech_jgcheckout_recurring_adoption_dd_login_g15_exp',
  },
  TIP_JAR_SWAP_VALUES: {
    flagKey: 'consumerfintech_jgcheckout_conversion_tipjar_swap_values',
    experimentKey: 'consumerfintech_jgcheckout_conversion_tipjar_swap_values_exp',
  },
  GIFT_AID_SUMMARY_PROMPT: {
    flagKey: 'consumerfintech_jgcheckout_giftaid_summarypage_promptv2_b15',
    experimentKey: 'consumerfintech_jgcheckout_giftaid_summarypage_promptv2_b15_exp',
  },
} as Record<AbTests, { flagKey: string; experimentKey: string; trackInHeap?: boolean }>;

export const getAbTestOverrides = (): Record<string, string> => {
  const optimizelyOverride = localStorage?.getItem('JG_optimizely');

  if (!optimizelyOverride) return {};

  return JSON.parse(optimizelyOverride);
};

type getAbTestKeyParams = {
  abTest: AbTests;
  shouldEnrol?: boolean;
};

export const getAbTestKey = ({ abTest, shouldEnrol = true }: getAbTestKeyParams): string => {
  const realTestKey = abTestKeys[abTest].flagKey;
  const invalidTestKey = `${realTestKey}_NOT_A_REAL_KEY`;

  if (!shouldEnrol) {
    return invalidTestKey;
  }

  const abTestOverrides = getAbTestOverrides();
  if (Object.keys(abTestOverrides).length > 0 && !abTestOverrides[abTestKeys[abTest as AbTests].experimentKey]) {
    return invalidTestKey;
  }

  return realTestKey;
};
