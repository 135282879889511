import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoBox } from '../../components/InfoBox/InfoBox';
import { Tooltip } from '../../components/tooltip/Tooltip';

const CryptoInformationBox: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <InfoBox type="info">
      {t('info', { ns: 'crypto' })}{' '}
      <Tooltip
        id="crypto-info"
        buttonText={`${t('findMore')}.`}
        buttonAccessibleLabel={t('findOutMoreAboutCryptocurrency', { ns: 'crypto' })}
      >
        {t('tooltip', { ns: 'crypto' })}
      </Tooltip>
    </InfoBox>
  );
};

export default CryptoInformationBox;
