import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import { FormError } from '../../components/FormError/FormError';
import { FormGroup } from '../../components/FormGroup/FormGroup';
import PaymentCardExpired from '../Payment/PaymentCardExpired';
import PaymentCardExpiryMonthDropdown from '../Payment/PaymentCardExpiryMonthDropdown';
import PaymentCardExpiryYearDropdown from '../Payment/PaymentCardExpiryYearDropdown';
import * as styles from './CardExpiryForm.css';

const CardExpiryForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<AddPaymentCardPageFormValues>();

  return (
    <FormGroup
      legendId="expiryDateLabel"
      legendText={t('paymentCard|expiryDateLabel', { ns: 'payment' })}
      legendVisible
    >
      <div className={styles.wrapper}>
        <PaymentCardExpiryMonthDropdown
          className={classNames({
            'is-error': errors.cardExpiryMonthAndYear,
          })}
        />
        <PaymentCardExpiryYearDropdown
          className={classNames({
            'is-error': errors.cardExpiryMonthAndYear,
          })}
        />
      </div>
      <FormError<AddPaymentCardPageFormValues> fieldName="cardExpiryMonth" />
      <FormError<AddPaymentCardPageFormValues> fieldName="cardExpiryYear" />
      <PaymentCardExpired />
    </FormGroup>
  );
};

export default CardExpiryForm;
