import { COUNTRY_CODES } from '../config/country/countryCodes.ISO3166';

export const getCountryOptionsList = (excludeList: string[] = [], countryList = COUNTRY_CODES) => {
  let countryOptions;
  if (excludeList.length) {
    countryOptions = excludeList.reduce(
      (acc, exclusion) => acc.filter(country => country['alpha-2'] !== exclusion),
      countryList,
    );
  } else {
    countryOptions = countryList;
  }
  return countryOptions.map(country => ({
    label: country.name,
    value: country['alpha-2'],
  }));
};
