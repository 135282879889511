import React, { FunctionComponent } from 'react';

import Loading from '../../components/Loading/Loading';
import * as styles from './AccordionPageLoadingStep.css';

type AccordionPageLoadingStepProps = {};

export const AccordionPageLoadingStep: FunctionComponent<AccordionPageLoadingStepProps> = () => {
  return (
    <div className={styles.wrapper}>
      <Loading size="l" />
    </div>
  );
};
