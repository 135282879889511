import "src/app/modules/Crypto/CryptoAmount.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/modules/Crypto/CryptoAmount.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VW4/aOBR+n19htVppVy2zuXIJ2geGsDNpcRiGTGfhzYmNcQhxlMsQsup/X+UCJJDSPiwRSuxz+3zO8XfuNzYLEk8A/94BYCNnS0Oe+LjjcI+HGvi4Xq+HdwBgFgUeOmhg7ZE039ihkDK/E/NAA2I3SId33+/uS19y6YuHmIQnP07xG54FUXzwiAYi7jFc294zHG80IAZpbTPmQSdkdBN3QoRZEmlAachtHsd8164SIIyZT48oTxuVkQbkSvEYWRB+y5dr7sediGVEA1Kl4TGfdDYkj6IBMd85nq6HBAXjeg6qt1jk4hjSI+tYA/JFuqq31JY2bCtrtVvTVgqtC6wBj1jMuK+BkHgoZu+kDe7Jh1qCOhkhO+JeEpdGBcRjrorySkK5aMnID8/fvewntkOUaCAJvd8xipFWrP+M3umndOcNbRSRrvL5+cmUVocHGb29CEgXGHRHzHjceOgNc3xcP62C1T94bMt0YLgjCsejQ/k36GrnRbYuMHs3SFYLg5JHMbJ9ODB2GwE/jbrTw0DGspPgDCa2/MWfZsYe6qN3R175hqt8fX562OBHSle6wCxrmZjuUoLZnEJrlMx0mEFvKUxdKpqTvTB1Xw9Qf00NfZnMrK06018gPCgZzOAeLkbi1KUZzCaypTuJqW9V03UoXCiS6S5FS5+kU3cpmS6k5kJRYAZTU9incKwIZkaVus/Z+OSzjP33MtfLbQ4L/YjxBVnW5DB15+rMcii0aAJzq+1embr51yg19IkwdSeS6X6Ds4UiQnckwsUohUxRZ9ZWqWOC1msCrbloiku1sjGgBQuf39yJOHUngmlhmOuZuqPMxg0/0tnPPIHWUjJf97lcmFlb2dAnFaYvyGAPO/SWRs8Lwy30hLlqsP6nsbsvavJM+V9/DJuNFJKAoFgDPq8+L+THtu6kGuj3K5poER/Oje0kYZS3ccCZH5PwgnjyG3Gbd9o02q/kFRn9nyxZ0l9l4XOftN3QXyC2e1EtGJ75pziS1KCsrrbmThLVCKuCCKQgBR9F3O3Z5FZdejfrIg4a0Xo36Ko2f5rcdYW14aw0UxtmasOs/7OgSv86aCO54iVHyvnzY2RlxDqYY6ReA9ngJ6O6WRAxL8hVW1006/Uwb5n4yGPU77CY7CINOOR4U9oa/YxV02yy5iEpMDvcj4kfa+DDh/rALeOd2q+aaS0HPB/j5uy6aFZUH8Hn891ob7t1xjYdfP8PmxzhXDkJAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var altValue = 'hbipul8';
export var buttonText = 'hbipul7';
export var currencySymbolWrapper = 'hbipul5';
export var editCurrencyValueError = 'hbipul2';
export var editCurrencyValueMode = 'hbipul1';
export var input = 'hbipul3';
export var inputWrapper = 'hbipul4';
export var loadingAmountWrapper = 'hbipulb';
export var loadingCurrencyWrapper = 'hbipula';
export var loadingWrapper = 'hbipul9';
export var switchButton = 'hbipul6';
export var wrapper = 'hbipul0';