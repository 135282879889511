import React, { ErrorInfo, ReactElement, ReactNode } from 'react';

import debug from 'debug';

import ChunkError from '../ChunkError/ChunkError';

const jgDebug = debug('jg:checkout');

type ErrorBoundaryProps = {
  fallbackContent?: ReactElement;
  children?: ReactNode;
};

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    window.Sentry?.withScope?.(function (scope) {
      scope.setExtra('errorInfo', { errorInfo });
    });
    window.Sentry?.captureException?.(error);
    jgDebug(error);
  }

  render() {
    const { fallbackContent, children } = this.props;
    const { error } = this.state;

    if (!error) return children;

    if (fallbackContent) return fallbackContent;

    if (/(Loading chunk)|(Loading CSS chunk)/.test(error?.message ?? '')) {
      return <ChunkError />;
    }

    if (error) return null;

    return children;
  }
}

export default ErrorBoundary;
