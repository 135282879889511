import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDecision } from '@optimizely/react-sdk';

import { AcceptedCurrencyCode, LineItemType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../types/formValues';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { getAbTestKey } from '../../config/abTests';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { formatCurrency } from '../../utils/value';

const AmountBoostPromptContents: FunctionComponent = () => {
  const { t } = useTranslation();
  const [{ enabled: amountBoostEnabled }] = useDecision(getAbTestKey({ abTest: 'DONATE_MORE_PROMPT' }));

  if (!amountBoostEnabled) return null;

  return (
    <InfoBox type="star">
      {t('donateMore', {
        ns: 'ab',
        addValue: formatCurrency(AcceptedCurrencyCode.Gbp, 5, false),
        totalValue: formatCurrency(AcceptedCurrencyCode.Gbp, 80000, false),
      })}
    </InfoBox>
  );
};

export const AmountBoostPrompt: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const orderType = getGQLOrderType(data);
  const [donationValue, transactionType, donationCurrencyCode] = useWatch<AmountPageFormValues>({
    name: ['donationValue', 'transactionType', 'donationCurrencyCode'],
  });
  if (
    orderType === LineItemType.DonationCharityDirect ||
    !donationValue ||
    donationValue < 100 ||
    ['RECURRING', 'CRYPTO'].includes(transactionType) ||
    donationCurrencyCode !== AcceptedCurrencyCode.Gbp
  ) {
    return null;
  }
  return <AmountBoostPromptContents />;
};
