import React, { FunctionComponent, ReactNode } from 'react';

import classNames from 'classnames';

import * as labelStyles from '../Form/Label/Label.css';
import * as visuallyHiddenStyles from '../VisuallyHidden/VisuallyHidden.css';

type Props = {
  legendId: string;
  legendText: string;
  legendTooltip?: ReactNode;
  groupClassName?: string;
  children?: ReactNode;
  legendVisible?: boolean;
};

export const FormGroup: FunctionComponent<Props> = ({
  legendId,
  legendText,
  groupClassName,
  legendTooltip,
  children,
  legendVisible = false,
}) => {
  const legendClassName = classNames({
    [visuallyHiddenStyles.visuallyHidden]: !legendVisible,
    [labelStyles.label]: legendVisible,
  });

  return (
    // Note: The use of `fieldset` and `legend` tags caused an issue when using Voiceover + Chrome.
    // Voiceover would remain stuck navigating the "old" page after the continue button had taken the user to a "new" page.
    // Solution is to use alternative WAI-ARIA approach below (`div` with role group, `aria-labelledby` reference to the legend element).
    <div role="group" aria-labelledby={legendId} className={groupClassName} data-qa={`${legendId}-group`}>
      {legendTooltip ? (
        <div className={legendClassName}>
          <span id={legendId}>{legendText}</span>
          {legendTooltip}
        </div>
      ) : (
        <p id={legendId} className={legendClassName}>
          {legendText}
        </p>
      )}
      {children}
    </div>
  );
};
