import { PAGES } from '../config/pages';
import { validateSourcePagePath } from './validateSourcePagePath';

export const getSourcePagePath = (preserveCampaignV1DotFormat?: boolean): string => {
  const currentPath = window.location.pathname.toLowerCase();
  if (currentPath === '/') {
    return ''; // Root page
  }

  const components = currentPath?.split('/').filter(Boolean);
  if (Object.values(PAGES).find(value => value === components[components.length - 1])) {
    components?.pop(); // Remove checkout page
  }

  const internalSourcePagePath = components.join('/');
  return validateSourcePagePath(internalSourcePagePath, preserveCampaignV1DotFormat);
};
