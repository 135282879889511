import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-tag';

import { Cryptocurrency, CryptocurrencyList } from '../../../../types/__generated-graphQL__';
import { graphqlRequestBaseQuery } from '../../utils/graphqlRequestBaseQuery';

export const cryptocurrenciesApiSlice = createApi({
  reducerPath: 'cryptocurrencies',
  baseQuery: (args, api, extraOptions) => graphqlRequestBaseQuery(args, api, { ...extraOptions, useCheckoutId: true }),
  endpoints: builder => ({
    fetchCryptocurrencies: builder.query<Cryptocurrency[], void>({
      query() {
        return {
          variables: { input: {} },
          document: gql`
            query cryptocurrencies($input: CryptocurrenciesInput!) {
              cryptocurrencies(input: $input) {
                cryptocurrencies {
                  name
                  shortCode
                  logoUrl
                  exchangeRate
                  exchangeRateTimestamp
                }
              }
            }
          `,
        };
      },
      transformResponse: (response: { cryptocurrencies: CryptocurrencyList }) =>
        response.cryptocurrencies.cryptocurrencies,
    }),
  }),
});

export const { useFetchCryptocurrenciesQuery } = cryptocurrenciesApiSlice;
