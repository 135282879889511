import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from './Alert';

const AlertRecoverableError: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Alert alertType="amber" qaSelector="alertRecoverableError">
      {t('recoverable', { ns: 'alert' })}
    </Alert>
  );
};

export default AlertRecoverableError;
