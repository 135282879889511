import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: State['router'] = {
  history: [],
};

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    updateHistory(state, action: PayloadAction<PageName>) {
      const previousPage = state.history[state.history.length - 2];
      if (previousPage === action.payload && state.history[state.history.length - 1] !== 'SummaryPage') {
        // SummaryPage condition allows the back button to display correctly if the first page the user lands on then leads to the Summary Page
        state.history.pop();
      } else {
        state.history.push(action.payload);
      }
    },
  },
});

export const { updateHistory } = routerSlice.actions;
export default routerSlice.reducer;
