export const httpErrorMessage = {
  UNAUTHORIZED_RECOVERABLE: 'unauthorized-logged-in-session',
  UNAUTHORIZED_NOT_RECOVERABLE: 'unauthorized-guest-session',
  BAD_REQUEST: 'Bad Request',
  REQUEST_TIMEOUT: 'Request timeout',
  UNAUTHORIZED_RETRIES_EXCEEDED: 'Unauthorized retries exceeded',
  UNPROCESSABLE_ENTITY: 'Unprocessable Entity',
  BANK_TRANSFER_NULL_RESPONSE: 'Bank transfer payment null response',
  GRAPHQL_403_OR_404: 'GraphQL 403 or 404 response status code',
};
