import { gql } from 'graphql-tag';

import { CheckoutWalletToken, InitialiseCheckoutWalletTokenInput } from '../../../../types/__generated-graphQL__';
import { checkoutApiSlice } from './checkoutApiSlice';

const mutateInitialiseCheckoutWalletToken = checkoutApiSlice.injectEndpoints({
  endpoints: builder => ({
    mutateInitialiseCheckoutWalletToken: builder.mutation<
      CheckoutWalletToken,
      Omit<InitialiseCheckoutWalletTokenInput, 'checkoutId'>
    >({
      query(data) {
        return {
          variables: {
            input: data,
          },
          document: gql`
            mutation initialiseCheckoutWalletToken($input: InitialiseCheckoutWalletTokenInput!) {
              initialiseCheckoutWalletToken(input: $input) {
                token
              }
            }
          `,
        };
      },
      transformResponse: (response: { initialiseCheckoutWalletToken: CheckoutWalletToken }) =>
        response.initialiseCheckoutWalletToken,
    }),
  }),
});

export const { useMutateInitialiseCheckoutWalletTokenMutation } = mutateInitialiseCheckoutWalletToken;
