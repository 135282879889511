import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { sprinkles } from '../../../styles/sprinkles.css';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCheckoutMode } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLSuccessUrl } from '../../redux/checkoutApiSlice/selectors/customAttributes.selectors';

const ExitApp: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();

  const successUrl = getGQLSuccessUrl(data);
  const checkoutMode = getGQLCheckoutMode(data);

  if (!successUrl) return null;
  if (checkoutMode === 'Headless') return null;

  return (
    <a
      href={successUrl}
      rel="noopener noreferrer"
      className={sprinkles({ marginTop: 'sizeSpacing05', display: 'block' })}
    >
      {t('backToPage')}
    </a>
  );
};

export default ExitApp;
