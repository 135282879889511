import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { AcceptedCurrencyCode, MarketCode } from '../../../../types/__generated-graphQL__';
import { sprinkles } from '../../../styles/sprinkles.css';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLSavedOrDefaultCurrencyCode } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLIsDonorPaysFeesEnabled,
  getGQLMarketCode,
  getGQLisTipJarEnabled,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLTipAmountInPounds } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { Tooltip } from '../tooltip/Tooltip';

type Props = {
  dynamicDonationCurrencyCode?: AcceptedCurrencyCode;
  dynamicDonationPlatformFee?: number;
  tooltipButtonClassName?: string;
  showText?: boolean;
};

export const VoluntaryContributionTooltip: FunctionComponent<Props> = ({
  tooltipButtonClassName,
  // Some uses of this tooltip pass in form data instead of api data
  dynamicDonationCurrencyCode,
  dynamicDonationPlatformFee,
  showText,
}) => {
  const { data } = useFetchCheckoutQuery();
  const { t, i18n } = useTranslation();
  const savedOrDefaultCurrencyCode = getGQLSavedOrDefaultCurrencyCode(data);
  const tipAmountInPounds = getGQLTipAmountInPounds(data);
  const isTipJarEnabled = getGQLisTipJarEnabled(data);
  const isDonorPaysFeesEnabled = getGQLIsDonorPaysFeesEnabled(data);
  const marketCode = getGQLMarketCode(data);

  if (!isTipJarEnabled && !isDonorPaysFeesEnabled) {
    return null;
  }

  const tipValueString = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: dynamicDonationCurrencyCode ?? savedOrDefaultCurrencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(dynamicDonationPlatformFee ?? tipAmountInPounds);

  return (
    <Tooltip
      id="voluntaryContributionTooltip"
      buttonText={showText ? t('findOutWhy', { ns: 'tipJar' }) : undefined}
      buttonAccessibleLabel={
        isTipJarEnabled ? t('tooltipLinkAccessible', { ns: 'tipJar' }) : t('tooltipLinkAccessible', { ns: 'dpf' })
      }
      buttonClassName={tooltipButtonClassName}
    >
      {isTipJarEnabled ? (
        <>
          <p className={sprinkles({ color: 'default', textSize: 'l' })}>
            {t('tooltipYourContributionPara1', { ns: 'tipJar' })}
          </p>
          <p className={sprinkles({ marginTop: 'sizeSpacing02', color: 'light' })}>
            {marketCode === MarketCode.Us
              ? t('tooltipWhatMoneyIsForPara2|USCharity', { ns: 'tipJar' })
              : t('tooltipWhatMoneyIsForPara2Tip', { ns: 'tipJar' })}
          </p>
        </>
      ) : (
        <>
          <p className={sprinkles({ color: 'default', textSize: 'l' })}>{t('info|title', { ns: 'dpf' })}</p>
          <p className={sprinkles({ marginTop: 'sizeSpacing02', color: 'light' })}>{t('info|line1', { ns: 'dpf' })}</p>
          <p className={sprinkles({ marginTop: 'sizeSpacing04', color: 'light' })}>
            {t('info|line2', {
              tipValue: tipValueString,
              ns: 'dpf',
            })}
          </p>
        </>
      )}
    </Tooltip>
  );
};
