import React, { FunctionComponent, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '@justgiving/auth-react';

import APP_SETTINGS from '../../../config';
import Checkbox from '../../components/Form/Checkbox/Checkbox';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsRecurringNotDirectDebit } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';

type Props = {
  classNames?: string;
};

const PaymentCardSaveForFutureUseInput: FunctionComponent<Props> = memo(() => {
  const { data } = useFetchCheckoutQuery();

  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const isGuest = auth?.isGuest();
  const isRecurringNotDirectDebit = getGQLIsRecurringNotDirectDebit(data);

  if (isGuest || isRecurringNotDirectDebit || APP_SETTINGS.DISABLE_ADDING_SAVED_CARD) {
    return null;
  }

  return (
    <Checkbox name="saveCardForFutureUse" id="saveCardForFutureUse" defaultChecked>
      {t('paymentCard|saveCardForFutureUse|label', { ns: 'payment' })}
    </Checkbox>
  );
});

export default PaymentCardSaveForFutureUseInput;
