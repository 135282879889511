import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useDocumentTitle = (title: string) => {
  const { t } = useTranslation();

  // On load set the title
  useEffect(() => {
    document.title = `${t('donate')} - ${title} - JustGiving`;
  }, [title, t]);

  // Logic based title change
  const setTitle = useCallback(
    (updatedTitle: string) => {
      document.title = `${t('donate')} - ${updatedTitle} - JustGiving`;
    },
    [t],
  );

  return { setTitle };
};

export default useDocumentTitle;
