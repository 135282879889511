let cachedScripts: Record<string, Promise<any>> = {};

export const loadScript = (src: string): Promise<void> => {
  const existing = cachedScripts[src];
  if (existing) {
    return existing;
  }

  const promise = new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const onScriptLoad = (): void => {
      resolve();
    };

    const onScriptError = (): void => {
      cleanup();

      delete cachedScripts[src];
      script.remove();

      reject(new Error(`Unable to load script ${src}`));
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    function cleanup(): void {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    }
  });

  cachedScripts[src] = promise;

  return promise;
};
