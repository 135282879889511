import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { sprinkles } from '../../../styles/sprinkles.css';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import { FormError } from '../../components/FormError/FormError';
import AddressLookUpCountryList from './AddressLookupCountryList';
import AddressLookupCountyStateRegion from './AddressLookupCountyStateRegion';
import * as styles from './addressLookup.css';

const AddressLookupManualAddress: FunctionComponent<{ formPrefix: string }> = ({ formPrefix }) => {
  const { t } = useTranslation();
  const countryCode = useWatch({ name: `${formPrefix}.address.country` });
  return (
    <>
      <Label htmlFor={`${formPrefix}.address.line1`}>{t('addressLine1Label', { ns: 'addressLookup' })}</Label>
      <Input
        name={`${formPrefix}.address.line1`}
        id={`${formPrefix}.address.line1`}
        registerMethods={{
          setValueAs: value => value?.trim(),
          required: { value: true, message: t('addressLine1|mandatory', { ns: 'validation' }) },
          maxLength: { value: 100, message: t('addressLine1|maxLength', { ns: 'validation' }) },
        }}
        required
      />
      <FormError fieldName={`${formPrefix}.address.line1`} />
      <Label className={sprinkles({ marginTop: 'sizeSpacing04' })} htmlFor={`${formPrefix}.address.line2`}>
        {t('addressLine2Label', { ns: 'addressLookup' })}
      </Label>
      <Input
        name={`${formPrefix}.address.line2`}
        id={`${formPrefix}.address.line2`}
        registerMethods={{
          setValueAs: value => value?.trim(),
          maxLength: { value: 100, message: t('addressLine2|maxLength', { ns: 'validation' }) },
        }}
      />
      <FormError fieldName={`${formPrefix}.address.line2`} />
      <div className={styles.formFlexRow}>
        <div className={styles.formFlexItem}>
          <Label htmlFor={`${formPrefix}.address.townCity`}>{t('townCityLabel', { ns: 'addressLookup' })}</Label>
          <Input
            name={`${formPrefix}.address.townCity`}
            id={`${formPrefix}.address.townCity`}
            registerMethods={{
              setValueAs: value => value?.trim(),
              required: { value: true, message: t('townCity|mandatory', { ns: 'validation' }) },
              maxLength: { value: 50, message: t('townCity|maxLength', { ns: 'validation' }) },
            }}
            required
          />
          <FormError fieldName={`${formPrefix}.address.townCity`} />
        </div>
        <div className={styles.formFlexItem}>
          <Label htmlFor={`${formPrefix}.address.postalCode`}>{t('postalCodeLabel', { ns: 'addressLookup' })}</Label>
          <Input
            name={`${formPrefix}.address.postalCode`}
            id={`${formPrefix}.address.postalCode`}
            required
            registerMethods={{
              setValueAs: value => value?.trim(),
              required: { value: true, message: t('zipOrPostalCode|mandatory', { ns: 'validation' }) },
              maxLength: { value: 10, message: t('zipOrPostalCode|maxLength', { ns: 'validation' }) },
            }}
          />
          <FormError fieldName={`${formPrefix}.address.postalCode`} />
        </div>
      </div>
      {['US', 'CA'].includes(countryCode) && (
        <AddressLookupCountyStateRegion name={`${formPrefix}.address.countyStateRegion`} countryCode={countryCode} />
      )}
      <AddressLookUpCountryList formPrefix={formPrefix} />
    </>
  );
};

export default AddressLookupManualAddress;
