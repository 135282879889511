import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PAGES } from '../config/pages';
import { AccordionPageLoadingStep } from '../modules/AccordionPages/AccordionPageLoadingStep';
import AccordionPages from '../modules/AccordionPages/AccordionPages';
import AmountPage from '../pages/AmountPage/AmountPage';
import SelectPaymentPage from '../pages/SelectPaymentPage/SelectPaymentPage';
import ThankYouPage from '../pages/ThankYouPage/ThankYouPage';

const GiftAidDeclarationPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "ga-wrapper" */ '../pages/GiftAidDeclarationPage/GiftAidDeclarationPage'
    ),
);

const GiftAidDetailsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "ga-details" */ '../pages/GiftAidDetailsPage/GiftAidDetailsPage'
    ),
);
const MessagePage = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "message" */ '../pages/MessagePage/MessagePage'),
);
const AmexPWPPage = lazy(() => import(/* webpackChunkName: "pay-with-points" */ '../pages/AmexPWPPage/AmexPWPPage'));
const SummaryPage = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "summary" */ '../pages/SummaryPage/SummaryPage'),
);

const RoutesContainer: FunctionComponent = () => {
  return (
    // TODO When the accordion ab test is removed make sure to keep this suspense in place with LoadingPage as it improves page transitions on slow devices
    <Suspense
      fallback={
        <AccordionPages addEmptyFormContext>
          <AccordionPageLoadingStep />
        </AccordionPages>
      }
    >
      <Routes>
        <Route path={`/:pageType?/:pageSlug?`}>
          <Route path={PAGES.ThankYouPage} element={<ThankYouPage />} />
          <Route path={PAGES.AmountPage} element={<AmountPage />} />
          <Route path={PAGES.MessagePage} element={<MessagePage />} />
          <Route path={PAGES.GiftAidDeclarationPage} element={<GiftAidDeclarationPage />} />
          <Route path={PAGES.GiftAidDetailsPage} element={<GiftAidDetailsPage />} />
          <Route path={PAGES.SelectPaymentPage} element={<SelectPaymentPage />} />
          <Route path={PAGES.SummaryPage} element={<SummaryPage />} />
          <Route path={PAGES.AmexPayWithPointsYourPaymentPage} element={<AmexPWPPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesContainer;
