import React, { FunctionComponent, ReactNode } from 'react';

import classNames from 'classnames';

import * as styles from './InputPrefixOrSuffix.css';

type Props = {
  children: ReactNode;
  type: 'prefix' | 'suffix';
  value: string;
  wrapperClassName?: string;
};

export const InputPrefixOrSuffix: FunctionComponent<Props> = ({ children, type, value, wrapperClassName }) => {
  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      {children}
      <span className={type === 'prefix' ? styles.prefixToken : styles.suffixToken} aria-hidden="true">
        {value}
      </span>
    </div>
  );
};
