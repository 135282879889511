import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type TipInputMode = 'Selection' | 'Other';

export type TipValueType = 'Amount' | 'Percentage';

type TipState = {
  isReady: boolean;
  inputMode: TipInputMode;
  otherValue?: string;
  selectionValue?: number;
  userSelectionMade: boolean;
  tipModalOpen: boolean;
  tipValues?: number[];
  valueType?: TipValueType;
  defaultValue?: number;
  lastSelectedAmountValue?: number;
  lastSelectedPercentageValue?: number;
};

const initialState: TipState = {
  isReady: false,
  inputMode: 'Selection',
  userSelectionMade: false,
  tipModalOpen: false,
};

type InitTip = {
  inputMode: TipInputMode;
  selectionValue?: number;
  userSelectionMade: boolean;
  tipValues?: number[];
  valueType?: TipValueType;
  defaultValue?: number;
  lastSelectedAmountValue?: number;
  lastSelectedPercentageValue?: number;
};

type SetInputMode = {
  inputMode: TipInputMode;
};

type SetTipValues = {
  tipValues?: number[];
  valueType: any;
  defaultValue: any;
};

type SetOtherValue = {
  value: string;
};

type SetSelectionValue = {
  value: number;
};

type SetDefaultTipValue = {
  value: number;
};

const tipSlice = createSlice({
  name: 'tip',
  initialState,
  reducers: {
    initTip(state, action: PayloadAction<InitTip>) {
      state.isReady = true;
      state.inputMode = state.inputMode === 'Other' ? state.inputMode : action.payload.inputMode;
      state.selectionValue = action.payload.selectionValue;
      state.userSelectionMade = action.payload.userSelectionMade;
      state.tipValues = action.payload.tipValues;
      state.defaultValue = action.payload.defaultValue;
      state.valueType = action.payload.valueType;
      state.lastSelectedAmountValue = state.lastSelectedAmountValue ?? action.payload.lastSelectedAmountValue;
      state.lastSelectedPercentageValue =
        state.lastSelectedPercentageValue ?? action.payload.lastSelectedPercentageValue;
    },
    setTipInputMode(state, action: PayloadAction<SetInputMode>) {
      state.inputMode = action.payload.inputMode;
    },
    setTipValues(state, action: PayloadAction<SetTipValues>) {
      state.tipValues = action.payload.tipValues;
      state.defaultValue = action.payload.defaultValue;
      state.valueType = action.payload.valueType;
    },
    setTipOtherValue(state, action: PayloadAction<SetOtherValue>) {
      state.otherValue = action.payload.value;
    },
    setTipSelectionValue(state, action: PayloadAction<SetSelectionValue>) {
      state.userSelectionMade = true;
      state.selectionValue = action.payload.value;
      state.inputMode = 'Selection';
      if (state.valueType === 'Amount') {
        state.lastSelectedAmountValue = action.payload.value;
      } else {
        state.lastSelectedPercentageValue = action.payload.value;
      }
    },
    setTipDefaultValue(state, action: PayloadAction<SetDefaultTipValue>) {
      if (state.inputMode === 'Selection') {
        state.userSelectionMade = false;
        state.selectionValue = action.payload.value;
      }
    },
    openTipModal(state) {
      state.tipModalOpen = true;
    },
    closeTipModal(state) {
      state.tipModalOpen = false;
    },
  },
});

export const {
  initTip,
  setTipInputMode,
  setTipValues,
  setTipOtherValue,
  setTipSelectionValue,
  setTipDefaultValue,
  openTipModal,
  closeTipModal,
} = tipSlice.actions;
export default tipSlice.reducer;
