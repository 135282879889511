import { useContext, useEffect } from 'react';

import { AuthContext } from '@justgiving/auth-react';
import { OptimizelyContext } from '@optimizely/react-sdk';

import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsRecurringOrDirectDebit } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLGiftAidIsClaimed } from '../../redux/checkoutApiSlice/selectors/giftAid.selectors';
import { getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode, getGQLTipAmountInPounds } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import {
  getGQLSelectedPaymentType,
  getGQLDirectDebitMandateReference,
} from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLCheckoutId } from '../../redux/checkoutApiSlice/selectors/root.selectors';
import { getGQLTransactionId } from '../../redux/checkoutApiSlice/selectors/transaction.selectors';
import { convertPoundsToPennies } from '../../utils/utilityMapperFunctions';
import { getIsGoCardlessInstantBankPaymentOutcome } from '../PayWithBank/payWithBank.utils';

export default function useOptimizelyTrack(status: DonationStatus) {
  const { data } = useFetchCheckoutQuery();

  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const checkoutId = getGQLCheckoutId(data);
  const currencyCode = getGQLCurrencyCode(data);
  const selectedPaymentType = getGQLSelectedPaymentType(data);
  const { optimizely } = useContext(OptimizelyContext);
  const transactionId = getGQLTransactionId(data);
  const tipAmountInPounds = getGQLTipAmountInPounds(data);
  const claimedGiftAid = getGQLGiftAidIsClaimed(data);
  const isRecurringOrDirectDebit = getGQLIsRecurringOrDirectDebit(data);
  const auth = useContext(AuthContext);
  const mandateReference = getGQLDirectDebitMandateReference(data);
  const isGoCardlessInstantBankPaymentOutcome = getIsGoCardlessInstantBankPaymentOutcome();

  useEffect(() => {
    const lineItemValueInPennies = convertPoundsToPennies(lineItemValueInPounds);
    const tipAmountInPennies = convertPoundsToPennies(tipAmountInPounds);

    try {
      const optimizelyEventData = {
        ...(lineItemValueInPennies ? { revenue: lineItemValueInPennies } : {}),
        donationId: transactionId ?? 'unknown',
        ...(lineItemValueInPennies ? { amount: lineItemValueInPounds?.toFixed?.(2) } : {}),
        donationCollectionId: checkoutId,
        donationCurrencyCode: currencyCode!,
        ...(mandateReference ? { mandateReference } : {}),
        userType: auth?.isGuest() ? 'Guest' : 'LoggedIn',
        selectedPaymentType: selectedPaymentType ?? '',
      };

      if (status !== 'pending') {
        // Optimizely full stack
        optimizely?.track('donationComplete', optimizelyEventData);
        optimizely?.track('donationTip', {
          revenue: tipAmountInPennies,
        });
        if (tipAmountInPounds > 0) {
          optimizely?.track('donationWithTipDonationValue', {
            ...(lineItemValueInPennies ? { revenue: lineItemValueInPennies } : {}),
          });
          optimizely?.track('donationWithTipTipValue', {
            revenue: tipAmountInPennies,
          });
        }

        if (isGoCardlessInstantBankPaymentOutcome) {
          optimizely?.track('donationWithGCIBP');
        }

        if (claimedGiftAid) {
          optimizely?.track('donationWithGiftAid');
        }

        if (isRecurringOrDirectDebit) {
          optimizely?.track('donationWithRecurring', {
            ...(lineItemValueInPennies ? { revenue: lineItemValueInPennies } : {}),
          });
        }

        optimizely?.track('donationTakeRate', {
          value: tipAmountInPounds / (tipAmountInPounds + lineItemValueInPounds),
        });
      }
    } catch (error) {
      window.Sentry?.captureException?.(new Error(`Optimizely error when firing track. ${error}`));
    }
  }, [
    lineItemValueInPounds,
    auth,
    checkoutId,
    currencyCode,
    mandateReference,
    optimizely,
    selectedPaymentType,
    tipAmountInPounds,
    status,
    claimedGiftAid,
    isGoCardlessInstantBankPaymentOutcome,
    isRecurringOrDirectDebit,
    transactionId,
  ]);
}
