export const updatePaymentCardsWithCVV = (paymentCard: string) =>
  ({
    type: 'session/updatePaymentCardsWithCVV',
    paymentCard,
  }) as const;

export const summaryPageReady = () =>
  ({
    type: 'session/summaryPageReady',
  }) as const;

export const startGlobalLoading = () =>
  ({
    type: 'session/startGlobalLoading',
  }) as const;

export const endGlobalLoading = () =>
  ({
    type: 'session/endGlobalLoading',
  }) as const;

export const updateIsEmbedded = () =>
  ({
    type: 'session/updateIsEmbedded',
  }) as const;

export const updateErrorType = (errorType: ErrorType) =>
  ({
    type: 'session/updateErrorType',
    errorType,
  }) as const;

export const braintreeInitialised = () =>
  ({
    type: 'session/braintreeInitialised',
  }) as const;

export type SessionActionTypes =
  | ReturnType<typeof updatePaymentCardsWithCVV>
  | ReturnType<typeof summaryPageReady>
  | ReturnType<typeof startGlobalLoading>
  | ReturnType<typeof endGlobalLoading>
  | ReturnType<typeof updateIsEmbedded>
  | ReturnType<typeof updateErrorType>
  | ReturnType<typeof braintreeInitialised>;
