import { createSelector } from '@reduxjs/toolkit';

import { Checkout, PaymentCard } from '../../../../../types/__generated-graphQL__';
import APP_SETTINGS from '../../../../config';
import { getGQLPaymentCardDetails } from './payment.selectors';

const _getGQLTransactingUser = (data?: Checkout) => data?.transactingUser;

export const getGQLSavedPaymentCards = (data?: Checkout) => {
  return _getGQLTransactingUser(data)?.savedCards;
};

export const getGQLSpecificSavedPaymentCard = (data?: Checkout, cardId?: string) => {
  return _getGQLTransactingUser(data)?.savedCards.find(savedPaymentCard => savedPaymentCard.paymentCardId === cardId);
};

export const getGQLSortedSavedPaymentCards = createSelector(
  getGQLSavedPaymentCards,
  getGQLPaymentCardDetails,
  (savedCards, selectedCard) => {
    if (APP_SETTINGS.DISABLE_DISPLAYING_SAVED_CARDS) return [];
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    const century = today.getFullYear().toString().substring(0, 2);

    return savedCards
      ?.map(card => {
        const newCard: PaymentCard & { expired: boolean } = { ...card, expired: false };
        const cardExpiryYear = card.expiryYear.length === 4 ? card.expiryYear : `${century}${card.expiryYear}`;
        const cardExpiryMonth = card.expiryMonth.length === 2 ? card.expiryMonth : `0${card.expiryMonth}`;

        newCard.expiryMonth = cardExpiryMonth.toString() as CardMonth;

        const cardDate = new Date();
        cardDate.setFullYear(Number(cardExpiryYear), Number(cardExpiryMonth), 0); // arg 0, takes to you to the end date of the month
        cardDate.setHours(0);
        cardDate.setMinutes(0);
        cardDate.setSeconds(0);
        cardDate.setMilliseconds(0);

        if (cardDate < today) {
          newCard.disabled = true;
          newCard.expired = true;
        }

        return newCard;
      })
      .sort(({ paymentCardId }) => (paymentCardId === selectedCard?.paymentCardId ? -1 : 1))
      .sort(({ expired, disabled }) => Number(expired || disabled));
  },
);
