import { Checkout } from '../../../../../types/__generated-graphQL__';
import { getGQLLineItemValueInPounds } from './lineItem.selectors';

const _getGQLGiftAid = (data?: Checkout) => data?.giftAid;

export const getGQLGiftAidIsClaimed = (data?: Checkout) => {
  const isClaimed = _getGQLGiftAid(data)?.isClaimed;
  return typeof isClaimed === 'boolean' ? isClaimed : null;
};

export const getGQLGiftAidRatePercentage = (data?: Checkout) => (_getGQLGiftAid(data)?.ratePercent ?? 0) * 100;

export const getGQLGiftAidValue = (data?: Checkout) => {
  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const giftAidPercentage = getGQLGiftAidRatePercentage(data);
  return lineItemValueInPounds && giftAidPercentage
    ? lineItemValueInPounds * (getGQLGiftAidRatePercentage(data) / 100)
    : 0;
};

export const getGQLIsSwiftAidEnabled = (data?: Checkout) =>
  Boolean(_getGQLGiftAid(data)?.isExternalGiftAidProviderEnabled);

export const getGQLIsMatchedInSwiftAid = (data?: Checkout) =>
  Boolean(_getGQLGiftAid(data)?.isMatchedInExternalGiftAidProvider);

export const getGQLGiftAidTaxPayerDetails = (data?: Checkout) => _getGQLGiftAid(data)?.taxPayerDetails;

export const getGQLGiftAidAddressIsOverseas = (data?: Checkout) => {
  const country = getGQLGiftAidTaxPayerDetails(data)?.address?.country;
  return Boolean(country && country !== 'GB');
};

export const getGQLGiftAidAddressSelectionHash = (data?: Checkout) => getGQLGiftAidTaxPayerDetails(data)?.selectionHash;
