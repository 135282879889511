import React, { ReactNode } from 'react';

import * as styles from './VisuallyHidden.css';

type Props<T extends React.ElementType> = {
  children?: ReactNode;
  as?: T;
};

const VisuallyHidden = <T extends React.ElementType = 'span'>({
  children,
  as,
  ...otherProps
}: Props<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof Props<T>>) => {
  const Component = as || 'span';
  return (
    <Component className={styles.visuallyHidden} {...otherProps}>
      {children}
    </Component>
  );
};

export default VisuallyHidden;
