import React, { FunctionComponent, ReactNode } from 'react';

import classNames from 'classnames';

import { ButtonVariants, button, isLoading as isLoadingStyle } from '../../../styles/recipes/buttons.css';
import Loading from '../Loading/Loading';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
  children?: ReactNode;
  qaSelector?: string;
  color?: ButtonVariants['color'];
  size?: ButtonVariants['size'];
  className?: string;
};

const Button: FunctionComponent<Props> = ({
  isLoading = false,
  qaSelector,
  disabled = false,
  children,
  color = 'primary',
  size,
  className,
  ...otherProps
}) => {
  return (
    <button
      className={classNames(button({ color, size }), className, { [isLoadingStyle]: isLoading })}
      data-qa={qaSelector}
      aria-disabled={disabled || isLoading}
      disabled={isLoading || disabled}
      {...otherProps}
    >
      {isLoading ? <Loading qaSelector={`${qaSelector}-loading`} /> : children}
    </button>
  );
};

export default Button;
