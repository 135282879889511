import { VoluntaryContributionConfigValueType } from '../../../../types/__generated-graphQL__';
import { TipJarConfigSet } from '../../../../types/tipjarConfig';
import { lowTipAboveUpperLimitInPennies, lowTipDefault } from './tipjarConfigDefaults';

export const tipJarConfigMacmillan: TipJarConfigSet = [
  lowTipDefault,
  {
    from: lowTipAboveUpperLimitInPennies,
    to: Number.MAX_VALUE,
    valueType: VoluntaryContributionConfigValueType.Percentage,
    values: [7.5, 10, 12.5, 15],
    defaultValue: 15,
  },
];
