import { createSelector } from '@reduxjs/toolkit';

const getEnrolledInOptimizely = (state: State) => state.AB_TEST.enrolledInOptimizely;

export const getEnrolledInOptimizelyForQualaroo = createSelector(getEnrolledInOptimizely, enrolledInOptimizely => {
  const remappedEnrolled: Record<string, string> = {};
  Object.keys(enrolledInOptimizely).map(feature => {
    const featureKey = feature.replace(/-/g, '_');

    remappedEnrolled[featureKey] = enrolledInOptimizely[feature].variation ?? '';
    return null;
  });
  return remappedEnrolled;
});
