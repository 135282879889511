import "src/app/modules/ChromeHeader/ChromeHeader.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/modules/ChromeHeader/ChromeHeader.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7WT0Y6bMBBF3/MVI60qtQ9EQMBhnZf+SWXsgbgLNjKmS1Sl315BIMUO2U1XWvFk5c7cMzcz2x9R2qvumYfwewNwRFkeLQUSNv1hA5Az/lIa3SkRcF1pQ+GpKIrxF20EmiDX1uqaQtT00OpKCnjinA+CWb/fDd9hc95sZ69o9KpZH7xKYY8UojiZDGtmSqkohMA6qw8LpCgMvwxvIdumYicKRYVjyc+utbI4BVwri8pSaBvGMcjRviIqxziejAePoMJibHsxbpgQUpUUyOXt2i567JygZqrlMEk0tFiUJP9fki5JzVx4QfMSWFSRseomIVbJUgXSYt1S4KgsmjdH3I9tpkSmnIgT04xEPOzMBVBa4VA1jZntl39yYHVDIfY6PDtZ7aaRpwbzc1o+w4Ts2pXVkKqSCoO80vzF3SofmK0DOytCHOo7k+djI3/pASz2NhDItWFWavXP4iPx5vSof6FxrGIyfJ6s0Lxr35XBH/D4nbkzz537Z0vSz7za7zUKyUCr6gQtN4gKmBLwtZZqBtiRbYr1t5HLO1D3vmbQ81KYPCrcX4XOOcYr0myS3l3F6y5HYXqpd+8hmps+FABJbsa/A5utwLL3YB+kSG8p+Eq2YXgN9/wXJ/x9G3gGAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var accordionBanner = '_15xnu9cc';
export var avatar = '_15xnu9c9';
export var banner = '_15xnu9c1';
export var container = '_15xnu9c0';
export var customHeaderImage = '_15xnu9c3';
export var imageWrapper = '_15xnu9c2';
export var justGivingLogoGrey = '_15xnu9c8';
export var justGivingLogoGreyWrapper = '_15xnu9c7 _15xnu9c6';
export var justGivingLogoPurple = '_15xnu9c4';
export var link = '_15xnu9cb _15xnu9c6';
export var otherContentWrapper = '_15xnu9c5';
export var userName = '_15xnu9ca';