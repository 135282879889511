import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { TipScheme } from '../../../../types/__generated-graphQL__';
import { VoluntaryContributionTooltip } from '../../components/VoluntaryContributionTooltip/VoluntaryContributionTooltip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityName, getGQLIsDonorPayingFees } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLDisplayGiftAid } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLGiftAidIsClaimed } from '../../redux/checkoutApiSlice/selectors/giftAid.selectors';
import { getGQLIsBBGF, getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLAppliedTipScheme,
  getGQLCurrencyCode,
  getGQLTipAmountInPounds,
  getGQLTotalAmountInPounds,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { formatCurrency } from '../../utils/value';
import * as styles from './DonationInformation.css';

type Props = { donationStatus: DonationStatus };

const DonationInformation: FunctionComponent<Props> = ({ donationStatus }) => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();

  const isDonorPayingFees = getGQLIsDonorPayingFees(data);
  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const apiDonationCurrencyCode = getGQLCurrencyCode(data);
  const totalAmountInPounds = getGQLTotalAmountInPounds(data);
  const tipAmountInPounds = getGQLTipAmountInPounds(data);
  const isBBGF = getGQLIsBBGF(data);
  const giftAidIsClaimed = getGQLGiftAidIsClaimed(data);
  const giftAidDisplayed = getGQLDisplayGiftAid(data);
  const charityName = getGQLCharityName(data);
  const isTip = isDonorPayingFees || tipAmountInPounds > 0;
  const appliedTipScheme = getGQLAppliedTipScheme(data);

  let tipLabel: string = t('tipToJustGiving');
  if (appliedTipScheme === TipScheme.Dpf1_2) {
    tipLabel = t('voluntaryContribution');
  } else if (isBBGF) {
    tipLabel = t('thankyou|BBGF|tip|heading', { ns: 'tipJar' });
  }

  if (!totalAmountInPounds) return null;

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.heading}>{t('yourDonationTitle')}</h2>
      <dl className={styles.list}>
        <dt className={styles.listTitle}>
          {isBBGF
            ? t('thankyou|donationValue', { causeName: charityName, ns: 'bbgf' })
            : t('donationValueLabel', { ns: 'amount' })}
        </dt>
        <dd className={styles.listDescription} data-qa="donation-amount">
          {formatCurrency(apiDonationCurrencyCode, lineItemValueInPounds)}
        </dd>
        {isTip && (
          <>
            <dt data-qa="donorFee" className={styles.listTitle}>
              {tipLabel}{' '}
              {['timeout', 'succeeded', 'directdebit_succeeded'].includes(donationStatus) && (
                <VoluntaryContributionTooltip tooltipButtonClassName={styles.tooltip} />
              )}
            </dt>
            <dd className={styles.listDescription}>{formatCurrency(apiDonationCurrencyCode, tipAmountInPounds)} </dd>
          </>
        )}
        {giftAidDisplayed && (
          <>
            <dt className={styles.listTitle}>{t('thankyou|yourdonation|giftAidTitle', { ns: 'giftAid' })}</dt>
            <dd className={styles.listDescription}>{t(`${giftAidIsClaimed ? 'yes' : 'no'}`)}</dd>
          </>
        )}
        <dt className={`${styles.listTitle}`}>{isBBGF ? t('totalPayment') : t('total')}</dt>
        <dd data-qa="totalAmountToPay" className={styles.listDescription}>
          {formatCurrency(apiDonationCurrencyCode, totalAmountInPounds)}
        </dd>
      </dl>
    </section>
  );
};

export default DonationInformation;
