import { Reducer } from 'redux';

import { EntityType, MarketCode } from '../../../../types/__generated-graphQL__';
import { PREFERENCES_SERVICE } from '../../config/preferences';
import { UserConsentActionTypes } from './preferences.actions';

const initialState = {
  consents: [],
};

export const preferences: Reducer<State['preferences'], UserConsentActionTypes> = (
  state = initialState,
  action: UserConsentActionTypes,
) => {
  switch (action.type) {
    case 'preferences/setInitial': {
      const { regionCode, marketCode, donationCauseSourceRef, collectionLocationId, consents } = action.preferences;

      if (!consents || !Array.isArray(consents)) return state;
      const { causeName, donationTarget } = action.cause;
      const cause = causeName || donationTarget;

      const mappedConsents = consents.map(({ id, entityType, type, text }) => {
        const hidden = type === PREFERENCES_SERVICE.DONATION_RELATED_EMAIL;

        const isConsentGiven = (() => {
          if (!hidden && entityType !== EntityType.Charity) {
            return false;
          }
          if (
            regionCode === 'US' &&
            marketCode === MarketCode.Us &&
            type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL &&
            entityType === EntityType.Charity
          ) {
            return true;
          }

          if (
            entityType === EntityType.Charity &&
            type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL &&
            ['AU', 'NZ'].includes(regionCode)
          ) {
            return true;
          }

          if (entityType === EntityType.Charity && action.defaultConsentToFalse) {
            return false;
          }
          return hidden || null;
        })();

        return {
          consentStatementId: id,
          entityType,
          consentStatementText: text.replace(/{charityName}/g, cause).replace(/{fundraiserName}/g, donationTarget),
          consentGiven: isConsentGiven,
          directMarketingType: type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL,
          hidden,
        };
      });

      return {
        donationCauseSourceRef,
        collectionLocationId,
        isConsentRequired: action.preferences.consents.some(
          ({ type, entityType }) =>
            type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL && entityType === EntityType.Charity,
        ),
        consents: mappedConsents,
      };
    }

    case 'preferences/updateConsent': {
      const { consentStatementId, consentGiven } = action.consent;
      const consentStatements = state.consents.map(consent => ({
        ...consent,
        consentGiven: consent.consentStatementId === consentStatementId ? consentGiven : consent.consentGiven,
      }));

      return { ...state, consents: consentStatements };
    }

    default:
      return state;
  }
};
