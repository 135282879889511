import { MarketCode } from '../../../../types/__generated-graphQL__';
import APP_SETTINGS from '../../../config';
import { queryCheckoutApi } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLCharityName,
  getGQLConsentLocationId,
  getGQLConsentUrl,
  getGQLPageOwnerOrCharityName,
  getGQLRef,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLMarketCode } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { Consent, setInitialPreferences } from '../../redux/preferences/preferences.actions';
import { createHeaders } from '../../utils/http';
import { logError } from '../../utils/logError';
import { getIsGoCardlessInstantBankPaymentOutcome } from '../PayWithBank/payWithBank.utils';

const getConsents =
  (): Thunk<Promise<unknown>> =>
  async (dispatch, _getState, { auth }) => {
    const { data } = await dispatch(queryCheckoutApi.endpoints.fetchCheckout.initiate());

    const marketCode = getGQLMarketCode(data);
    const defaultConsentToFalse = marketCode === MarketCode.Gb;

    // Do not fetch consents if the user is returning from pay with bank transfer flow
    if (getIsGoCardlessInstantBankPaymentOutcome()) return;

    const url = getGQLConsentUrl(data);
    const headers = createHeaders(auth);

    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers,
      });

      const consents: Consent[] = await response.json().catch(_error => {
        throw new Error('Failed to get consents json');
      });

      const donationTarget = getGQLPageOwnerOrCharityName(data) ?? '';
      const causeName = getGQLCharityName(data) ?? '';

      dispatch(
        setInitialPreferences(
          { donationTarget, causeName },
          {
            donationCauseSourceRef: getGQLRef(data) ?? '',
            collectionLocationId: getGQLConsentLocationId(data),
            marketCode,
            regionCode: response.headers.get('X-Region-Code') || '',
            consents,
          },
          defaultConsentToFalse,
        ),
      );
    } catch (error) {
      logError({
        tags: ['preference-service', 'consents', 'error'],
        baseUrl: APP_SETTINGS.PREFERENCE_SERVICE_CONSENT_URL,
        url,
        requestName: 'consentStatements',
        correlationId: headers['X-JG-RequestId'],
        req: {
          headers: {
            ...headers,
          },
        },
        message: error.message,
        stacktrace: error.stack,
      });
    }
  };

export default getConsents;
