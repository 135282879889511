import { LineItemType } from '../../../types/__generated-graphQL__';

export const PREFERENCES_SERVICE = {
  DONATION_RELATED_EMAIL: 'DonationRelatedEmail',
  DIRECT_MARKETING_EMAIL: 'DirectMarketingEmail',
};

export const CONSENT_LOCATION_ID = {
  [LineItemType.DonationCampaignFrp]: 17,
  [LineItemType.DonationCharityDirect]: 4,
  [LineItemType.DonationCharityFrp]: 5,
  [LineItemType.DonationCampaignDirect]: 6,
  [LineItemType.DonationCrowdfunding]: 7,
};
