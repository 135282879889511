import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '../../components/Image/Image';
import { useFetchPageQuery } from '../../redux/additionalPageDataApiSlice/additionalPageDataApiSlice';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityOrPageOwnerName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { checkVideoUrl } from '../../utils/checkVideoUrl';
import { isImageMedia, isVideoMedia, isLivestreamMedia } from '../../utils/typeGuards';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import * as styles from './SourcePagePreviewCover.css';

const SourcePagePreviewCover: FunctionComponent = () => {
  const { t } = useTranslation();
  const { accordionEnabled } = useAccordionExperiment();
  const { data } = useFetchCheckoutQuery();
  const charityOrPageOwnerName = getGQLCharityOrPageOwnerName(data);
  const { data: additionalPageData } = useFetchPageQuery();
  const hero = additionalPageData?.hero;
  const cover = additionalPageData?.cover;

  if (hero?.imageUrl) {
    return (
      <Image
        className={accordionEnabled ? styles.accordionCoverImage : styles.coverImage}
        src={hero?.imageUrl}
        alt={hero?.imageTitle || ''}
      />
    );
  }

  if (isImageMedia(cover)) {
    return (
      <Image
        className={accordionEnabled ? styles.accordionCoverImage : styles.coverImage}
        src={cover?.url}
        alt={cover?.alt || ''}
      />
    );
  }

  if (isVideoMedia(cover) || isLivestreamMedia(cover)) {
    const { isTwitch, isYoutube, videoUrl } = checkVideoUrl(
      isVideoMedia(cover) ? cover.embeddedVideoURL : cover.embeddedStreamURL,
    );
    const videoOrLivestreamTitle = isTwitch
      ? t('twitchTitle', { charityOrPageOwnerName })
      : t('youtubeTitle', { charityOrPageOwnerName });

    if (!isYoutube && !isTwitch) return null;

    return (
      <iframe
        src={videoUrl}
        className={accordionEnabled ? styles.accordionCoverVideo : styles.coverVideo}
        title={videoOrLivestreamTitle}
        height="400"
        width="100%"
        allowFullScreen
        frameBorder="0"
        scrolling="no"
        data-qa="livestream-video"
      />
    );
  }

  return <div className={styles.noCoverImage}></div>;
};

export default SourcePagePreviewCover;
