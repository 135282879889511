import { useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { AuthContext } from '@justgiving/auth-react';

import { PaymentType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../types/formValues';
import APP_SETTINGS from '../../../config';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLSupportedPaymentTypesForCurrency } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLSelectedPaymentType } from '../../redux/checkoutApiSlice/selectors/payment.selectors';

export const useIsRecurringPaymentAvailable = () => {
  const { data } = useFetchCheckoutQuery();
  const donationCurrencyCode = useWatch<AmountPageFormValues, 'donationCurrencyCode'>({ name: 'donationCurrencyCode' });
  const supportedPaymentTypes = getGQLSupportedPaymentTypesForCurrency(data, donationCurrencyCode);
  const selectedPaymentType = getGQLSelectedPaymentType(data);
  const auth = useContext(AuthContext);

  // Stops recurring elements remounting during force guest
  const isGuest = useMemo(() => {
    return (auth && auth.isGuest()) ?? false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (APP_SETTINGS.DISABLE_RECURRING || isGuest) {
    return { isRecurringCardAvailable: false, isDirectDebitAvailable: false };
  }

  const isRecurringCardAvailable = APP_SETTINGS.DISABLE_RECURRING_CARD_PAYMENT
    ? false
    : supportedPaymentTypes?.includes(PaymentType.RecurringPaymentCard) &&
      [null, PaymentType.PaymentCard].includes(selectedPaymentType ?? null);

  const isDirectDebitAvailable = supportedPaymentTypes?.includes(PaymentType.DirectDebit);

  return { isRecurringCardAvailable, isDirectDebitAvailable };
};
