import React, { FunctionComponent } from 'react';

import { PaymentCardHouseNameNumberInputWrapper } from '../Payment/PaymentCardHouseNameNumberInput';
import { PaymentCardZipOrPostalCodeInputWrapper } from '../Payment/PaymentCardZipOrPostalCodeInput';
import * as styles from './HousePostalCode.css';

const HousePostalCodeForm: FunctionComponent = () => {
  return (
    <div className={styles.wrapper}>
      <PaymentCardHouseNameNumberInputWrapper className={styles.item} />
      <PaymentCardZipOrPostalCodeInputWrapper className={styles.item} />
    </div>
  );
};

export default HousePostalCodeForm;
