const getColourContrast = (primaryColor: null | string): null | number => {
  if (primaryColor === null) {
    return primaryColor;
  }

  let hex = primaryColor;

  if (primaryColor.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  const red = parseInt(hex.slice(0, 2), 16) * 0.299;
  const green = parseInt(hex.slice(2, 4), 16) * 0.587;
  const blue = parseInt(hex.slice(4, 6), 16) * 0.114;

  const l = red + green + blue;
  return l;
};

export default getColourContrast;
