import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import { FormGroup } from '../../components/FormGroup/FormGroup';
import { RadioButton, RadioButtonGroup } from '../../components/RadioButtonGroup/RadioButtonGroup';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsCryptoPaymentAvailable } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { useIsRecurringPaymentAvailable } from './useRecurringAvailable';

const TransactionTypeTabs: FunctionComponent = () => {
  const { t } = useTranslation();
  const [transactionType, donationCurrencyCode] = useWatch<
    AmountPageFormValues,
    ['transactionType', 'donationCurrencyCode']
  >({
    name: ['transactionType', 'donationCurrencyCode'],
  });

  const { isRecurringCardAvailable, isDirectDebitAvailable } = useIsRecurringPaymentAvailable();
  const isRecurringAvailable = isRecurringCardAvailable || isDirectDebitAvailable;
  const { data } = useFetchCheckoutQuery();
  const isCryptoAvailable = getGQLIsCryptoPaymentAvailable(data, donationCurrencyCode);

  if (!isRecurringAvailable && !isCryptoAvailable) return null;

  return (
    <>
      <FormGroup
        legendId="transactionTypesLegend"
        legendText={t('amount|transactionTypesLegend', { ns: 'recurring' })}
        groupClassName={sprinkles({ marginTop: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}
      >
        <RadioButtonGroup>
          <RadioButton
            labelDataQa="transactionType-oneOff-label"
            value={TransactionTypes.ONE_OFF}
            checked={transactionType === TransactionTypes.ONE_OFF}
            shouldRegister
            name="transactionType"
            id="transactionType-oneOff"
          >
            {t('oneOff', { ns: 'recurring' })}
          </RadioButton>
          {isRecurringAvailable && (
            <RadioButton
              labelDataQa="transactionType-monthly-label"
              id="transactionType-monthly"
              shouldRegister
              name="transactionType"
              value={TransactionTypes.RECURRING}
              checked={transactionType === TransactionTypes.RECURRING}
            >
              {t('monthly', { ns: 'recurring' })}
            </RadioButton>
          )}
          {isCryptoAvailable && (
            <RadioButton
              labelDataQa="transactionType-crypto-label"
              shouldRegister
              name="transactionType"
              id="transactionType-crypto"
              value={TransactionTypes.CRYPTO}
              checked={transactionType === TransactionTypes.CRYPTO}
            >
              {t('cryptoTabLabel', { ns: 'crypto' })}
            </RadioButton>
          )}
        </RadioButtonGroup>
      </FormGroup>
    </>
  );
};

export default TransactionTypeTabs;
