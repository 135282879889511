import React, { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import classNames from 'classnames';

import * as styles from './Radio.css';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  children: React.ReactNode;
  className?: string;
  name: string;
  value: string;
  id: string;
  registerMethods?: RegisterOptions;
};

const Radio: FunctionComponent<Props> = ({ children, className, name, registerMethods, id, ...props }) => {
  const { register } = useFormContext();
  return (
    <label className={classNames(className, styles.label)} htmlFor={id}>
      <input className={styles.radio} {...register(name, registerMethods)} type="radio" id={id} {...props} />
      <span className={styles.checkboxIcon}></span>
      <span className={styles.labelWrapper}>{children}</span>
    </label>
  );
};

export default Radio;
