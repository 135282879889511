import React from 'react';

import { sprinkles } from '../../../styles/sprinkles.css';
import DonationTargetBar from '../../components/DonationTargetBar/DonationTargetBar';
import TransitionContent from '../../components/TransitionContent/TransitionContent';
import { useFetchPageQuery } from '../../redux/additionalPageDataApiSlice/additionalPageDataApiSlice';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import ThankYouInAdvance from '../ThankYouInAdvance/ThankYouInAdvance';
import SourcePagePreviewHeader from './SourcePagePreviewHeader';
import SourcePagePreviewTitles from './SourcePagePreviewTitles';

export const SourcePagePreview = () => {
  const { isLoading } = useFetchPageQuery();
  const { accordionEnabled } = useAccordionExperiment();

  return (
    <TransitionContent showContent={!isLoading} mode={'fade'}>
      <SourcePagePreviewHeader />
      <SourcePagePreviewTitles />
      <div
        className={sprinkles({
          marginH: accordionEnabled ? 'sizeSpacing04' : 'sizeSpacing05',
          marginTop: 'sizeSpacing05',
        })}
      >
        <DonationTargetBar />
        <ThankYouInAdvance />
      </div>
    </TransitionContent>
  );
};
