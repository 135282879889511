import React, { FunctionComponent, ReactNode } from 'react';

import * as styles from './AccordionPageSectionWrapper.css';

type AccordionPageSectionWrapperProps = {
  children: ReactNode;
};

export const AccordionPageSectionWrapper: FunctionComponent<AccordionPageSectionWrapperProps> = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
};
