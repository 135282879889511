import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroup } from '../../components/FormGroup/FormGroup';
import * as styles from './TipJarBox.css';
import { TipJarMessage } from './TipJarMessage';

type Props = {
  children?: ReactNode;
};

export const TipJarBox: FunctionComponent<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <FormGroup legendId="voluntaryContribution" legendText={t('tipToJustGiving')} groupClassName={styles.tipJarBox}>
      <TipJarMessage />
      {children}
    </FormGroup>
  );
};
