async function extractResponseBody(response: Response): Promise<{ text: undefined | string; json: any }> {
  if (response.bodyUsed) {
    return { text: '[Response body already consumed]', json: undefined };
  }

  try {
    const cloned = response.clone();
    const text = await cloned.text();

    try {
      return { text: undefined, json: JSON.parse(text) };
    } catch (error) {
      return { text, json: undefined };
    }
  } catch (error) {
    return { text: undefined, json: undefined };
  }
}

async function toSerializableHelper(data: unknown, circularDetectionRefs: WeakSet<any>): Promise<any> {
  if (circularDetectionRefs.has(data)) {
    return '[Circular Reference]';
  }

  if (data instanceof Response) {
    return {
      ok: data.ok,
      redirected: data.redirected,
      status: data.status,
      statusText: data.statusText,
      type: data.type,
      url: data.url,
      ...(await extractResponseBody(data)),
    };
  }

  if (data instanceof Error) {
    return {
      name: data.name,
      message: data.message,
      stack: data.stack,
    };
  }

  if (Array.isArray(data)) {
    return await Promise.all(data.map(x => toSerializableHelper(x, circularDetectionRefs)));
  }

  if (typeof data === 'object' && data !== null) {
    circularDetectionRefs.add(data);

    const out = {} as any;
    // eslint-disable-next-line guard-for-in
    for (const prop in data) {
      try {
        out[prop] = await toSerializableHelper((data as any)[prop], circularDetectionRefs);
      } catch (error) {
        out[prop] = '[Errored]';
      }
    }
    return out;
  }

  return data;
}

export default async function toSerializable(data: object): Promise<any> {
  try {
    return await toSerializableHelper(data, new WeakSet());
  } catch (error) {
    return {};
  }
}
