import APP_SETTINGS from '../../config';

export const setupSift = (checkoutId: string) => {
  const _sift = (window._sift = window._sift || []);
  _sift.push(['_setAccount', APP_SETTINGS.SIFT_SCIENCE_SNIPPET_KEY]);
  _sift.push(['_setSessionId', checkoutId]);

  // Cookiebot - not blocked - this is classed as "necessary"
  const e = document.createElement('script');
  e.src = 'https://cdn.siftscience.com/s.js';
  document.body.appendChild(e);
};

export const trackSiftSciencePageView = () => {
  if (typeof window._sift !== 'undefined' && window._sift.push) {
    window._sift.push(['_trackPageview']);
  }
};

export const setSiftScienceUserId = (userId?: string) => {
  if (typeof window._sift !== 'undefined' && window._sift.push) {
    window._sift.push(['_setUserId', userId || '']);
  }
};

export const setupAccertify = (checkoutId: string) => {
  if (!APP_SETTINGS.ACCERTIFY_DATA_COLLECTOR_URL) return;
  try {
    const s = document.createElement('script');
    s.setAttribute('type', 'text/javascript');
    s.setAttribute('src', APP_SETTINGS.ACCERTIFY_DATA_COLLECTOR_URL);
    s.setAttribute('dvct', '500');
    s.setAttribute('sid', checkoutId);
    s.setAttribute('dvc', 'l');

    s.onload = function () {
      if (window.hasOwnProperty('_bcn') && window?._bcn?.hasOwnProperty('dvc')) {
        window?._bcn?.dvc?.collect();
      }
    };

    document.head.appendChild(s);
  } catch (error) {}
};
