import { createSelector } from '@reduxjs/toolkit';

import { Checkout } from '../../../../../types/__generated-graphQL__';
import { stringToAcceptedCurrencyCodeMap } from '../../../utils/utilityMapperFunctions';

const getGQLAdditionalDataString = (data?: Checkout) => {
  return data?.additionalData;
};

export type AdditionalData = {
  redirectUrl?: string | null;
  skipMessage?: boolean;
  message?: string;
  currency?: string;
  selectedAmount?: number;
  branding?: {
    theme: string;
    header: string;
  };
  cause: {
    name: string;
    image: string | null;
    legacyId: string;
    thankYouMessage: string | null;
  };
  crowdfunding?: {
    category: string;
    pitchFor?: string;
    targetAmount?: string;
  };
  trackingId?: string;
  referrer?: string;
  checkoutMode?: 'Headless' | 'LowBranded';
  deepLinkUri?: string;
  startedBy?: string;
};

const _getGQLAdditionalData = createSelector(getGQLAdditionalDataString, additionalDataString => {
  try {
    return JSON.parse(additionalDataString) as AdditionalData;
  } catch (err) {
    return null;
  }
});

export const getGQLCheckoutMode = (data?: Checkout) => _getGQLAdditionalData(data)?.checkoutMode;

export const getGQLTrackingId = (data?: Checkout) => _getGQLAdditionalData(data)?.trackingId;

export const getGQLReferrer = (data?: Checkout) => _getGQLAdditionalData(data)?.referrer;

export const getGQLSkipMessage = (data?: Checkout) => _getGQLAdditionalData(data)?.skipMessage;

export const getGQLBranding = (data?: Checkout) => _getGQLAdditionalData(data)?.branding;

export const getGQLRedirectUrl = (data?: Checkout) => _getGQLAdditionalData(data)?.redirectUrl;

export const getGQLDeepLinkUri = (data?: Checkout) => _getGQLAdditionalData(data)?.deepLinkUri;

export const getGQLAdditionalDataCause = (data?: Checkout) => _getGQLAdditionalData(data)?.cause;

const _getGQLSelectedAmount = (data?: Checkout) => _getGQLAdditionalData(data)?.selectedAmount;

export const getGQLDefaultDonationValue = (data?: Checkout) => {
  const referrer = getGQLReferrer(data) ?? '';
  if (['linkservice', 'givingcheckout'].includes(referrer)) {
    return _getGQLSelectedAmount(data);
  }
  return;
};

export const getGQLAdditionalDataCurrencyCode = (data?: Checkout) => {
  return stringToAcceptedCurrencyCodeMap(_getGQLAdditionalData(data)?.currency);
};

export const getGQLCrowdfunding = (data?: Checkout) => _getGQLAdditionalData(data)?.crowdfunding;

export const getGQLStartedBy = (data?: Checkout) => _getGQLAdditionalData(data)?.startedBy;

export const getGQLIsExternalLinkServiceOrGivingCheckout = (data?: Checkout) => {
  const referrer = getGQLReferrer(data);
  const startedBy = getGQLStartedBy(data);

  return (
    ['linkservice', 'givingcheckout'].includes(referrer ?? '') &&
    (!startedBy || !['webjustgiving', 'cfwebsite'].includes(startedBy))
  );
};

export const getGQLIsPoweredByAvailable = createSelector(getGQLBranding, branding => {
  return Boolean(branding?.theme && branding?.header);
});

export const getGQLAdditionalDataMessage = (data?: Checkout) => _getGQLAdditionalData(data)?.message;
