import React, { FunctionComponent, Suspense, lazy, useEffect } from 'react';

import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import TransitionContent from '../../components/TransitionContent/TransitionContent';
import useAccordionExperiment from '../../modules/AccordionPages/useAccordionExperiment';
import AmexPWPThankYou from '../../modules/AmexPayWithPoints/AmexPWPThankYou';
import DonationInformation from '../../modules/ThankYou/DonationInformation';
import DonationReference from '../../modules/ThankYou/DonationReference';
import DonationStatusHeading from '../../modules/ThankYou/DonationStatusHeading';
import DonationStatusImage from '../../modules/ThankYou/DonationStatusImage';
import ExitApp from '../../modules/ThankYou/ExitApp';
import GiftAidEstimatedAmount from '../../modules/ThankYou/GiftAidEstimatedAmount';
import { RegularGivingPrompt } from '../../modules/ThankYou/RegularGivingPrompt';
import { Retry } from '../../modules/ThankYou/Retry';
import ThankyouMessage from '../../modules/ThankYou/ThankyouMessage';
import useInSession from '../../modules/ThankYou/useInSession';
import useOptimizelyTrack from '../../modules/ThankYou/useOptimizelyTrack';
import useRedirect from '../../modules/ThankYou/useRedirect';
import useScripts from '../../modules/ThankYou/useScripts';
import * as styles from './ThankYouPage.css';

const SocialSharingComp = lazy(
  () => import(/* webpackChunkName: "socialSharing" */ '../../modules/SocialSharing/SocialSharing'),
);

const ConfettiComp = lazy(() => import(/* webpackChunkName: "confetti" */ '../../modules/ThankYou/Confetti'));

const SocialSharing: FunctionComponent = () => {
  return (
    <ErrorBoundary fallbackContent={<></>}>
      <Suspense fallback={<></>}>
        <SocialSharingComp />
      </Suspense>
    </ErrorBoundary>
  );
};

const Confetti: FunctionComponent = () => {
  return (
    <ErrorBoundary fallbackContent={<></>}>
      <Suspense fallback={<></>}>
        <ConfettiComp />
      </Suspense>
    </ErrorBoundary>
  );
};

const ThankYouPage: FunctionComponent = () => {
  const { qualaroo } = useScripts();

  const status = useInSession();

  const inSessionComplete = ['directdebit_succeeded', 'succeeded', 'timeout'].includes(status);

  useEffect(() => {
    qualaroo();
  }, [qualaroo]);

  useOptimizelyTrack(status);
  const { accordionEnabled } = useAccordionExperiment();

  useRedirect(status);

  return (
    <>
      {inSessionComplete && <Confetti />}
      <div
        className={classNames({
          [styles.accordionWrapper]: accordionEnabled,
        })}
      >
        <div className={`donate-main ${sprinkles({ textAlign: 'center' })}`} data-qa="thankYouPage">
          <DonationStatusImage status={status} />
          <DonationStatusHeading donationStatus={status} />
          <TransitionContent showContent={status !== 'pending'}>
            {status === 'invalid' && (
              <div className={sprinkles({ marginV: 'sizeSpacing06', textAlign: 'center' })}>
                <Retry />
              </div>
            )}
            {inSessionComplete && (
              <>
                <SocialSharing />
                <DonationInformation donationStatus={status} />
                <AmexPWPThankYou />
                <ThankyouMessage />
                <GiftAidEstimatedAmount />
              </>
            )}
            {status !== 'pending' && (
              <>
                <DonationReference donationStatus={status} />
                <ExitApp />
              </>
            )}
          </TransitionContent>
        </div>
      </div>
      {inSessionComplete && <RegularGivingPrompt />}
    </>
  );
};

export default ThankYouPage;
