import { gql } from 'graphql-tag';

import { Checkout } from '../../../../types/__generated-graphQL__';
import { checkoutDetails } from '../../graphQL/fragmentCheckoutDetails';
import { getIsGoCardlessInstantBankPaymentOutcome } from '../../modules/PayWithBank/payWithBank.utils';
import { checkoutApiSlice } from './checkoutApiSlice';

export const queryCheckoutApi = checkoutApiSlice.injectEndpoints({
  endpoints: builder => ({
    fetchCheckout: builder.query<Checkout, void>({
      providesTags: ['Checkout'],
      query() {
        const isGoCardlessInstantBankPaymentOutcome = getIsGoCardlessInstantBankPaymentOutcome();

        return {
          variables: {
            input: {
              isPayWithBankTransfer: isGoCardlessInstantBankPaymentOutcome,
            },
          },
          document: gql`
            ${checkoutDetails}
            query checkout($input: CheckoutInput!) {
              checkout(input: $input) {
                ...checkoutDetails
              }
            }
          `,
        };
      },
      transformResponse: (response: { checkout: Checkout }) => response.checkout,
    }),
  }),
});

export const { useFetchCheckoutQuery, useLazyFetchCheckoutQuery } = queryCheckoutApi;
