import { useEffect } from 'react';

import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLRedirectUrl, getGQLReferrer } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLTransactionId } from '../../redux/checkoutApiSlice/selectors/transaction.selectors';
import { getValidUrl } from '../../utils/url';

export default function useRedirect(donationStatus: DonationStatus) {
  const { data } = useFetchCheckoutQuery();
  const redirectUrl = getGQLRedirectUrl(data);
  const referrer = getGQLReferrer(data);
  const transactionId = getGQLTransactionId(data);

  useEffect(() => {
    if (donationStatus === 'pending' || !redirectUrl || !transactionId) return;

    if (referrer === 'kilter' || donationStatus !== 'invalid') {
      window.location.assign(getValidUrl(redirectUrl).replace(/JUSTGIVING-DONATION-ID/g, transactionId));
    }
  }, [transactionId, donationStatus, redirectUrl, referrer]);
}
