import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { heading } from '../../../styles/recipes/headings.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import { useHeadingSetFocusRef } from '../../hooks/useHeadingSetFocusRef';
import * as styles from './directDebitGuarantee.css';

const DirectDebitGuarantee: FunctionComponent = () => {
  const { t } = useTranslation();
  const headingRef = useHeadingSetFocusRef();
  return (
    <article>
      <h1
        ref={headingRef}
        tabIndex={-1}
        className={classNames(sprinkles({ textAlign: 'center', marginBottom: 'sizeSpacing04' }), heading({ level: 3 }))}
      >
        {t('guarantee|heading', { ns: 'recurring' })}
      </h1>
      <p className={styles.subHeader}>{t('guarantee|subHeading', { ns: 'recurring' })}</p>
      <ol className={styles.list}>
        <li className={styles.listItem}>{t('guarantee|point1', { ns: 'recurring' })}</li>
        <li className={styles.listItem}>{t('guarantee|point2', { ns: 'recurring' })}</li>
        <li className={styles.listItem}>{t('guarantee|point3', { ns: 'recurring' })}</li>
        <li className={styles.listItem}>{t('guarantee|point4', { ns: 'recurring' })}</li>
      </ol>
    </article>
  );
};

export default DirectDebitGuarantee;
