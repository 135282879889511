import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { DocumentNode } from 'graphql';
import { ClientError } from 'graphql-request';
import { gql } from 'graphql-tag';

import { Checkout } from '../../../../types/__generated-graphQL__';
import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import { ReduxExtra } from '../../../../types/redux';
import APP_SETTINGS from '../../../config';
import { CONSTANTS } from '../../config/constants';
import { httpErrorMessage } from '../../config/httpErrorMessage';
import { checkoutDetails } from '../../graphQL/fragmentCheckoutDetails';
import { getIsGoCardlessInstantBankPaymentOutcome } from '../../modules/PayWithBank/payWithBank.utils';
import fetchWithTimeout from '../../utils/fetchWithTimeout';
import { getAuthHeader } from '../../utils/getAuthHeader';
import { getLoginURL } from '../../utils/getLoginURL';
import { isPaymentCard } from '../../utils/typeGuards';
import { updatePaymentCardsWithCVV } from '../session/session.actions';
import { getIsEmbedded, getSessionCheckoutId } from '../session/session.selectors';
import { checkoutApiSlice } from './checkoutApiSlice';

const mutatePaymentCard = checkoutApiSlice.injectEndpoints({
  endpoints: builder => ({
    mutatePaymentCard: builder.mutation<Checkout, AddPaymentCardPageFormValues>({
      async queryFn(
        formData,
        queryApi,
        extraOptions,
        baseQuery: BaseQueryFn<
          { document: string | DocumentNode; variables?: any },
          { checkout: Checkout },
          {
            message: string;
            status: number;
            stack?: string;
          },
          Partial<Pick<ClientError, 'request' | 'response'>> & { useCheckoutId?: boolean }
        >,
      ) {
        const auth = (queryApi.extra as ReduxExtra)?.auth;
        const state = queryApi.getState() as State;
        const checkoutId = getSessionCheckoutId(state);

        const {
          cardNumber,
          nameOnCard,
          cvv,
          cardExpiryMonth,
          cardExpiryYear,
          saveCardForFutureUse,
          houseNameOrNumber,
          zipOrPostalCode,
          addressLine1,
          addressLine2,
          townOrCity,
          countyStateRegion,
          countryCode,
        } = formData;

        const shouldSaveCardByDefault = !auth.isGuest() && !APP_SETTINGS.DISABLE_ADDING_SAVED_CARD;

        const cardNumberWithTrimmed = cardNumber.replace(/\s/g, '');

        const body = JSON.stringify({
          nameOnCard,
          pan: cardNumberWithTrimmed,
          cardSecurityCode: cvv,
          bin: cardNumberWithTrimmed.substring(0, 6),
          lastFourDigits: cardNumberWithTrimmed.slice(-4),
          expiryMonth: cardExpiryMonth,
          expiryYear: cardExpiryYear.length === 4 ? cardExpiryYear.slice(-2) : cardExpiryYear,
          saveCardForFutureUse: saveCardForFutureUse ?? shouldSaveCardByDefault,
          billing_Selection: null,
          billing_AddressLine1: houseNameOrNumber ?? addressLine1 ?? null,
          billing_AddressLine2: addressLine2 ?? null,
          billing_TownCity: townOrCity ?? null,
          billing_CountyStateRegion: countyStateRegion || null,
          billing_Country: countryCode ?? null,
          billing_PostalCode: zipOrPostalCode,
        });

        const url = `${APP_SETTINGS.DONATION_API_URL}/${checkoutId}/paymentcard`;

        const authHeader = getAuthHeader(auth);
        const headers = new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeader || '',
        });

        if (auth.isUserLoggedIn() && !auth.isGuest()) auth.refreshAccessTokenIfExpired();

        const response = await fetchWithTimeout(url, {
          method: 'POST',
          headers,
          body,
          credentials: 'include',
        });

        const json: Record<string, any> = await response.json().catch(() => {});

        if (response.status === 401) {
          if (json?.owningUserType === 'LoggedIn') {
            const isEmbedded = getIsEmbedded(state);
            const loginURL = getLoginURL(isEmbedded);
            window.location.assign(loginURL);
            return {
              error: {
                status: 401,
                message: httpErrorMessage.UNAUTHORIZED_RECOVERABLE,
              },
            };
          }

          return {
            error: {
              status: 401,
              message: response.statusText,
            },
          };
        }

        if (json?.error) {
          // For Checkout API response
          const error = json.error;

          if (error !== CONSTANTS.CARD_VERIFICATION_DECLINED) {
            window.Sentry?.withScope?.(function (scope) {
              scope.setFingerprint(error);
              scope.setExtra('Response', { error });
            });
            window.Sentry?.captureException?.(new Error(error));
          }

          return {
            error: {
              status: 500,
              message: CONSTANTS.CARD_VERIFICATION_DECLINED,
            },
          };
        } else if (json?.errors) {
          // Also for Checkout API response
          const firstKey = Object.keys(json.errors)[0];
          const firstError = json.errors[firstKey]?.[0];

          if (firstError) {
            window.Sentry?.withScope?.(function (scope) {
              scope.setFingerprint(firstError);
              scope.setExtra('Response', json.errors);
            });
            window.Sentry?.captureException?.(new Error(firstError));
          }

          return {
            error: {
              status: 500,
              message: CONSTANTS.CARD_VERIFICATION_DECLINED,
            },
          };
        } else if (!response.ok) {
          return {
            error: {
              status: 500,
              message: response.statusText,
            },
          };
        }
        const isGoCardlessInstantBankPaymentOutcome = getIsGoCardlessInstantBankPaymentOutcome();

        // TODO Once all the save card endpoints (dc, checkout does not) return the payment card id we can explore changing this to cache invalidation and return payment card id from this query, potentially change it to a query rather than a queryfn
        const queryResponse = await baseQuery(
          {
            variables: {
              input: {
                isPayWithBankTransfer: isGoCardlessInstantBankPaymentOutcome,
              },
            },
            document: gql`
              ${checkoutDetails}
              query checkout($input: CheckoutInput!) {
                checkout(input: $input) {
                  ...checkoutDetails
                }
              }
            `,
          },
          queryApi,
          { ...extraOptions, useCheckoutId: true },
        );
        if (!queryResponse.data) {
          return { error: { status: 404, message: '' } };
        }

        // TODO Once all the save card endpoints (dc, checkout does not) return the payment card id we can explore changing this to cache invalidation and actually return the id from this mutation, that point we might be able to make this a query rather than a queryfn
        const paymentDetails = queryResponse.data?.checkout.payment?.details;
        const paymentCardId = isPaymentCard(paymentDetails) ? paymentDetails.paymentCardId : null;
        if (!paymentCardId) {
          throw new Error('No paymentCardId available after saving payment card');
        }

        queryApi.dispatch(updatePaymentCardsWithCVV(paymentCardId));
        window.heap?.addEventProperties?.({ selectedPaymentType: 'paymentcard' });

        return { data: queryResponse.data.checkout };
      },
      async onQueryStarted(_formData, { dispatch, queryFulfilled }) {
        try {
          const { data: checkout } = await queryFulfilled;
          // @ts-ignore
          dispatch(mutatePaymentCard.util.updateQueryData('fetchCheckout', undefined, () => checkout));
        } catch (err) {
          // Do nothing
        }
      },
    }),
  }),
});

export const { useMutatePaymentCardMutation } = mutatePaymentCard;
