import {
  AcceptedCurrencyCode,
  Checkout,
  PaymentType,
  PointsScheme,
  SuggestedAmount,
} from '../../../../../types/__generated-graphQL__';
import { CONSTANTS } from '../../../config/constants';
import { isCurrencyDefaults } from '../../../utils/typeGuards';
import { convertPenniesToPounds } from '../../../utils/utilityMapperFunctions';

const _getGQLOrder = (data?: Checkout) => data?.order;

export const getGQLLineItems = (data?: Checkout) => _getGQLOrder(data)?.lineItems;

export const getGQLAppliedTipScheme = (data?: Checkout) => _getGQLOrder(data)?.appliedTipScheme;

export const getGQLRequiresFullBillingAddress = (data?: Checkout) => _getGQLOrder(data)?.requiresFullBillingAddress;

export const getGQLCurrencyCode = (data?: Checkout) => _getGQLOrder(data)?.currency;

export const getGQLTotalAmountInPounds = (data?: Checkout) => convertPenniesToPounds(_getGQLOrder(data)?.totalAmount);

export const getGQLPlatformFeePercentage = (data?: Checkout) =>
  _getGQLOrder(data)?.fees?.platformFeePercentage ?? CONSTANTS.PLATFORM_FEE_PERCENTAGE_DEFAULT;

export const getGQLTipAmountInPounds = (data?: Checkout) => convertPenniesToPounds(_getGQLOrder(data)?.tipAmount);

const _getGQLSupportedCurrencies = (data?: Checkout) => _getGQLOrder(data)?.supportedCurrencies ?? {};

const _getGQLCurrencyDefaultsForCurrency = (data?: Checkout, currencyCode?: AcceptedCurrencyCode | null) => {
  if (!currencyCode) return null;
  return _getGQLSupportedCurrencies(data)?.[currencyCode];
};

export const getGQLSupportedCardTypes = (data?: Checkout) => {
  const currencyCode = getGQLCurrencyCode(data);
  return _getGQLCurrencyDefaultsForCurrency(data, currencyCode)?.supportedCardTypes;
};

const _getGQLSupportedPointsSchemes = (data?: Checkout) => {
  const currencyCode = getGQLCurrencyCode(data);
  return _getGQLCurrencyDefaultsForCurrency(data, currencyCode)?.supportedPointsSchemes;
};

export const getGQLIsAmexPayWithPointsAvailable = (data?: Checkout) => {
  return _getGQLSupportedPointsSchemes(data)?.includes(PointsScheme.AmexPayWithPoints);
};

export const getGQLSupportedPaymentTypesForCurrency = (data?: Checkout, dynamicCurrencyCode?: AcceptedCurrencyCode) => {
  const appliedCurrencyCode = getGQLCurrencyCode(data);
  return _getGQLCurrencyDefaultsForCurrency(data, dynamicCurrencyCode ?? appliedCurrencyCode)?.supportedPaymentTypes;
};

export const getGQLSuggestedAmountsForCurrencyInPounds = (data?: Checkout, currencyCode?: AcceptedCurrencyCode) => {
  return (
    _getGQLCurrencyDefaultsForCurrency(data, currencyCode)?.suggestedAmounts.map(suggestedAmount => {
      return {
        ...suggestedAmount,
        value: convertPenniesToPounds(suggestedAmount.value),
      } as SuggestedAmount;
    }) ?? []
  );
};

export const getGQLMinAndMaxAmountsForCurrencyInPounds = (data?: Checkout, currencyCode?: AcceptedCurrencyCode) => {
  const currencyDefaults = _getGQLCurrencyDefaultsForCurrency(data, currencyCode);
  if (!currencyDefaults) return null;
  return {
    maximum: convertPenniesToPounds(currencyDefaults?.maximum),
    minimum: convertPenniesToPounds(currencyDefaults?.minimum),
  };
};

export const getGQLCurrencyCodeContainingSuggestedAmountDescriptions = (data?: Checkout) => {
  const supportedCurrencies = _getGQLSupportedCurrencies(data);
  let currencyCodeContainingSuggestedAmountDescriptions: AcceptedCurrencyCode | undefined;

  Object.keys(supportedCurrencies).forEach(currency => {
    const acceptedCurrency = currency as AcceptedCurrencyCode;
    if (supportedCurrencies?.[acceptedCurrency]?.suggestedAmounts[0]?.description) {
      currencyCodeContainingSuggestedAmountDescriptions = acceptedCurrency;
    }
  });

  return currencyCodeContainingSuggestedAmountDescriptions;
};

export const getGQLSupportedCurrencyCodeList = (data?: Checkout) => {
  const supportedCurrencies = _getGQLSupportedCurrencies(data);
  return Object.keys(supportedCurrencies)
    .filter(currency => Boolean(supportedCurrencies[currency as AcceptedCurrencyCode]))
    .sort() as AcceptedCurrencyCode[];
};

export const getGQLIsRegularGivingAvailableForAnySupportedCurrency = (data?: Checkout) =>
  Object.values(_getGQLSupportedCurrencies(data)).some(
    currencyDefaults =>
      isCurrencyDefaults(currencyDefaults) &&
      (currencyDefaults.supportedPaymentTypes.includes(PaymentType.DirectDebit) ||
        currencyDefaults.supportedPaymentTypes.includes(PaymentType.RecurringPaymentCard)),
  );

export const getGQLVoluntaryContributionConfig = (data?: Checkout) => _getGQLOrder(data)?.voluntaryContributionConfig;
