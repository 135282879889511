export const calculatePercantageRaised = (targetValue: number, raisedValue?: number) => {
  // If the raised percentage is 0 or below 1 we want to show a small amount of percentage (1%) on the bar
  if (!raisedValue) return 1;

  const currentPercentage = Math.floor((raisedValue / targetValue) * 100);

  if (currentPercentage < 1) return 1;
  if (currentPercentage > 100) return 100;
  return currentPercentage;
};
