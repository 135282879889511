import { Page, ProductType, ThankYouMessageType } from '../../../../../types/__generated-graphQL__';

const _getGQLAdditionalPageDataParents = (data?: Page) => data?.relationships?.parentsConnection?.nodes;

export const getGQLAdditionalPageDataParentTeamName = (data?: Page) =>
  _getGQLAdditionalPageDataParents(data)?.filter(parent => parent.productName === ProductType.Team)[0]?.page?.slug;

export const getGQLAdditionalPageDataParentCampaignThankYouMessage = (data?: Page) =>
  _getGQLAdditionalPageDataParents(data)
    ?.filter(parent => parent.productName === ProductType.Campaign)[0]
    ?.page?.fundraisingSettings?.thankYouMessages.filter(message => message?.type === ThankYouMessageType.Donation)[0]
    ?.message;
