import React, { FunctionComponent, ReactNode } from 'react';

import classNames from 'classnames';

import * as styles from './ButtonTextLink.css';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  className?: string;
  alwaysUnderline?: boolean;
};

const ButtonTextLink: FunctionComponent<Props> = ({ children, className, alwaysUnderline = true, ...otherProps }) => {
  return (
    <button
      type="button"
      className={classNames(styles.textLink, className, { [styles.underlineOnHover]: !alwaysUnderline })}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default ButtonTextLink;
