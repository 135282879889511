import React, { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import { DonationDescriptions } from '../../modules/DonationDescriptions/useDonationDescriptions';
import * as visuallyHiddenStyles from '../VisuallyHidden/VisuallyHidden.css';
import * as styles from './RadioButtonGroup.css';

type RadioButtonGroupProps = {
  withGap?: boolean;
  children?: React.ReactNode;
};
export const RadioButtonGroup: FunctionComponent<RadioButtonGroupProps> = ({ children, withGap }) => {
  return <div className={classNames(styles.buttonGroup, { [styles.withGap]: withGap })}>{children}</div>;
};

const RadioButtonGroupArrow = () => (
  <div className={styles.arrow}>
    <svg
      viewBox="0 0 24 12"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinecap: 'round',
        strokeMiterlimit: 1.5,
      }}
    >
      <path className={styles.arrowPath} d="M12 .686.686 12 12 23.314 23.314 12 12 .686Z" />
    </svg>
  </div>
);

type RadioButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
  labelDataQa?: string;
  inputDataQa?: string;
  hasDescription?: boolean;
  children: React.ReactNode;
  ariaDescribedBy?: string;
  registerMethods?: RegisterOptions;
  name: string;
  value: string | number;
  shouldRegister?: boolean;
};

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
  inputDataQa,
  labelDataQa,
  hasDescription,
  children,
  ariaDescribedBy,
  shouldRegister,
  registerMethods = {},
  name,
  ...props
}) => {
  const { register } = useFormContext();
  return (
    <label
      data-qa={labelDataQa}
      className={classNames(styles.button, {
        [styles.isActive]: props.checked,
      })}
      htmlFor={props.id}
    >
      {hasDescription && <RadioButtonGroupArrow />}
      <input
        type="radio"
        data-qa={inputDataQa ?? props.id}
        className={visuallyHiddenStyles.visuallyHidden}
        aria-describedby={ariaDescribedBy}
        name={name}
        {...(shouldRegister && register(name, registerMethods))}
        {...props}
      />
      {children}
    </label>
  );
};

export const RadioButtonGroupDescription: FunctionComponent<Pick<DonationDescriptions, 'descriptions'>> = ({
  descriptions,
}) => {
  return descriptions.map(description => (
    <div
      key={description.id}
      id={description.id}
      className={classNames(
        { [styles.description]: description.selected },
        { [sprinkles({ display: 'none' })]: !description.selected },
      )}
      aria-hidden
    >
      {description.description}
    </div>
  ));
};
