import { gql } from 'graphql-tag';

import { Checkout, UpdateCheckoutPaymentTypeInput } from '../../../../types/__generated-graphQL__';
import { checkoutDetails } from '../../graphQL/fragmentCheckoutDetails';
import { checkoutApiSlice } from './checkoutApiSlice';

export const mutatePaymentMethod = checkoutApiSlice.injectEndpoints({
  endpoints: builder => ({
    mutatePaymentMethod: builder.mutation<Checkout, Omit<UpdateCheckoutPaymentTypeInput, 'checkoutId'>>({
      query({ selectedPaymentType }) {
        window.heap?.addEventProperties?.({ selectedPaymentType });

        return {
          variables: {
            input: {
              selectedPaymentType,
            },
          },
          document: gql`
            ${checkoutDetails}
            mutation updateCheckoutPaymentType($input: UpdateCheckoutPaymentTypeInput!) {
              updateCheckoutPaymentType(input: $input) {
                ...checkoutDetails
              }
            }
          `,
        };
      },
      transformResponse: (response: { updateCheckoutPaymentType: Checkout }) => response.updateCheckoutPaymentType,
      async onQueryStarted(_input, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // @ts-ignore
          dispatch(mutatePaymentMethod.util.updateQueryData('fetchCheckout', undefined, () => data));
        } catch (err) {
          // Do nothing
        }
      },
    }),
  }),
});

export const { useMutatePaymentMethodMutation } = mutatePaymentMethod;
