import APP_SETTINGS from '../../config';

export const APP_VERSION =
  document?.querySelector('meta[data-application-version]')?.getAttribute('data-application-version') ?? '0.0.0';

const logErrorExclusionCheck = (message?: string) => {
  if (
    [
      'Google pay: CANCELED',
      'PAYPAL_POPUP_CLOSED',
      'User closed the Payment Request UI',
      'Failed to fetch',
      'Load failed',
      'NetworkError when attempting to fetch resource',
    ].includes(message ?? '')
  ) {
    return true;
  }
  return false;
};

export const logError = async (err: Record<string, any>, customData: Record<string, any> = {}) => {
  if (logErrorExclusionCheck(err?.message)) return;
  const { LOGGING_API_URL } = APP_SETTINGS;

  const body = {
    ...customData,
    message: err?.message ?? '',
    app: {
      name: 'GG.FrontEnd.App.DonationCollection',
      version: APP_VERSION,
    },
    // TODO Start passing in checkoutId in customData in all uses of logError or find a different solution to access this from state
    // donationCollectionId: options?.donationCollectionId ?? '',
  };

  try {
    if (APP_SETTINGS.env === 'local') {
      return console.log('LogError -> ', body);
    }

    await fetch(`${LOGGING_API_URL}/error/js`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  } catch (error) {
    // Do nothing
  }
};

export const captureSentryException = (response: any, checkoutId?: string) => {
  const firstError = response?.errors?.[0] ?? {};
  const exception = firstError?.extensions?.exception;

  try {
    if ((response?.status === 400 || exception?.responseStatus === 400) && firstError.message) {
      window.Sentry?.withScope?.(function (scope) {
        scope.setFingerprint([firstError.message]);
        scope.setExtra('Response', {
          ...(exception ?? {}),
          checkoutId,
        });
        window.Sentry?.captureException?.(new Error(firstError.message));
      });
    }
  } catch (error) {
    // Do nothing
  }
};
