import React, { FunctionComponent } from 'react';

import { Tooltip as CarepackTooltip } from '@justgiving/carepack-tooltip';
import '@justgiving/carepack-tooltip/tooltip.css';
import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import * as visuallyHiddenStyles from '../VisuallyHidden/VisuallyHidden.css';
import * as styles from './Tooltip.css';

type TooltipProps = {
  children: React.ReactNode;
  id: string;
  buttonText?: string | React.ReactNode;
  buttonAccessibleLabel?: string;
  buttonClassName?: string;
  contentClassName?: string;
  showCloseButton?: boolean;
};

export const Tooltip: FunctionComponent<TooltipProps> = ({
  buttonText,
  buttonAccessibleLabel,
  children,
  contentClassName = '',
  buttonClassName = '',
  id,
  showCloseButton = true,
}) => {
  const hasText = Boolean(buttonText);
  const textClasses = classNames({ [visuallyHiddenStyles.visuallyHidden]: !hasText, [styles.triggerText]: hasText });

  return (
    <CarepackTooltip>
      <CarepackTooltip.Trigger id={id} asChild={true}>
        <button
          type="button"
          className={classNames(styles.button, buttonClassName, {
            [styles.iconButton]: !hasText,
          })}
          aria-label={buttonAccessibleLabel}
          aria-describedby={`${id}-tooltip-description`}
          data-qa={`${id}-tooltip-trigger`}
        >
          {buttonText &&
            (typeof buttonText === 'string' ? <span className={textClasses}>{buttonText}</span> : buttonText)}
        </button>
      </CarepackTooltip.Trigger>
      <CarepackTooltip.Content aria-hidden className={styles.tooltipContent} data-qa={`${id}-tooltip-content`}>
        <div className={contentClassName || sprinkles({ color: 'light' })}>{children}</div>
        {showCloseButton && (
          <CarepackTooltip.CloseTrigger className={styles.tooltipClose} data-qa={`${id}-tooltip-close`} />
        )}
      </CarepackTooltip.Content>
      {/* div below duplicates content for the benefit of AT - so that it immediately follows the trigger in reading order */}
      <VisuallyHidden as="div" id={`${id}-tooltip-description`} role="tooltip">
        {children}
      </VisuallyHidden>
    </CarepackTooltip>
  );
};
