import { initReactI18next } from 'react-i18next';

import backend from '@justgiving/i18next-dynamic-import-backend';
import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

export const initI18n = () =>
  i18n
    .use(initReactI18next)
    .use(
      backend(
        async (lang, namespace) => {
          return import(`../lang/${lang}/${namespace}.ts`);
        },
        // By default, the backend will return an empty object when 'en' or
        // 'en-GB' is requested. This overrides that default behaviour.
        { loadDefault: true },
      ),
    )
    .use(LngDetector)
    .init({
      // Ensure that the Intl polyfill locales are also loaded to match these
      supportedLngs: ['en', 'en-GB', 'en-US', 'en-CA'],
      ns: [
        'common',
        'ab',
        'amexPwp',
        'recurring',
        'giftAid',
        'tipJar',
        'dpf',
        'socialSharing',
        'chromeHeader',
        'cookie',
        'messagePrompts',
        'addressLookup',
        'fixedAmount',
        'page',
        'validation',
        'alert',
        'status',
        'bbgf',
        'payment',
        'message',
        'amount',
        'crypto',
      ],
      defaultNS: 'common',

      fallbackLng: 'en',
      keySeparator: '.',
      nsSeparator: ':',

      interpolation: {
        escapeValue: false,
      },

      detection: {
        order: ['querystring', 'navigator'],
      },
    });
