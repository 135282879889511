import React from 'react';

import * as styles from './Divider.css';

type Props<T extends React.ElementType> = { children?: React.ReactNode; as?: T };

const Divider = <T extends React.ElementType = 'hr'>({
  children,
  as,
}: Props<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof Props<T>>) => {
  const Component = as || 'hr';
  if (children) {
    return <div className={styles.dividerWithChildren}>{children}</div>;
  }
  return <Component className={styles.divider} />;
};

export default Divider;
