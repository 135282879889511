import { useDispatch, useSelector } from 'react-redux';

import { ApplePay, GooglePayment, Venmo, DataCollector, PayPal } from 'braintree-web';

import { PaymentServiceProvider, PaymentType } from '../../../types/__generated-graphQL__';
import { useMutateInitialiseCheckoutWalletTokenMutation } from '../redux/checkoutApiSlice/mutateInitialiseCheckoutWalletToken';
import { braintreeInitialised } from '../redux/session/session.actions';
import { getIsBraintreeInitialised } from '../redux/session/session.selectors';

export let dataCollectorInstance: DataCollector;
export let applePay: { instance?: ApplePay; enabled: boolean } = { enabled: false };
export let googlePay: { instance?: GooglePayment; enabled: boolean } = { enabled: false };
export let paypal: { instance?: PayPal; enabled: boolean } = { enabled: false };
export let venmo: { instance?: Venmo; enabled: boolean } = { enabled: false };

export const useInitialiseBraintree = () => {
  const dispatch = useDispatch();
  const isBraintreeInitialised = useSelector(getIsBraintreeInitialised);
  const [mutateInitialiseCheckoutWalletToken] = useMutateInitialiseCheckoutWalletTokenMutation();

  const initialiseBraintree = async () => {
    try {
      if (isBraintreeInitialised) return;

      const { data } = await mutateInitialiseCheckoutWalletToken({
        paymentServiceProvider: PaymentServiceProvider.Braintree,
      });

      if (!data) throw new Error('No braintree token');

      dispatch(braintreeInitialised());

      const createInstances = await import(
        /* webpackChunkName: "createBraintreePaymentInstances" */ '../utils/createBraintreePaymentInstances'
      );
      const instances = await createInstances.createBraintreePaymentInstances(data?.token);

      if (instances.dataCollectorInstance) dataCollectorInstance = instances.dataCollectorInstance;
      if (instances.applePayInstance) applePay = { instance: instances.applePayInstance, enabled: true };
      if (instances.googlePayInstance) googlePay = { instance: instances.googlePayInstance, enabled: true };
      if (instances.paypalInstance) paypal = { instance: instances.paypalInstance, enabled: true };
      if (instances.venmoInstance) venmo = { instance: instances.venmoInstance, enabled: true };
    } catch (err) {
      // Do nothing
    }
  };
  return { initialiseBraintree };
};

export const disablePaymentType = (payMethod?: PaymentType) => {
  switch (payMethod) {
    case PaymentType.BraintreeApplePay:
      applePay.enabled = false;
      return;
    case PaymentType.BraintreeGooglePay:
      googlePay.enabled = false;
      return;
    case PaymentType.BraintreePayPal:
      paypal.enabled = false;
      return;
    case PaymentType.BraintreeVenmo:
      venmo.enabled = false;
      return;
    default:
      return;
  }
};
