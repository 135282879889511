import { LineItemType } from '../../../types/__generated-graphQL__';
import { PageName } from '../config/pages';
import { getIsGoCardlessInstantBankPaymentOutcome } from '../modules/PayWithBank/payWithBank.utils';
import { useLazyFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLSkipMessage } from '../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLDisplayGiftAid,
  getGQLShouldRouteToPWPPage,
} from '../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLSkipGiftAid } from '../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import { getGQLGiftAidIsClaimed } from '../redux/checkoutApiSlice/selectors/giftAid.selectors';
import {
  getGQLLineItemValueInPounds,
  getGQLMessage,
  getGQLOrderType,
} from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../redux/checkoutApiSlice/selectors/order.selectors';
import {
  getGQLIsDirectDebitSelected,
  getGQLSelectedPaymentType,
} from '../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLIsTransactionComplete } from '../redux/checkoutApiSlice/selectors/transaction.selectors';

export const useInitialRoute = () => {
  const [queryCheckout] = useLazyFetchCheckoutQuery();

  const initialRouteName = async (): Promise<PageName | null> => {
    const { data } = await queryCheckout(undefined, true);
    const isTransactionComplete = getGQLIsTransactionComplete(data);
    const skipMessage = getGQLSkipMessage(data);
    const isDirectDebitSelected = getGQLIsDirectDebitSelected(data);
    const selectedPaymentType = getGQLSelectedPaymentType(data);
    const hasSelectedPaymentType = Boolean(selectedPaymentType);
    const isGiftAidClaimed = getGQLGiftAidIsClaimed(data);
    const skipGiftAid = getGQLSkipGiftAid(data);
    const isGiftAidSupported = !skipGiftAid && getGQLDisplayGiftAid(data);
    const shouldRouteToPWPPage = getGQLShouldRouteToPWPPage(data);
    const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
    const apiCurrencyCode = getGQLCurrencyCode(data);
    const message = getGQLMessage(data);
    const isSkipMessagePage =
      skipMessage || getGQLOrderType(data) === LineItemType.DonationCharityDirect || isDirectDebitSelected;

    const shouldRouteToGiftAid = isGiftAidSupported && isGiftAidClaimed === null;

    if (isTransactionComplete) {
      return 'ThankYouPage';
    }

    if (getIsGoCardlessInstantBankPaymentOutcome()) return 'ThankYouPage';

    if (!lineItemValueInPounds || !apiCurrencyCode) {
      return 'AmountPage';
    }

    if (!isSkipMessagePage && message === null) return 'MessagePage';

    if (shouldRouteToPWPPage) return 'AmexPayWithPointsYourPaymentPage';

    if (!hasSelectedPaymentType) return 'SelectPaymentPage';

    if (shouldRouteToGiftAid) return 'GiftAidDeclarationPage';

    return 'SummaryPage';
  };

  return { initialRouteName };
};
