import React, { FunctionComponent, useEffect } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import classNames from 'classnames';

import * as styles from './Select.css';

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  name: string;
  children: React.ReactNode;
  registerMethods?: RegisterOptions;
  shouldFocusOnMount?: boolean;
};

const Select: FunctionComponent<Props> = ({
  children,
  name,
  className,
  registerMethods,
  shouldFocusOnMount,
  ...props
}) => {
  const {
    register,
    setFocus,
    formState: { errors },
  } = useFormContext();
  const isError = Boolean(errors[name]);

  useEffect(() => {
    if (shouldFocusOnMount) setFocus(name);
  }, [shouldFocusOnMount, name, setFocus]);

  return (
    <select
      className={classNames(styles.select, className, { 'is-error': isError })}
      {...props}
      aria-invalid={isError}
      {...register(name, registerMethods)}
    >
      {children}
    </select>
  );
};

export default Select;
