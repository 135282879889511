import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';

import APP_SETTINGS from '../../../config';
import usePortal from '../../hooks/usePortal';
import useAccordionExperiment from '../../modules/AccordionPages/useAccordionExperiment';
import * as styles from './alert.css';

type Props = {
  alertType: 'red' | 'amber';
  qaSelector?: string;
  children?: ReactNode;
  dontSnackbar?: boolean;
  showSideBorders?: boolean;
};

const Alert: FunctionComponent<Props> = ({ alertType, qaSelector, children, dontSnackbar, showSideBorders }) => {
  const [alertClosed, setAlertClosed] = useState(false);
  const target = usePortal('alert');
  const { accordionEnabled } = useAccordionExperiment();

  const alertEl = useRef<HTMLDivElement>(null);

  const wrapperClasses = classNames({
    [styles.alertRed]: alertType === 'red',
    [styles.alertAmber]: alertType === 'amber',
  });

  const closeOnEscape = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setAlertClosed(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscape, false);

    return () => {
      document.removeEventListener('keydown', closeOnEscape, false);
    };
  }, [closeOnEscape]);

  if (!dontSnackbar && (APP_SETTINGS.ENABLE_SNACKBAR_ERRORS || accordionEnabled)) {
    return createPortal(
      <div className={classNames(styles.snackbar, { [styles.snackbarClosed]: alertClosed })}>
        <div className={styles.snackbarContent}>
          <div ref={alertEl} tabIndex={-1} className={wrapperClasses} data-qa={qaSelector} role="alert">
            <div className={styles.body}>{children}</div>
            <button type="button" className={styles.close} onClick={() => setAlertClosed(true)}>
              Close
            </button>
          </div>
        </div>
      </div>,
      target,
    );
  }

  return (
    <div
      ref={alertEl}
      tabIndex={-1}
      className={classNames(wrapperClasses, { [styles.sideBorders]: showSideBorders })}
      data-qa={qaSelector}
      role="alert"
    >
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default Alert;
