import i18next from 'i18next';

import { AcceptedCurrencyCode } from '../../../types/__generated-graphQL__';

export const addZerosIfRequired = (value: string | number, numOfZeros = 2) =>
  Number.isInteger(value) ? value : Number(value).toFixed(numOfZeros);

export const roundNumber = (value: number, twoDp = true) => (twoDp ? Math.round(value * 100) / 100 : Math.round(value));

export const formatPoints = (value: number, locale = i18next.language) =>
  roundNumber(value, false).toLocaleString(locale);

export const formatCurrency = (
  currencyCode: AcceptedCurrencyCode | null | undefined,
  amount: string | number,
  alwaysShowFractions = true,
  locale = i18next.language,
) => {
  if (!currencyCode) return amount.toString();

  return `${new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode.toString(),
    ...(!alwaysShowFractions && Number(amount) % 1 === 0
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 2 }),
  }).format(addZerosIfRequired(amount || '0') as number)}`;
};
