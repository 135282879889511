import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import * as styles from './Loading.css';

type Props = {
  qaSelector?: string;
  size?: 's' | 'l';
  color?: 'primary' | 'white' | 'grey';
  children?: ReactNode;
  className?: string;
};

const Loading: FunctionComponent<Props> = ({ qaSelector = 'loader', children, size, className, color }) => {
  const { t } = useTranslation();
  return (
    <>
      <span
        data-qa={qaSelector}
        className={classNames(styles.loadingWrapper, className, {
          [styles.large]: size === 'l',
          [styles.small]: size === 's',
          [styles.primary]: color === 'primary',
          [styles.white]: color === 'white',
          [styles.grey]: color === 'grey',
        })}
      >
        {!children && <VisuallyHidden>{t('loading')}</VisuallyHidden>}
      </span>
      {children}
    </>
  );
};

export default Loading;
