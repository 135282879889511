import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '@justgiving/auth-react';
import { infoIcon } from '@justgiving/icons';
import Svg from '@justgiving/svg';
import classNames from 'classnames';

import { AcceptedCurrencyCode, LineItemType, PaymentType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import DirectDebitLogo from '../../../assets/images/directDebitLogo.svg';
import { heading } from '../../../styles/recipes/headings.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import Select from '../../components/Form/Select/Select';
import { FormError } from '../../components/FormError/FormError';
import { Image } from '../../components/Image/Image';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import Modal from '../../components/modal/Modal';
import { CONSTANTS } from '../../config/constants';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLSupportedPaymentTypesForCurrency } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLDirectDebitAddress } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { separateCharacters } from '../../utils/separateCharacters';
import AddressLookUpForm from '../AddressLookUp/AddressLookUpForm';
import { CancelWhenCauseExipres } from './CancelWhenCauseExipres';
import DirectDebitGuarantee from './DirectDebitGuarantee';
import { dayOfMonthOptions, formatSortCode } from './directDebit.utils';
import * as styles from './directDebitForm.css';

const DirectDebitForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useFormContext<AmountPageFormValues>();

  const { data } = useFetchCheckoutQuery();
  const orderType = getGQLOrderType(data);

  const auth = useContext(AuthContext);
  const isGuest = (auth && auth.isGuest()) ?? false;
  const [directDebit, transactionType, donationCurrencyCode] = useWatch<
    AmountPageFormValues,
    ['directDebit', 'transactionType', 'donationCurrencyCode']
  >({
    name: ['directDebit', 'transactionType', 'donationCurrencyCode'],
  });
  const { sortCode } = directDebit ?? {};
  const supportedPaymentTypes = getGQLSupportedPaymentTypesForCurrency(
    data,
    donationCurrencyCode as AcceptedCurrencyCode,
  );
  const directDebitAddress = getGQLDirectDebitAddress(data);
  const sortCodeRef = useRef<string>();

  useEffect(() => {
    sortCodeRef.current = sortCode as string;
  });
  const prevSortCode = sortCodeRef.current;

  const [isGuaranteeModalOpen, setIsGuaranteeModalOpen] = useState(false);
  const openModal = useCallback(() => setIsGuaranteeModalOpen(true), []);
  const closeModal = useCallback(() => setIsGuaranteeModalOpen(false), []);

  const isDirectDebitAvailable = !isGuest && supportedPaymentTypes?.includes(PaymentType.DirectDebit);

  if (transactionType !== TransactionTypes.RECURRING || !isDirectDebitAvailable) return null;

  return (
    <div data-qa="directDebitForm" className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={heading({ level: 5 })}>{t('formTitle', { ns: 'recurring' })}</h2>
        <Image src={DirectDebitLogo} className={styles.headerLogo} />
      </div>

      <Label htmlFor="directDebit.accountHolderName">{t('accountHolderNameLabel', { ns: 'recurring' })}</Label>
      <Input
        name="directDebit.accountHolderName"
        id="directDebit.accountHolderName"
        autoComplete="off"
        placeholder={t('accountHolderName|placeholder', { ns: 'recurring' })}
        registerMethods={{
          setValueAs: value => value?.trim().toUpperCase(),
          required: {
            value: true,
            message: t('validation|accountHolderName|required', { ns: 'recurring' }),
          },
          minLength: { value: 2, message: t('validation|accountHolderName|minError', { ns: 'recurring' }) },
          maxLength: { value: 30, message: t('validation|accountHolderName|maxError', { ns: 'recurring' }) },
          pattern: {
            value: CONSTANTS.BANK_ACCOUNT_HOLDER_NAME,
            message: t('validation|accountHolderName|regexError', { ns: 'recurring' }),
          },
        }}
        required
      />
      <FormError fieldName="directDebit.accountHolderName" />

      <div className={styles.sortCodeAccountNumberWrapper}>
        <div className={styles.sortCodeOrAccountNumber}>
          <Label htmlFor="directDebit.sortCode">{t('sortCodeLabel', { ns: 'recurring' })}</Label>
          <Controller
            name="directDebit.sortCode"
            control={control}
            rules={{
              required: { value: true, message: t('validation|sortCode|required', { ns: 'recurring' }) },
              pattern: {
                value: /^\d{2}-\d{2}-\d{2}$/,
                message: t('validation|sortCode|required', { ns: 'recurring' }),
              },
            }}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                id={name}
                isExternalError={Boolean(errors.directDebit?.accountDetails)}
                inputMode="numeric"
                autoComplete="off"
                value={value}
                placeholder={t('sortCode|placeholder', { ns: 'recurring' })}
                onChange={e => {
                  onChange(formatSortCode(e.target.value, prevSortCode));
                }}
                required
              />
            )}
          />
          <FormError fieldName="directDebit.sortCode" />
        </div>
        <div className={styles.sortCodeOrAccountNumber}>
          <Label htmlFor="directDebit.accountNumber">{t('accountNumberLabel', { ns: 'recurring' })}</Label>
          <Input
            name="directDebit.accountNumber"
            id="directDebit.accountNumber"
            isExternalError={Boolean(errors.directDebit?.accountDetails)}
            inputMode="numeric"
            autoComplete="off"
            registerMethods={{
              required: { value: true, message: t('validation|accountNumber|required', { ns: 'recurring' }) },
              pattern: { value: /^\d{8}$/, message: t('validation|accountNumber|required', { ns: 'recurring' }) },
            }}
            placeholder={t('accountNumber|placeholder', { ns: 'recurring' })}
            required
          />
          <FormError fieldName="directDebit.accountNumber" />
        </div>
      </div>
      <FormError fieldName="directDebit.accountDetails" />
      <AddressLookUpForm formPrefix="directDebit" initialValues={directDebitAddress} />
      <div className={classNames(styles.takenOn)}>
        <span aria-hidden>{t('dayOfMonth|labelBefore', { ns: 'recurring' })}</span>{' '}
        <label htmlFor="directDebitDonationDate">
          <VisuallyHidden>{t('dayOfMonth|accessibleLabel', { ns: 'recurring' })}</VisuallyHidden>
          <Select
            id="directDebitDonationDate"
            name="directDebit.dayOfMonth"
            registerMethods={{ valueAsNumber: true }}
            data-qa="directDebitDayOfMonthSelect"
          >
            {dayOfMonthOptions.map(({ label, value }) => (
              <option key={value} value={value} title={`${label} ${t('dayOfMonth|labelAfter', { ns: 'recurring' })}`}>
                {label}
              </option>
            ))}
          </Select>
        </label>{' '}
        <span aria-hidden>{t('dayOfMonth|labelAfter', { ns: 'recurring' })}</span>
      </div>

      {orderType && ![LineItemType.DonationCharityDirect, LineItemType.DonationCrowdfunding].includes(orderType) && (
        <CancelWhenCauseExipres />
      )}

      <p className={styles.blurb}>{t('blurb', { ns: 'recurring' })}</p>
      <p>
        <ButtonTextLink onClick={openModal} className={sprinkles({ marginTop: 'sizeSpacing04' })}>
          {t('guaranteeLink', { ns: 'recurring' })}
          <Svg markup={infoIcon} className={styles.guaranteeLinkIcon} />
        </ButtonTextLink>
      </p>
      <p>
        <strong>
          {t('serviceUserNumberLabel', { ns: 'recurring' })}{' '}
          <span aria-hidden>{t('serviceUserNumber', { ns: 'recurring' })}</span>
          <VisuallyHidden>{separateCharacters(t('serviceUserNumber', { ns: 'recurring' }))}</VisuallyHidden>
        </strong>
      </p>
      <Modal
        id="modal-direct-debit"
        isOpen={isGuaranteeModalOpen}
        onRequestClose={closeModal}
        contentLabel={t('guarantee|heading', { ns: 'recurring' })}
      >
        <DirectDebitGuarantee />
      </Modal>
    </div>
  );
};

export default DirectDebitForm;
