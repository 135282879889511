import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import { FormGroup } from '../../components/FormGroup/FormGroup';
import {
  RadioButton,
  RadioButtonGroup,
  RadioButtonGroupDescription,
} from '../../components/RadioButtonGroup/RadioButtonGroup';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsExternalLinkServiceOrGivingCheckout } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { formatCurrency } from '../../utils/value';
import { useDonationDescriptions } from '../DonationDescriptions/useDonationDescriptions';
import { useGetSuggestedAmountValues } from './useGetSuggestedAmountValues';

const SuggestedAmounts: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();

  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
  } = useFormContext<AmountPageFormValues>();
  const [donationValue, donationCurrencyCode, transactionType] = useWatch<
    AmountPageFormValues,
    ['donationValue', 'donationCurrencyCode', 'transactionType']
  >({ name: ['donationValue', 'donationCurrencyCode', 'transactionType'] });

  const { selectedIndex, hasDescription, descriptions } = useDonationDescriptions();
  const suggestedAmounts = useGetSuggestedAmountValues();
  const isExternalLinkServiceOrGivingCheckout = getGQLIsExternalLinkServiceOrGivingCheckout(data);

  if (!suggestedAmounts || !isExternalLinkServiceOrGivingCheckout || transactionType === TransactionTypes.CRYPTO)
    return null;

  return (
    <FormGroup
      legendId="suggestedAmounts"
      legendText={t('suggestedAmountLegend', { ns: 'amount' })}
      groupClassName={sprinkles({ marginTop: { mobile: 'sizeSpacing04', desktop: 'sizeSpacing05' } })}
    >
      <RadioButtonGroup>
        {suggestedAmounts.map((suggestedAmount, index) => {
          const label = formatCurrency(donationCurrencyCode, suggestedAmount, false);
          const checked = donationValue === suggestedAmount.toString();
          return (
            <RadioButton
              key={`donationSuggestedAmounts-${index}-${donationCurrencyCode}`}
              labelDataQa="donationSuggestedAmounts-label"
              checked={checked}
              name="donationSuggestedAmounts"
              id={`donationSuggestedAmounts-${index}`}
              onChange={e => {
                setValue('donationValue', e.target.value, { shouldValidate: Boolean(errors.donationValue) });
              }}
              hasDescription={hasDescription && index === selectedIndex}
              value={suggestedAmount}
              ariaDescribedBy={hasDescription ? descriptions[index].id : undefined}
            >
              {label}
            </RadioButton>
          );
        })}
        {hasDescription && <RadioButtonGroupDescription descriptions={descriptions} />}
      </RadioButtonGroup>
    </FormGroup>
  );
};

export default SuggestedAmounts;
