import APP_SETTINGS from '../../config';
import fetchWithTimeout from './fetchWithTimeout';
import { APP_VERSION } from './logError';
import toSerializable from './toSerializable';

export const logInfo = async (data: object) => {
  try {
    if (APP_SETTINGS.env === 'local') return;

    const serializableData = await toSerializable(data);
    await fetchWithTimeout(`${APP_SETTINGS.LOGGING_API_URL}/info`, {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      credentials: 'omit',
      body: JSON.stringify({
        ...serializableData,
        app: {
          ...serializableData?.app,
          name: 'GG.FrontEnd.App.DonationCollection',
          version: APP_VERSION,
          location: window?.location,
        },
      }),
    });
  } catch (error) {
    // do nothing
  }
};
