import React from 'react';
import { FunctionComponent, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PageName } from '../../config/pages';
import * as styles from './AccordionPages.css';
import AmexPWPPageSection from './sections/AmexPWPPageSection';
import AmountPageSection from './sections/AmountPageSection';
import GiftAidPageSection from './sections/GiftAidPageSection';
import MessagePageSection from './sections/MessagePageSection';
import SelectPaymentPageSection from './sections/SelectPaymentPageSection';
import SummaryPageSection from './sections/SummaryPageSection';
import useAccordionExperiment from './useAccordionExperiment';

type AccordionEmptyFormContextProps = {
  children: ReactNode;
};

const AccordionEmptyFormContext: FunctionComponent<AccordionEmptyFormContextProps> = ({ children }) => {
  const formMethods = useForm();
  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

type AccordionPagesListProps = {
  addEmptyFormContext?: boolean;
  children: ReactNode;
  collapse?: boolean;
  pageLoading?: PageName | null;
};

const AccordionPagesList: FunctionComponent<AccordionPagesListProps> = ({ children, collapse, pageLoading }) => {
  return (
    <ol className={styles.wrapper}>
      <AmountPageSection currentPageCollapsed={collapse} isLoading={pageLoading === 'AmountPage'}>
        {children}
      </AmountPageSection>
      <MessagePageSection currentPageCollapsed={collapse} isLoading={pageLoading === 'MessagePage'}>
        {children}
      </MessagePageSection>
      <SelectPaymentPageSection currentPageCollapsed={collapse} isLoading={pageLoading === 'SelectPaymentPage'}>
        {children}
      </SelectPaymentPageSection>
      <AmexPWPPageSection
        currentPageCollapsed={collapse}
        isLoading={pageLoading === 'AmexPayWithPointsYourPaymentPage'}
      >
        {children}
      </AmexPWPPageSection>
      <GiftAidPageSection
        currentPageCollapsed={collapse}
        isLoading={pageLoading === 'GiftAidDeclarationPage' || pageLoading === 'GiftAidDetailsPage'}
      >
        {children}
      </GiftAidPageSection>
      <SummaryPageSection currentPageCollapsed={collapse} isLoading={pageLoading === 'SummaryPage'}>
        {children}
      </SummaryPageSection>
    </ol>
  );
};

type AccordionPagesWrapperProps = {
  addEmptyFormContext?: boolean;
  children: ReactNode;
  collapse?: boolean;
  pageLoading?: PageName | null;
};

const AccordionPages: FunctionComponent<AccordionPagesWrapperProps> = ({
  children,
  collapse,
  pageLoading,
  addEmptyFormContext,
}) => {
  const { accordionEnabled } = useAccordionExperiment();

  if (!accordionEnabled) {
    return <>{children}</>;
  }

  if (addEmptyFormContext) {
    return (
      <AccordionEmptyFormContext>
        <AccordionPagesList collapse={collapse} pageLoading={pageLoading}>
          {children}
        </AccordionPagesList>
      </AccordionEmptyFormContext>
    );
  }

  return (
    <AccordionPagesList collapse={collapse} pageLoading={pageLoading}>
      {children}
    </AccordionPagesList>
  );
};

export default AccordionPages;
