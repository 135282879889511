import APP_SETTINGS from '../../config';

type ExtendedError = Error & { response?: any; isInformational?: boolean };

const fetchWithTimeout = async (url: string, requestOptions: RequestInit, timeoutInMs?: number): Promise<Response> => {
  const executeFetch = () => fetch(url, requestOptions);
  const timeout = typeof timeoutInMs === 'undefined' ? APP_SETTINGS.REQUEST_TIMEOUT_DEADLINE : timeoutInMs;

  if (timeout) {
    let timer: ReturnType<typeof setTimeout>;

    return Promise.race([
      fetch(url, requestOptions),
      new Promise<Promise<Response>>((_resolve, reject) => {
        timer = setTimeout(() => {
          const timerError: ExtendedError = new Error('Request timeout');
          timerError.response = new Response(JSON.stringify({}), {
            status: 408,
            statusText: 'Request Timeout',
          });
          reject(timerError);
        }, timeout);
      }),
    ]).then(res => {
      clearTimeout(timer);
      return res;
    });
  }

  return executeFetch();
};

export default fetchWithTimeout;
