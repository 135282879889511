import React, { FunctionComponent, ReactNode } from 'react';

import * as styles from './AccordionPagePreviewEmerge.css';

type AccordionPageNewStepProps = {
  children: ReactNode;
};

export const AccordionPagePreviewEmerge: FunctionComponent<AccordionPageNewStepProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
