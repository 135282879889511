import React, { FunctionComponent } from 'react';

import { useCharityBranding } from '../hooks/useCharityBranding';
import { useGooglePay } from '../hooks/useGooglePay';
import { useWarnUserBeforeNavigation } from '../hooks/useWarnUserBeforeNavigation';
import Layout from '../modules/Layout/Layout';
import Routes from '../router/Routes';

export const App: FunctionComponent = () => {
  useCharityBranding();
  useGooglePay();
  useWarnUserBeforeNavigation();

  return (
    <>
      <Layout>
        <Routes />
      </Layout>
    </>
  );
};
export default App;
