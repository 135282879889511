import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import JustGivingRobot from '../../../assets/images/JustGivingRobot.png';
import { sprinkles } from '../../../styles/sprinkles.css';
import { Image } from '../../components/Image/Image';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import { useFetchCryptocurrenciesQuery } from '../../redux/cryptocurrenciesApiSlice/cryptocurrenciesApiSlice';
import { CryptoAmount, CryptoAmountLoader } from './CryptoAmount';
import CryptoInformationBox from './CryptoInformationBox';
import * as styles from './Cryptocurrency.css';
import { CryptocurrencySelectLoader, CryptocurrencySelect } from './CryptocurrencySelect';
import { calculateCryptoAmount } from './cryptoUtils';

const DEFAULT_CRYPTO_SHORTCODE = 'BTC';
const DEFAULT_FIAT_AMOUNT = 10000;

export const CryptoWrapper: FunctionComponent = () => {
  const transactionType = useWatch<AmountPageFormValues, 'transactionType'>({ name: 'transactionType' });
  if (transactionType !== TransactionTypes.CRYPTO) return null;
  return <Crypto />;
};

const Crypto: FunctionComponent = () => {
  const { register } = useFormContext<AmountPageFormValues>();
  return (
    <>
      <input type="hidden" {...register('donationValue')} />
      <input type="hidden" {...register('donationCurrencyCode')} />
      <input type="hidden" {...register('crypto.currencyShortName')} />
      <input type="hidden" {...register('crypto.currencyFullName')} />
      <input type="hidden" {...register('crypto.currencyLogoUrl')} />
      <input type="hidden" {...register('crypto.estimatedExchangeRate')} />
      <input type="hidden" {...register('crypto.fiatAmount')} />
      <input type="hidden" {...register('crypto.cryptoAmount')} />
      <CryptoInformationBox />
      <CryptocurrencyLiveRegion />
      <CryptoControls />
    </>
  );
};

type CryptocurrencyErrorProps = {
  refetch: () => void;
};
const CryptocurrencyError: FunctionComponent<CryptocurrencyErrorProps> = ({ refetch }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.errorWrapper}>
      <Image src={JustGivingRobot} width="124" height="124" alt="" />
      <p className={styles.errorMessage} aria-hidden>
        {t('recoverable', { ns: 'alert' })}
      </p>
      <ButtonTextLink onClick={refetch}>{t('tryAgain')}</ButtonTextLink>
    </div>
  );
};

const CryptocurrencyLiveRegion: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isLoading, isError, isFetching } = useFetchCryptocurrenciesQuery();

  if (isLoading) return <VisuallyHidden aria-live="polite">{t('stateLoading', { ns: 'crypto' })}</VisuallyHidden>;
  if (isError) return <VisuallyHidden aria-live="polite">{t('stateError', { ns: 'crypto' })}</VisuallyHidden>;
  if (isFetching) return <VisuallyHidden aria-live="polite">{t('stateReloading', { ns: 'crypto' })}</VisuallyHidden>;
  return <VisuallyHidden aria-live="polite"></VisuallyHidden>;
};

const CryptoControls: FunctionComponent = () => {
  const {
    data: cryptocurrencyData,
    isLoading,
    isError,
    isFetching,
    refetch: refetchCryptocurrencyData,
  } = useFetchCryptocurrenciesQuery();
  const [hasRefetched, setHasRefetched] = useState<boolean>(false);

  const defaultValue = cryptocurrencyData?.find(crypto => crypto.shortCode === DEFAULT_CRYPTO_SHORTCODE);

  const { setValue, getValues } = useFormContext<AmountPageFormValues>();
  useEffect(() => {
    const donationValue = getValues('donationValue');
    if (defaultValue) {
      setValue('crypto.currencyShortName', defaultValue.shortCode);
      setValue('crypto.currencyFullName', defaultValue.name);
      setValue('crypto.currencyLogoUrl', defaultValue.logoUrl);
      setValue('crypto.estimatedExchangeRate', defaultValue.exchangeRate.toString());
      setValue('crypto.fiatAmount', donationValue || DEFAULT_FIAT_AMOUNT.toString());
      setValue(
        'crypto.cryptoAmount',
        calculateCryptoAmount(donationValue || DEFAULT_FIAT_AMOUNT.toString(), defaultValue.exchangeRate),
      );
      if (!donationValue) setValue('donationValue', DEFAULT_FIAT_AMOUNT.toString());
    }
  }, [defaultValue, setValue, getValues]);

  const refetch = () => {
    refetchCryptocurrencyData();
    setHasRefetched(true);
  };

  if (isLoading || isFetching) {
    return (
      <>
        <CryptocurrencySelectLoader />
        <CryptoAmountLoader />
        <CryptoExchangeRateNote />
      </>
    );
  }

  if (isError || !cryptocurrencyData) {
    return <CryptocurrencyError refetch={refetch} />;
  }

  return (
    <div className={styles.wrapper}>
      <CryptocurrencySelect cryptocurrencyData={cryptocurrencyData} focusOnMount={hasRefetched} />
      <CryptoAmount cryptocurrencyData={cryptocurrencyData} />
      <CryptoExchangeRateNote />
    </div>
  );
};

const CryptoExchangeRateNote: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <p className={sprinkles({ marginTop: 'sizeSpacing04', color: 'light', textSize: 's' })}>
      {t('exchangeRateNote', { ns: 'crypto' })}
    </p>
  );
};
