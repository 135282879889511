import { useEffect, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { useTipJarModeConfig } from '../../hooks/useTipJarModeConfig';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLTipJarValues } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getTipLastSelectedAmountValue,
  getTipLastSelectedPercentValue,
  getTipSelectionValue,
  getTipUserSelectionMade,
} from '../../redux/tip/tip.selectors';
import { setTipDefaultValue, setTipValues } from '../../redux/tip/tip.slice';

export const TipJarAmountWatcher = () => {
  const { data } = useFetchCheckoutQuery();
  const dispatch = useDispatch();

  // TODO We require totalAmountToPay here even though we do not use it to force a render, look at improving this
  const [donationValue, transactionType] = useWatch<
    AmountPageFormValues,
    ['donationValue', 'transactionType', 'totalAmountToPay']
  >({
    name: ['donationValue', 'transactionType', 'totalAmountToPay'],
  });
  const { tipJarValuesOverride } = useTipJarModeConfig();
  const tipSelectionValue = useSelector(getTipSelectionValue);
  const tipUserSelectionMade = useSelector(getTipUserSelectionMade);
  const lastSelectedAmountValue = useSelector(getTipLastSelectedAmountValue);
  const lastSelectedPercentValue = useSelector(getTipLastSelectedPercentValue);
  const isCryptoTransactionTypeSelected = transactionType === TransactionTypes.CRYPTO;

  const tipJarValues = getGQLTipJarValues(donationValue, isCryptoTransactionTypeSelected, tipJarValuesOverride)(data);

  const donationAmount = Number(donationValue);

  const mounted = useRef(false);

  useEffect(() => {
    dispatch(
      setTipValues({
        tipValues: tipJarValues?.values,
        valueType: tipJarValues?.valueType,
        defaultValue: tipJarValues?.defaultValue,
      }),
    );
    // Side effect - On donation amount change reset the tipjar to default if requirements are met
    if (mounted.current === false) return;

    const lastSelectedAmount =
      tipJarValues?.valueType === 'Amount' ? lastSelectedAmountValue : lastSelectedPercentValue;

    // Current selected value is not supported so set default
    if (!tipJarValues?.values.includes(Number(tipSelectionValue))) {
      dispatch(setTipDefaultValue({ value: lastSelectedAmount ?? tipJarValues?.defaultValue }));
      return;
    }

    // The user has not made a preference so allow default to change
    if (!tipUserSelectionMade) {
      dispatch(setTipDefaultValue({ value: lastSelectedAmount ?? tipJarValues?.defaultValue }));
      return;
    }
  }, [donationAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    mounted.current = true;
  }, []);

  return null;
};
