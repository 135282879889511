import React, { FunctionComponent } from 'react';

import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLPageOwnerOrCharityName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import HeaderImage from '../Header/HeaderImage';
import SourcePagePreviewCover from './SourcePagePreviewCover';
import * as styles from './SourcePagePreviewHeader.css';

const SourcePagePreviewHeader: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { accordionEnabled } = useAccordionExperiment();

  const pageOwnerOrCharityName = getGQLPageOwnerOrCharityName(data);

  return (
    <>
      <SourcePagePreviewCover />
      <div className={accordionEnabled ? styles.accordionHeaderInfo : styles.headerInfo}>
        <HeaderImage className={styles.causeImage} size={accordionEnabled ? 'mediumAccordion' : 'large'} />
        <div className={styles.name}>{pageOwnerOrCharityName}</div>
      </div>
    </>
  );
};

export default SourcePagePreviewHeader;
