import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, retry } from '@reduxjs/toolkit/query/react';

import { httpErrorMessage } from '../../config/httpErrorMessage';
import { getLoginURL } from '../../utils/getLoginURL';
import { graphqlRequestBaseQuery } from '../../utils/graphqlRequestBaseQuery';
import { updateErrorType } from '../session/session.actions';
import { getIsEmbedded } from '../session/session.selectors';

const withRetry = retry(
  (args, api, extraOptions) => graphqlRequestBaseQuery(args, api, { ...extraOptions, useCheckoutId: true }),
  {
    retryCondition: (error: BaseQueryError<any>, _args, extraArgs) => {
      const state = extraArgs.baseQueryApi.getState() as State;
      const isEmbedded = getIsEmbedded(state);
      const loginURL = getLoginURL(isEmbedded);
      if (error.status === 401 && error.message === httpErrorMessage.UNAUTHORIZED_RECOVERABLE) {
        if (extraArgs.attempt <= 1) {
          return true;
        }
        window.location.assign(loginURL);
      }
      if (error.status === 401 && error.message === httpErrorMessage.UNAUTHORIZED_NOT_RECOVERABLE) {
        extraArgs.baseQueryApi.dispatch(updateErrorType('GuestAuthFailure'));
      }
      return false;
    },
  },
);

export const checkoutApiSlice = createApi({
  reducerPath: 'checkoutApi',
  baseQuery: withRetry,
  tagTypes: ['Checkout'],
  endpoints: () => ({}),
});
