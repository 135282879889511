import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import Select from '../../components/Form/Select/Select';

type Props = { className?: string };

const PaymentCardExpiryMonthDropdown: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation();
  const monthsSelectList: JSX.Element[] = useMemo(
    () =>
      Array(12)
        .fill(null)
        .map((_, i) => {
          const count = i + 1;
          const month = count < 10 ? `0${count}` : String(count);
          return (
            <option key={`month-${month}`} value={month}>
              {month}
            </option>
          );
        }),
    [],
  );

  return (
    <Select
      name="cardExpiryMonth"
      className={classNames(className, sprinkles({ flex: 1 }))}
      aria-label={t('paymentCard|expiryMonth|label', { ns: 'payment' })}
      data-qa="expiryMonth"
      autoComplete="off"
      registerMethods={{
        required: { value: true, message: t('paymentCard|expiryMonth|error|required', { ns: 'validation' }) },
      }}
      defaultValue=""
      required
    >
      <option value="">{t('paymentCard|expiryMonth|placeholder', { ns: 'payment' })}</option>
      {monthsSelectList}
    </Select>
  );
};

export default PaymentCardExpiryMonthDropdown;
