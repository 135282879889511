export const RECURRENCE_DAYS_OF_MONTH = [
  {
    label: '1st',
    value: '1',
  },
  {
    label: '15th',
    value: '15',
  },
  {
    label: '28th',
    value: '28',
  },
] as const;
