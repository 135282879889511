import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { MarketCode } from '../../../../types/__generated-graphQL__';
import JustGivingPurple from '../../../assets/images/JustGivingPurple.svg';
import JustGivingfromBlackbaud from '../../../assets/images/JustGivingfromBlackbaud.svg';
import IconPadlock from '../../../assets/images/icon-padlock.svg';
import { Image } from '../../components/Image/Image';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLMarketCode } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import * as styles from './SecurelyProvidedBy.css';

export const SecurelyProvidedBy: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();
  const marketCode = getGQLMarketCode(data);
  const isGb = marketCode === MarketCode.Gb;

  return (
    <>
      <p className={styles.heading}>
        <Image className={styles.iconPadlock} src={IconPadlock} />
        {t('sercurelyPoweredBy')}
        <VisuallyHidden> {isGb ? 'JustGiving' : t('justgivingFromBlackbaud')}</VisuallyHidden>
      </p>
      <Image
        className={isGb ? styles.imageJG : styles.imageJGfromBB}
        src={isGb ? JustGivingPurple : JustGivingfromBlackbaud}
        height={isGb ? 28 : 20}
        width={isGb ? 168 : 214}
      />
    </>
  );
};
