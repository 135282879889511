import { VoluntaryContributionConfigValueType } from '../../../../types/__generated-graphQL__';
import { TipJarConfigSet } from '../../../../types/tipjarConfig';
import { lowTipAboveUpperLimitInPennies, lowTipDefault } from './tipjarConfigDefaults';

export const tipJarConfigDefault16: TipJarConfigSet = [
  lowTipDefault,
  {
    from: lowTipAboveUpperLimitInPennies,
    to: Number.MAX_VALUE,
    valueType: VoluntaryContributionConfigValueType.Percentage,
    values: [10, 12.5, 16, 17.5, 20],
    defaultValue: 16,
  },
];
