import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LineItemType, MarketCode, TipScheme } from '../../../../types/__generated-graphQL__';
import { sprinkles } from '../../../styles/sprinkles.css';
import { VoluntaryContributionTooltip } from '../../components/VoluntaryContributionTooltip/VoluntaryContributionTooltip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityName, getGQLPageOwnerName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLCauseTipScheme,
  getGQLIsBBGF,
  getGQLMarketCode,
  getGQLOrderType,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import * as styles from './TipJarMessage.css';

type TipJarMessageWithTitleProps = {
  title: string;
  body: string;
  showVcTooltip?: boolean;
};

export const TipJarMessageWithTitle: FunctionComponent<TipJarMessageWithTitleProps> = ({
  title,
  body,
  showVcTooltip = false,
}) => {
  return (
    <>
      <h2 className={styles.heading}>{title}</h2>
      <p className={styles.message}>
        {body} {showVcTooltip && <VoluntaryContributionTooltip tooltipButtonClassName={styles.tooltip} showText />}
      </p>
    </>
  );
};

const TipJarMessageContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data } = useFetchCheckoutQuery();
  const isBBGF = getGQLIsBBGF(data);
  const marketCode = getGQLMarketCode(data);
  const causeTipScheme = getGQLCauseTipScheme(data);
  const orderType = getGQLOrderType(data);
  const charityName = getGQLCharityName(data);
  const pageOwnerName = getGQLPageOwnerName(data);

  if (orderType === LineItemType.DonationCrowdfunding) {
    return <>{t('amount|crowdfunding|JGChargingNoFee', { causeName: pageOwnerName, ns: 'tipJar' })}</>;
  }

  if (causeTipScheme === TipScheme.TipJar2_3) {
    return <>{t('amount|2_3|JGChargingNoFeeTip', { ns: 'tipJar' })}</>;
  }

  // Tipjar 2.1 logic
  if (isBBGF) {
    return (
      <TipJarMessageWithTitle
        title={t('amount|2_1|US|JGChargingNoFee|heading', { ns: 'tipJar' })}
        body={t('amount|2_1|BBGF|body', { ns: 'tipJar' })}
        showVcTooltip
      />
    );
  }

  if (marketCode === MarketCode.Us) {
    return (
      <TipJarMessageWithTitle
        title={t('amount|2_1|US|JGChargingNoFee|heading', { ns: 'tipJar' })}
        body={t('amount|2_1|US|JGChargingNoFee|body', { causeName: charityName, ns: 'tipJar' })}
      />
    );
  }

  return (
    <TipJarMessageWithTitle
      title={t('amount|2_1|JGChargingNoFee|heading', { ns: 'tipJar' })}
      body={t('amount|2_1|JGChargingNoFee|body', { causeName: charityName, ns: 'tipJar' })}
    />
  );
};

export const TipJarMessage: FunctionComponent = () => (
  <div className={sprinkles({ display: 'flex', gap: 'sizeSpacing04' })} data-qa="tipjar-message">
    <div className={styles.icon}></div>
    <div>
      <TipJarMessageContent />
    </div>
  </div>
);
