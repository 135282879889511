import React, { FunctionComponent, memo } from 'react';

import classNames from 'classnames';

import successGroup from '../../../assets/images/successGroup.png';
import { Image } from '../../components/Image/Image';
import * as styles from './DonateStatusImage.css';

type DonationStatusImageProps = {
  status: DonationStatus;
};

const DonationStatusImage: FunctionComponent<DonationStatusImageProps> = memo(({ status }) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [`${styles.success}`]: ['succeeded', 'directdebit_succeeded', 'timeout'].includes(status),
        [`${styles.invalid}`]: status === 'invalid',
      })}
    >
      <div className={styles.clock} aria-hidden="true">
        <div className={styles.linesContainer}>
          <div className={styles.minutes} />
          <div className={styles.seconds} />
        </div>
      </div>
      <Image src={successGroup} className={styles.image} />
    </div>
  );
});

export default DonationStatusImage;
