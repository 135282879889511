import { Checkout } from '../../../types/__generated-graphQL__';
import { getGQLSourceUrl } from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import stripCauseId from '../utils/stripCauseId';

export const getTipJarDefaultValueAdjustment = (causeId?: string): number | null => {
  const strippedCauseId = stripCauseId(causeId);
  switch (strippedCauseId) {
    // When adding a cause to this hack, remember to add a backlog ticket to remove it again (and put the link here)

    // case 'xxxxx':
    //   return 5;

    default:
      return null;
  }
};

export const HACK_getGQLIsViralPageBySlug = (data?: Checkout): boolean => {
  const sourceUrl = getGQLSourceUrl(data);

  switch (sourceUrl) {
    // When adding a source url to this hack, remember to add a backlog ticket to remove it again (and put the link here)

    // case 'xxxxx': {
    //   return true;
    // }

    default:
      return false;
  }
};

export const HACK_getForceDescriptionsOnAllCurrencies = (causeId?: string): boolean => {
  const strippedCauseId = stripCauseId(causeId);
  switch (strippedCauseId) {
    // When adding a cause to this hack, remember to add a backlog ticket to remove it again (and put the link here)

    // case 'xxxxx': {
    //   return true;
    // }

    default:
      return false;
  }
};

export const HACK_isAwardsPage = (sourceUrl?: string): boolean => {
  switch (sourceUrl) {
    // Current plan is to enable these on 1st July
    case 'https://www.justgiving.com/page/sally-smart-1686826073448':
    case 'https://www.justgiving.com/fundraising/dontblowourfutures':
    case 'https://www.justgiving.com/page/maya-tohid-1693755634402':
    case 'https://www.justgiving.com/fundraising/freddie-Xavi':
    case 'https://www.justgiving.com/page/jakeandlogan-1708961503981':
    case 'https://www.justgiving.com/page/williamsbig4':
    case 'https://www.justgiving.com/page/jonny-davies-1692194753702':
    case 'https://www.justgiving.com/page/freya-morgan-1669119657286':
    case 'https://www.justgiving.com/page/angelmums':
    case 'https://www.justgiving.com/page/wonkyheartchallenge':
    case 'https://www.justgiving.com/page/corinnewatsonmnd':
    case 'https://www.justgiving.com/campaign/skyathlon': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const HACK_isAwardsTeam = (teamName?: string): boolean => {
  switch (teamName) {
    // Current plan is to enable these on 1st July
    case 'team/doitforbrodie':
    case 'team/lloydmartinlondonmarathon':
    case 'team/claires-great-british-walk':
    case 'team/jonathanbailey':
    case 'team/cure4carmela':
    case 'team/samperkins':
    case 'team/fatimawhitbread':
    case 'team/jaynebressington':
    case 'team/sippowers': {
      return true;
    }
    default: {
      return false;
    }
  }
};

// TODO: Remove this hack after the JustGiving Awards on 18/09/2024
export const HACK_isGoCardlessStaffPage = (pageSlug?: string): boolean => {
  return [
    'page/gc-pride-month-challenge',
    'page/gc-access-fairshot',
    'page/gocardless-beam-erg-donate-here',
    'page/go-green-whale-dolphin-conservation',
  ].includes(pageSlug ?? '');
};
