import { httpErrorMessage } from '../config/httpErrorMessage';
import { logError } from './logError';

type ExtendedError = Error & { isInformational: boolean };

const filterAndLogAppError = (err: ExtendedError) => {
  if (
    err?.message === httpErrorMessage.UNAUTHORIZED_RECOVERABLE ||
    err?.message === httpErrorMessage.UNAUTHORIZED_NOT_RECOVERABLE ||
    err?.message === httpErrorMessage.GRAPHQL_403_OR_404
  ) {
    return;
  }
  if (!err || !err.message || err.isInformational) {
    return logError(err);
  }
  switch (err.message) {
    case 'Failed to get initial state from api':
    case 'Failed to access checkout session ID':
    case 'No valid uri passed':
    case 'Failed to associate authenticated donor with the donation':
    case 'API failed to check the authorisation':
    case 'Error: Not Found':
      return logError(err);
    default: {
      logError(err);
      throw err;
    }
  }
};

export default filterAndLogAppError;
