import { Auth } from '@justgiving/auth';

import { Checkout } from '../../../types/__generated-graphQL__';
import APP_SETTINGS from '../../config';
import {
  getGQLCheckoutMode,
  getGQLReferrer,
  getGQLTrackingId,
} from '../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLCauseId,
  getGQLCauseTipScheme,
  getGQLMarketCode,
  getGQLOrderType,
} from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getDeviceTypeFromUserAgent } from './getDeviceTypeFromUserAgent';
import stripCauseId from './stripCauseId';
import { mapTipSchemeToLinkServiceFormat } from './utilityMapperFunctions';

let heapHasBeenCleared = false;

export const addHeapEventPropertiesWhenReady = async (heapData: Record<string, any>) => {
  const start = Date.now();
  const timeout = APP_SETTINGS.HEAP_TIMEOUT;

  return new Promise(function check(resolve, reject) {
    if (window.heap?.addEventProperties) {
      if (!heapHasBeenCleared) {
        heapHasBeenCleared = true;
        window.heap?.clearEventProperties?.();
      }
      window.heap?.addEventProperties?.(heapData);
      return resolve(window.heap);
    }
    if (Date.now() - start >= timeout) {
      // On timout heap will just be disabled
      return resolve(undefined);
    }
    setTimeout(() => check(resolve, reject), 30);
  });
};

export const addBaseHeapProperties = (data: Checkout, auth?: Auth, extraData?: Record<string, any>) => {
  const causeId = stripCauseId(getGQLCauseId(data));
  const orderType = getGQLOrderType(data);
  const marketCode = getGQLMarketCode(data);
  const causeTipScheme = getGQLCauseTipScheme(data);
  const checkoutMode = getGQLCheckoutMode(data);
  const trackingId = getGQLTrackingId(data);
  const referrer = getGQLReferrer(data);

  const deviceType = getDeviceTypeFromUserAgent();

  const heapData = {
    causeId,
    causeType: orderType,
    marketCode,
    isUserLoggedIn: auth?.isUserLoggedIn() ?? false,
    isGuest: auth?.isGuest() ?? false,
    tipScheme: mapTipSchemeToLinkServiceFormat(causeTipScheme),
    deviceType,
    trackingId,
    referrer,
    embedCheckout: checkoutMode === 'Headless',
    ...extraData,
  };

  if (!window.Cookiebot?.consent?.marketing) return;

  if (!document.getElementById('heapScript')) {
    window.heap?.load?.(APP_SETTINGS.HEAP_API_KEY, { disableTextCapture: true });
  }
  addHeapEventPropertiesWhenReady(heapData);
};
