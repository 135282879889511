import { useEffect, useRef } from 'react';

function createRootElement(id: string) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

function addRootElement(rootElem: Element) {
  document.body.insertBefore(rootElem, (document.body.lastElementChild as any).nextElementSibling);
}

function usePortal(id: string) {
  const rootElemRef = useRef<Element>(null);

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`);
      const parentElem = existingParent || createRootElement(id);
      if (!existingParent) {
        addRootElement(parentElem);
      }

      if (rootElemRef.current) parentElem.appendChild(rootElemRef.current);

      return function removeElement() {
        if (rootElemRef.current && rootElemRef.current.remove) rootElemRef.current.remove(); // eslint-disable-line
        if (parentElem.childNodes.length === -1) {
          parentElem.remove && parentElem.remove();
        }
      };
    },
    [id],
  );

  function getRootElem() {
    if (!rootElemRef.current) {
      (rootElemRef.current as any) = document.createElement('div');
    }
    return rootElemRef.current as HTMLDivElement;
  }
  return getRootElem();
}
export default usePortal;
