import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityName, getGQLPageOwnerName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLOrderType, getGQLPage } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import * as styles from './SourcePagePreviewTitles.css';

type SubHeadingProps = {
  subtitle?: string | null;
  summary?: string | null;
};

const SubHeading: FunctionComponent<SubHeadingProps> = ({ subtitle, summary }) => {
  if (subtitle) return <p className={styles.subtitle}>{subtitle}</p>;
  if (summary) return <p className={styles.subtitle}>{summary}</p>;
  return null;
};

const SourcePagePreviewTitles: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data } = useFetchCheckoutQuery();
  const page = getGQLPage(data);
  const pageOwnerName = getGQLPageOwnerName(data);
  const charityName = getGQLCharityName(data);
  const orderType = getGQLOrderType(data);

  const { accordionEnabled } = useAccordionExperiment();

  const { subtitle, summary } = page!;

  // Note: "unclaimed" pages for Digital Services projects have the fundraiser name "- -", which we never want to be visible in the checkout
  const sanitisedPageOwnerName = pageOwnerName === '- -' ? undefined : pageOwnerName;

  const fundraiserSubtitle =
    sanitisedPageOwnerName && charityName
      ? t('fundraiserSubtitle', { pageOwnerName: sanitisedPageOwnerName, charityName, ns: 'page' })
      : undefined;

  let finalSubtype = null;
  switch (orderType) {
    case LineItemType.DonationCharityFrp:
    case LineItemType.DonationCampaignFrp:
      finalSubtype = fundraiserSubtitle;
      break;
    case LineItemType.DonationCrowdfunding:
      finalSubtype = subtitle;
      break;
    //  Note: campaign direct deliberately left out as this should be blank. No Page data for charity direct
  }

  return (
    <div className={accordionEnabled ? styles.accordionTitles : styles.titles}>
      <h2 className={styles.page_heading}>{page?.title}</h2>
      <SubHeading subtitle={finalSubtype} summary={summary} />
    </div>
  );
};
export default SourcePagePreviewTitles;
