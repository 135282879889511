import { Checkout, PaymentType } from '../../../../../types/__generated-graphQL__';
import { isCrypto, isDirectDebit, isPaymentCard } from '../../../utils/typeGuards';

const _getGQLPayment = (data?: Checkout) => data?.payment;

export const getGQLSelectedPaymentType = (data?: Checkout) => _getGQLPayment(data)?.type;

export const getGQLIsWalletPaymentTypeSelected = (data?: Checkout) => {
  const selectedPaymentType = getGQLSelectedPaymentType(data);

  return (
    selectedPaymentType &&
    [PaymentType.BraintreeApplePay, PaymentType.BraintreeGooglePay, PaymentType.BraintreePayPal].includes(
      selectedPaymentType,
    )
  );
};

export const getGQLPaymentCardDetails = (data?: Checkout) => {
  const paymentDetails = _getGQLPayment(data)?.details;
  if (isPaymentCard(paymentDetails)) {
    return paymentDetails;
  }
  return;
};

export const getGQLDirectDebitDetails = (data?: Checkout) => {
  const paymentDetails = _getGQLPayment(data)?.details;
  if (isDirectDebit(paymentDetails)) {
    return { ...paymentDetails, cancelWhenCauseExpires: paymentDetails.cancelWhenCauseExpires ? 'yes' : 'no' };
  }
  return;
};

export const getGQLCryptoDetails = (data?: Checkout) => {
  const paymentDetails = _getGQLPayment(data)?.details;
  if (isCrypto(paymentDetails)) {
    return paymentDetails;
  }
  return;
};

export const getGQLDirectDebitMandateReference = (data?: Checkout) => {
  return getGQLDirectDebitDetails(data)?.mandateReference;
};

export const getGQLDirectDebitAddress = (data?: Checkout) => {
  return getGQLDirectDebitDetails(data)?.address;
};

export const getGQLPaymentCardDetailsPoints = (data?: Checkout) => getGQLPaymentCardDetails(data)?.points;

export const getGQLPaymentCardDetailsCardType = (data?: Checkout) => getGQLPaymentCardDetails(data)?.cardType;

export const getGQLIs3DSRequired = (data?: Checkout) => getGQLPaymentCardDetails(data)?.requires3DS;

export const getGQLIsDirectDebitSelected = (data?: Checkout) => {
  const paymentDetails = _getGQLPayment(data)?.details;
  return isDirectDebit(paymentDetails);
};
