import React, { FunctionComponent, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { AcceptedCurrencyCode, PaymentType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import Select from '../../components/Form/Select/Select';
import { FormError } from '../../components/FormError/FormError';
import { CONSTANTS } from '../../config/constants';
import { useMinMaxDonationAmounts } from '../../hooks/useMinMaxDonationAmounts';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLSupportedCurrencyCodeList,
  getGQLSupportedPaymentTypesForCurrency,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getCurrencyFlag, formatCurrencySymbol } from '../../utils/currency';
import * as styles from './DonationValue.css';

const DIRECT_DEBIT_MAXIMUM_DONATION_AMOUNT_IN_POUNDS = 15000;

export const DonationValue: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext<AmountPageFormValues>();
  const [donationCurrencyCode, transactionType] = useWatch<
    AmountPageFormValues,
    ['donationCurrencyCode', 'transactionType']
  >({
    name: ['donationCurrencyCode', 'transactionType'],
  });
  const supportedPaymentTypes = getGQLSupportedPaymentTypesForCurrency(data, donationCurrencyCode);

  const supportedCurrencyCodeList = getGQLSupportedCurrencyCodeList(data);
  const currencySelectList = useMemo(() => {
    return supportedCurrencyCodeList.map(currency => (
      <option key={`currency-${currency}`} value={currency}>
        {getCurrencyFlag(currency)} {currency} - {t(`currency|${currency}`)}
      </option>
    ));
  }, [t, supportedCurrencyCodeList]);

  const isDirectDebitSelected =
    transactionType === TransactionTypes.RECURRING && supportedPaymentTypes?.includes(PaymentType.DirectDebit);

  const { min, max: nonDirectDebitMax } = useMinMaxDonationAmounts();
  const max = isDirectDebitSelected ? DIRECT_DEBIT_MAXIMUM_DONATION_AMOUNT_IN_POUNDS : nonDirectDebitMax;
  const isSingleCurrency = currencySelectList.length === 1;

  if (transactionType === TransactionTypes.CRYPTO) return null;

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.group, {
          [styles.isError]: errors.donationValue,
        })}
      >
        <CurrencySelector isSingleCurrency={isSingleCurrency} currencySelectList={currencySelectList} />
        <div className={styles.amount}>
          <input
            id="donationValue"
            type="text"
            inputMode="decimal"
            pattern="[0-9]*"
            autoComplete="off"
            aria-label={t('donationValueLabelWithCurrency', {
              currencyName: t(`currency|${donationCurrencyCode}`),
              ns: 'amount',
            })}
            className={styles.input}
            data-qa="donationValue"
            {...register('donationValue', {
              required: { value: true, message: t('amount|amount-not-set', { min, max, ns: 'validation' }) },
              min: { value: min, message: t('amount|amount-below-minimum', { min, max, ns: 'validation' }) },
              max: { value: max, message: t('amount|amount-above-maximum', { min, max, ns: 'validation' }) },
              pattern: {
                value: CONSTANTS.CURRENCY_REGEXP,
                message: t('amount|amount-not-set', { min, max, ns: 'validation' }),
              },
            })}
            aria-invalid={Boolean(errors.donationValue)}
            required
          />
        </div>
      </div>
      <FormError<AmountPageFormValues> fieldName="donationValue" />
    </div>
  );
};

type CurrencySelectorProps = {
  isSingleCurrency: boolean;
  currencySelectList: JSX.Element[];
};

const CurrencySelector: FunctionComponent<CurrencySelectorProps> = ({ isSingleCurrency, currencySelectList }) => {
  const { t } = useTranslation();
  const { register } = useFormContext<AmountPageFormValues>();
  const donationCurrencyCode = useWatch<AmountPageFormValues, 'donationCurrencyCode'>({ name: 'donationCurrencyCode' });

  return (
    <>
      <div className={styles.currencyWrapper}>
        {!isSingleCurrency ? (
          <div className={styles.currency}>
            <CurrencySymbol donationCurrencyCode={donationCurrencyCode} />
            <span aria-hidden="true">
              {donationCurrencyCode}{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width={9}
                height={11}
                viewBox="0 0 255 255"
                className={styles.currencyArrow}
              >
                <path d="m0 63.75 127.5 127.5L255 63.75z" />
              </svg>
            </span>
            <Select
              name="donationCurrencyCode"
              aria-label={t('donationCurrencyLabel', { ns: 'amount' })}
              data-qa="donationCurrency"
              id="donationCurrency"
              className={styles.currencySelect}
            >
              {currencySelectList}
            </Select>
          </div>
        ) : (
          <div className={styles.currency}>
            <CurrencySymbol donationCurrencyCode={donationCurrencyCode} />
            <span aria-hidden="true">{donationCurrencyCode}</span>
            <input type="hidden" {...register('donationCurrencyCode')} />
          </div>
        )}
      </div>
      <div className={styles.divider}></div>
    </>
  );
};

type CurrencySymbolProps = {
  donationCurrencyCode: AcceptedCurrencyCode;
};

const CurrencySymbol: FunctionComponent<CurrencySymbolProps> = ({ donationCurrencyCode }) => {
  return (
    <span
      id="donation-field-amount-prefix"
      className={styles.currencySymbol}
      aria-hidden="true"
      data-qa="donation-currency-code"
    >
      {donationCurrencyCode && formatCurrencySymbol(donationCurrencyCode)}
    </span>
  );
};
