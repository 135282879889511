import { createApi } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-tag';

import { Page, PageType } from '../../../../types/__generated-graphQL__';
import { getSourcePagePath } from '../../utils/getSourcePagePath';
import { graphqlRequestBaseQuery } from '../../utils/graphqlRequestBaseQuery';

export const additionalPageDataApiSlice = createApi({
  reducerPath: 'additionalPageData',
  baseQuery: graphqlRequestBaseQuery,
  endpoints: () => ({}),
});

export const queryAdditionalPageData = additionalPageDataApiSlice.injectEndpoints({
  endpoints: builder => ({
    fetchPage: builder.query<Page | undefined, void>({
      query() {
        const { pageType: type, pageSlug: slug } = getPageDetailsFromUrl();
        if (!slug || !type) return { document: undefined };
        return {
          variables: { slug, type },
          document: gql`
            query page($type: PageType!, $slug: Slug) {
              page(type: $type, slug: $slug) {
                owner {
                  avatar
                }
                hero {
                  imageUrl
                  imageTitle
                }
                cover {
                  __typename
                  ... on ImageMedia {
                    url
                    alt
                  }
                  ... on VideoMedia {
                    embeddedVideoURL
                  }
                  ... on LivestreamMedia {
                    embeddedStreamURL
                  }
                }
                donationSummary {
                  totalAmount {
                    value
                  }
                }
                fundraisingSettings {
                  thankYouMessages {
                    message
                    type
                  }
                }
                relationships {
                  beneficiaries(first: 1) {
                    nodes {
                      ... on Charity {
                        __typename
                        thankYouMessage
                      }
                    }
                  }
                  parentsConnection(first: 3) {
                    nodes {
                      productName
                      page {
                        slug
                        fundraisingSettings {
                          thankYouMessages {
                            message
                            type
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
        };
      },
      transformResponse: (response: { page: Page | null }) => response.page ?? undefined,
    }),
  }),
});

export const { useFetchPageQuery, useLazyFetchPageQuery } = queryAdditionalPageData;

const getPageDetailsFromUrl = (): { pageType: PageType | undefined; pageSlug: string | undefined } => {
  let pageType: PageType | undefined = undefined;
  let pageSlug: string | undefined = undefined;
  const sourcePagePath = getSourcePagePath(true);

  const components = sourcePagePath?.split('/').filter(Boolean);
  if (components.length === 2) {
    if (components[0] === 'fundraising') {
      pageType = PageType.Fundraising;
      pageSlug = components[1];
    }

    if (components[0] === 'crowdfunding') {
      pageType = PageType.Crowdfunding;
      pageSlug = components[1];
    }

    if (components[0] === 'page' || components[0] === 'campaign') {
      pageType = PageType.OnePage;
      pageSlug = components.join('/');
    }

    if (components[0].startsWith('campaigns.charity.')) {
      pageType = PageType.OnePage;
      pageSlug = `${components[0].replace(/\./g, '/')}/${components[1]}`;
    }
  }

  return { pageType, pageSlug };
};
