export const PAGES = {
  AmountPage: 'donation-amount',
  GiftAidDeclarationPage: 'donation-giftaid',
  GiftAidDetailsPage: 'giftaid-details',
  MessagePage: 'donation-message',
  SelectPaymentPage: 'select-payment',
  SummaryPage: 'donation-summary',
  ThankYouPage: 'thank-you',
  AmexPayWithPointsYourPaymentPage: 'your-payment',
} as const;

export type PageName = keyof typeof PAGES;
