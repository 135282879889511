import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import classNames from 'classnames';

import { AcceptedCurrencyCode, PaymentType } from '../../../../../types/__generated-graphQL__';
import { AmountPageFormValues, TransactionTypes } from '../../../../../types/formValues';
import { paymentIcon } from '../../../../styles/icons.css';
import { sprinkles } from '../../../../styles/sprinkles.css';
import VisuallyHidden from '../../../components/VisuallyHidden/VisuallyHidden';
import { PAGES } from '../../../config/pages';
import { useFetchCheckoutQuery } from '../../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLLineItemValueInPounds } from '../../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../../redux/checkoutApiSlice/selectors/order.selectors';
import {
  getGQLIsDirectDebitSelected,
  getGQLPaymentCardDetails,
  getGQLSelectedPaymentType,
} from '../../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getPaymentCardIcon } from '../../../utils/browser';
import * as styles from '../../Payment/PaymentInfoSummary.css';
import { AccordionPageCurrentStep } from '../AccordionPageCurrentStep';
import { AccordionPageFilledStep } from '../AccordionPageFilledStep';
import { AccordionPageLoadingStep } from '../AccordionPageLoadingStep';
import { AccordionPageNewStep } from '../AccordionPageNewStep';
import { AccordionPagePreviewEmerge } from '../AccordionPagePreviewEmerge';
import { AccordionPageSectionWrapper } from '../AccordionPageSectionWrapper';
import type { PageSectionProps } from './AccordionSection';

const SelectedPaymentType = () => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();

  const selectedPaymentType = getGQLSelectedPaymentType(data);
  const { cardType, lastFourDigits } = getGQLPaymentCardDetails(data) || {};

  switch (selectedPaymentType) {
    case PaymentType.BraintreePayPal: {
      return (
        <div className={styles.paymentWrapper}>
          <span className={paymentIcon({ type: 'payPal' })} data-qa="cardTypeIcon" />
          <span>{t('payByPayPalLabel')}</span>
        </div>
      );
    }
    case PaymentType.BraintreeApplePay: {
      return (
        <div className={styles.paymentWrapper}>
          <span className={paymentIcon({ type: 'applePay' })} data-qa="cardTypeIcon" />
          <VisuallyHidden>{t('payByApplePayLabel')}</VisuallyHidden>
        </div>
      );
    }
    case PaymentType.BraintreeGooglePay: {
      return (
        <div className={styles.paymentWrapper}>
          <span className={paymentIcon({ type: 'googlePay' })} data-qa="cardTypeIcon" />
          <span>{t('payByGooglePayLabel')}</span>
        </div>
      );
    }
    case PaymentType.BraintreeVenmo: {
      return (
        <div className={styles.paymentWrapper}>
          <span className={paymentIcon({ type: 'venmo' })} data-qa="cardTypeIcon" />
          <span>{t('payByVenmoLabel')}</span>
        </div>
      );
    }
    case PaymentType.GoCardlessInstantBankPayment: {
      return (
        <div className={styles.paymentWrapper}>
          <span className={paymentIcon({ type: 'instantBank' })} data-qa="cardTypeIcon" />
          <span>{t('instantBankPay')}</span>
        </div>
      );
    }
    case PaymentType.PaymentCard: {
      const iconType = getPaymentCardIcon(cardType!);
      return (
        <>
          <div>
            {lastFourDigits && (
              <>
                <span
                  className={classNames(
                    sprinkles({ verticalAlign: 'middle', marginRight: 'sizeSpacing02' }),
                    paymentIcon({ type: iconType ?? 'cardFront' }),
                  )}
                  data-qa="cardTypeIcon"
                />
                <span>
                  {t('summary|cardLastFourDigitsLabel', { ns: 'payment' })}{' '}
                  <span data-qa="lastFourDigits">{lastFourDigits}</span>
                </span>
              </>
            )}
          </div>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

const SelectPaymentStepPreview: FunctionComponent = () => {
  const { t } = useTranslation();

  const { data } = useFetchCheckoutQuery();
  const lineItemValue = getGQLLineItemValueInPounds(data);
  const currency = getGQLCurrencyCode(data);

  const selectedPaymentType = getGQLSelectedPaymentType(data);

  if (selectedPaymentType && lineItemValue && currency) {
    return (
      <AccordionPageFilledStep
        title={t('payment|filled|title', { ns: 'ab' })}
        routeTo={'SelectPaymentPage'}
        a11yRouteText={t('summary|paymentInfoEditScreenReader', { ns: 'payment' })}
      >
        <SelectedPaymentType />
      </AccordionPageFilledStep>
    );
  }

  return <AccordionPageNewStep>{t('payment|title', { ns: 'ab' })}</AccordionPageNewStep>;
};

const SelectPaymentPageSection: FunctionComponent<PageSectionProps> = ({
  children,
  currentPageCollapsed,
  isLoading,
}) => {
  const isCurrentPage = useMatch(`/:pageType?/:pageSlug?/${PAGES.SelectPaymentPage}`);

  const { data } = useFetchCheckoutQuery();

  const [formDonationCurrencyCode, formTransactionType] = useWatch<
    AmountPageFormValues,
    ['donationCurrencyCode', 'transactionType']
  >({ name: ['donationCurrencyCode', 'transactionType'] });

  const isDirectDebitSelected = formDonationCurrencyCode
    ? formDonationCurrencyCode === AcceptedCurrencyCode.Gbp && formTransactionType === TransactionTypes.RECURRING
    : getGQLIsDirectDebitSelected(data);

  if (isDirectDebitSelected) return null;

  if (isLoading) return <AccordionPageLoadingStep />;

  if (isCurrentPage) {
    return (
      <li>
        <AccordionPageSectionWrapper>
          {currentPageCollapsed && (
            <AccordionPagePreviewEmerge>
              <SelectPaymentStepPreview />
            </AccordionPagePreviewEmerge>
          )}
          <AccordionPageCurrentStep collapsed={currentPageCollapsed}>{children}</AccordionPageCurrentStep>
        </AccordionPageSectionWrapper>
      </li>
    );
  }

  return (
    <li>
      <SelectPaymentStepPreview />
    </li>
  );
};

export default SelectPaymentPageSection;
