import { RefObject, useEffect, useRef } from 'react';

export function useHeadingSetFocusRef(): RefObject<HTMLHeadingElement> {
  const ref = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);
  return ref;
}
