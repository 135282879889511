import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sprinkles } from '../../../styles/sprinkles.css';
import { colors } from '../../../styles/variables/tokens.css';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import {
  getTipDefaultValue,
  getTipModalOpen,
  getTipInputMode,
  getTipSelectionValue,
  getTipValueType,
} from '../../redux/tip/tip.selectors';
import { closeTipModal, openTipModal, setTipSelectionValue } from '../../redux/tip/tip.slice';
import TipJarOtherAmountModal from './TipJarOtherAmountModal';
import * as styles from './TipJarSlider.css';
import useGetTipOptions from './useGetTipOptions';
import { LabelAndValue } from './useInitTipjar';
import useSetTipAmount from './useSetTipAmount';

export const TipJarSlider: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tipInputMode = useSelector(getTipInputMode);
  const tipSelectionValue = useSelector(getTipSelectionValue);
  const tipOptions = useGetTipOptions();
  const tipValueType = useSelector(getTipValueType);
  const tipDefaultValue = useSelector(getTipDefaultValue);

  const labelContainerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const [labelContainerWidth, setLabelContainerWidth] = useState(0);
  const [labelWidth, setLabelWidth] = useState(0);
  const [sliderValue, setSliderValue] = useState(
    calculateInitialSliderValue(tipOptions, tipSelectionValue!, tipDefaultValue!),
  );

  const isTipModalOpen = useSelector(getTipModalOpen);

  // This resets the slider to the correct position when a change in donation amount means the available options change - eg. reducing donation amount to less than 10
  const [rememberedSelectedValue, setRememberedSelectedValue] = useState(tipSelectionValue);
  if (tipInputMode !== 'Other' && rememberedSelectedValue !== tipSelectionValue) {
    setSliderValue(calculateInitialSliderValue(tipOptions, tipSelectionValue!, tipDefaultValue!));
    setRememberedSelectedValue(tipSelectionValue);
  }

  const { setTipAmount } = useSetTipAmount();

  useEffect(() => {
    if (tipInputMode !== 'Other') {
      setTipAmount({ valueType: tipValueType, value: tipSelectionValue! });
    }
  }, [tipSelectionValue, setTipAmount, tipValueType, tipInputMode]);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (labelContainerRef.current) setLabelContainerWidth(labelContainerRef.current.offsetWidth);
      if (labelRef.current) setLabelWidth(labelRef.current.offsetWidth);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [labelContainerRef, labelRef]);

  const sortedValues = tipOptions
    .filter(option => typeof option.value === 'number')
    .sort((a, b) => {
      return Number(a.value) - Number(b.value);
    });
  const step = 1;
  const numberOfOptionsMinusOne = sortedValues.length - 1;
  const label = tipOptions.find(option => option.value === tipSelectionValue)?.label;
  const sliderPercentage = (sliderValue / numberOfOptionsMinusOne) * 100;

  const sliderBackgroundPoints =
    Array(sortedValues.length - 2)
      .fill('')
      .map((_val, index) => {
        const pointNumber = index + 1;
        const percentage = (pointNumber / numberOfOptionsMinusOne) * 100;
        const colour = sliderPercentage >= (pointNumber / numberOfOptionsMinusOne) * 100 ? 'white' : colors.nero;
        return `radial-gradient(circle at ${percentage}% 5px, ${colour} 3px, transparent 0)`;
      })
      .join(', ') || 'linear-gradient(90deg, transparent 0%, transparent 0%)'; // Fallback where there are only 2 values in the slider, to keep CSS syntax correct

  const originalLeft = (labelContainerWidth / 100) * sliderPercentage - labelWidth / 2;
  const widthDiff = 30; // Difference between the track actual width and visible background width
  const fraction = widthDiff / 100;
  const multiplier = 50 - sliderPercentage;
  const adjustment = fraction * multiplier;
  const adjustedLeft = originalLeft + adjustment;
  const minLeft = 0;
  const maxLeft = labelContainerWidth - labelWidth;
  const labelLeft = Math.min(maxLeft, Math.max(adjustedLeft, minLeft));
  const pointerLeft = adjustedLeft + labelWidth / 2;

  const inputRef = useRef<HTMLInputElement>(null);
  const [shouldSliderFocusOnLoad, setShouldSliderFocusOnLoad] = useState(false);
  // Set focus into the slider when it is rendered - but not on initial render of page
  useEffect(() => {
    if (tipInputMode !== 'Other' && shouldSliderFocusOnLoad) {
      setShouldSliderFocusOnLoad(false);
      inputRef.current?.focus();
    }
  }, [shouldSliderFocusOnLoad, inputRef, tipInputMode]);

  const onOtherAmountModalClose = () => {
    setShouldSliderFocusOnLoad(true);
    dispatch(closeTipModal());
  };

  if (isTipModalOpen) {
    return <TipJarOtherAmountModal isOpen={isTipModalOpen} onClose={onOtherAmountModalClose} />;
  }

  if (tipInputMode === 'Other') return null;

  return (
    <>
      <div className={styles.rangeContainer}>
        <VisuallyHidden as="label" htmlFor="tipAmount">
          {t('pleaseSelectATip', { ns: 'tipJar' })}
        </VisuallyHidden>
        <input
          step={step}
          min="0"
          max={numberOfOptionsMinusOne}
          type="range"
          id="tipAmount"
          data-qa="tipAmount"
          ref={inputRef}
          value={sliderValue}
          className={styles.rangeInput}
          onChange={event => {
            setSliderValue(Number(event.target.value));
            dispatch(setTipSelectionValue({ value: tipOptions[Number(event.target.value)].value }));
          }}
          style={
            {
              '--slider-position': `${sliderPercentage}%`,
              '--slider-background-points': sliderBackgroundPoints,
            } as React.CSSProperties
          }
          aria-valuetext={sortedValues[sliderValue]?.label}
        />
        <div ref={labelContainerRef} className={styles.rangeValueContainer} aria-hidden>
          <div ref={labelRef} className={styles.rangeValue} style={{ left: `${labelLeft}px` }} data-qa="tipLabel">
            {label}
          </div>
          <div className={styles.rangeValuePointer} aria-hidden style={{ left: `${pointerLeft}px` }}></div>
        </div>
      </div>
      <div className={sprinkles({ textAlign: 'center' })}>
        <ButtonTextLink
          alwaysUnderline={false}
          type="button"
          onClick={() => {
            dispatch(openTipModal());
          }}
        >
          {t('enterCustomAmount', { ns: 'tipJar' })}
        </ButtonTextLink>
      </div>
    </>
  );
};

const calculateInitialSliderValue = (
  rangeOptions: LabelAndValue[],
  selectedValue: number | 'other',
  defaultValue: number,
): number => {
  return rangeOptions.findIndex(
    option => option.value === (typeof selectedValue === 'number' ? selectedValue : defaultValue),
  );
};
