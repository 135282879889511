import "src/app/modules/DonationValue/DonationValue.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/modules/DonationValue/DonationValue.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVTY/aMBC98ytGqirtHoKSEALrvazUU++Veja2EyyMHTnObtiK/17FjkOcAItaEAfsefPx5s14WcpEbT9j+LMAOGJdchkZVSFI8qp9XZwXS2eQWAPK60rgE4JCsPZ1AbBnvNwbBNs4sF5Z60rV3HAlEWgmsOHvrIN00KjU6gNBMoJkdyCfEZeUtRYAUChpopp/MgSZDQtQYUq5LBHEkPZHYWYAH5yaPYIkjr93f3dKU6YjjSlv6g6XVa39xd0tUUJpBN82OM4oHZ28Y/0URfioGml+yqoxv1hrfnR3zyOvtTkJhqBWgtPRsU/BJdQfClYYfxOPzn0KxH66C1xVDGssCUMglbTMGI2lZyxepnWAHrOLCkWa2nI8CZDQfLOzzlRjBJeD9wGcQtCjCZ7usmKdj81drOiDmz2Xd7E+9oBd91bGqCOCZO2o6jhCkKTu36CG9HUsyZ1Q5DDRR5o5iI+X5t3XuuSSRV4jXhS9jPoB2AbainxSeX88KLU22HAyKiK3RYzSiMeC9MFCNdipqPeay4PXASaHUqtG0itamPd9Yn2tfS6vK34fbOFN/KyNCZLKPHlnz//pbWMt/3UmH2K2KIqvBvXGUD/Sm2AmL7un08O10fdLya4jt5pGgtmkwardhOQ+MmRbp08uRECnO7jL5uDixW3rfjTcfNoSDGtNhAUvJQLCpGF6MpJJX004gNl0qC7rf4iJJy8E3tVKNIZdNoTLoBveYOL8E6AqTLg59bfhGrm2XMdZ51dfEdLouuO4UtyVel68ESUN5pJpeDpy6fu73mZV+2wrWI6f3PDR9fvqHDoie0YOqjG/dZfmV46T3rEv/yX3TiFcxkGFeU/SXdx6jltlM6v86wQ2vcmFz5nJyzyW78JEPekcjG+mcF68HRnlGJQUJ6iJZkwCljQgdZUv1+wYsLqdZJxM6UpWQ4i/SzAfp1UJAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var amount = 'gn1o8z3';
export var currency = 'gn1o8z9';
export var currencyArrow = 'gn1o8z8';
export var currencySelect = 'gn1o8za';
export var currencySymbol = 'gn1o8z5';
export var currencyWrapper = 'gn1o8z7';
export var divider = 'gn1o8z6';
export var group = 'gn1o8z1';
export var input = 'gn1o8z4';
export var isError = 'gn1o8z2';
export var wrapper = 'gn1o8z0';