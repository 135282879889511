import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import APP_SETTINGS from '../../../config';
import { button } from '../../../styles/recipes/buttons.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLCheckoutMode,
  getGQLDeepLinkUri,
  getGQLRedirectUrl,
  getGQLReferrer,
  getGQLSkipMessage,
} from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLCauseSourceRef,
  getGQLLineItemValueInPounds,
  getGQLMessage,
  getGQLOrderType,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLTotalAmountInPounds,
  getGQLCurrencyCode,
  getGQLAppliedTipScheme,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { isGuid } from '../../utils/isGuid';
import { mapTipSchemeToLinkServiceFormat } from '../../utils/utilityMapperFunctions';

export const Retry: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();
  const orderType = getGQLOrderType(data);
  const causeSourceRef = getGQLCauseSourceRef(data);
  const skipMessage = getGQLSkipMessage(data);
  const referrer = getGQLReferrer(data);
  const redirectUrl = getGQLRedirectUrl(data);
  const checkoutMode = getGQLCheckoutMode(data);
  const deepLinkUri = getGQLDeepLinkUri(data);
  const message = getGQLMessage(data);
  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const totalAmountInPounds = getGQLTotalAmountInPounds(data);
  const currencyCode = getGQLCurrencyCode(data);
  const tipScheme = getGQLAppliedTipScheme(data);

  const retryLink = ((): string | null => {
    // Giving Checkout links (passed by API as the underlying Link Service URL)
    if (deepLinkUri && RegExp(`^${APP_SETTINGS.LINK_SERVICE_URL}/v[0-9]+/link/`).test(deepLinkUri)) {
      return deepLinkUri;
    }

    let queryParams = `?donationValue=${lineItemValueInPounds}&currency=${currencyCode}`;

    if (tipScheme && tipScheme !== 'Disabled' && totalAmountInPounds) {
      queryParams += `&totalAmount=${totalAmountInPounds}&tipScheme=${mapTipSchemeToLinkServiceFormat(tipScheme)}`;
    } else {
      queryParams += `&totalAmount=${lineItemValueInPounds}`;
    }

    const deepLinkParams = extractParamsFromDeepLink(deepLinkUri);
    if (deepLinkParams && deepLinkParams.length > 0) {
      deepLinkParams.forEach(param => {
        queryParams += `&${param.key}=${param.value}`;
      });
    }

    // Values that can be set in additionalData and passed as Link Service parameters
    if (redirectUrl && !queryParams.includes('&exitUrl=')) {
      queryParams += `&exitUrl=${encodeURIComponent(redirectUrl)}`;
    }
    if (skipMessage && !queryParams.includes('&skipMessage=')) {
      queryParams += `&skipMessage=${skipMessage}`;
    }
    if (referrer && !queryParams.includes('&referrer=')) {
      queryParams += `&referrer=${encodeURIComponent(referrer)}`;
    }
    if (checkoutMode && !queryParams.includes('&checkoutMode=')) {
      queryParams += `&checkoutMode=${checkoutMode}`;
    }

    // Values that can be set by user and passed as Link Service parameters
    if (message) {
      queryParams += `&message=${encodeURIComponent(message)}`;
    }

    // Link Service links
    if (deepLinkUri?.startsWith(APP_SETTINGS.LINK_SERVICE_URL)) {
      return `${deepLinkUri.split(/\?(.*)/)[0]}${queryParams}`;
    }

    // Donations not started via Link Service
    switch (orderType) {
      case LineItemType.DonationCharityFrp:
      case LineItemType.DonationCampaignFrp: {
        let linkServicePageType = 'fundraisingpage';
        // One page sends through a guid for cause source ref
        if (isGuid(causeSourceRef)) {
          linkServicePageType = 'page';
        }
        return `${APP_SETTINGS.LINK_SERVICE_URL}/v1/${linkServicePageType}/donate/pageid/${causeSourceRef}${queryParams}`;
      }
      case LineItemType.DonationCharityDirect:
        return `${APP_SETTINGS.LINK_SERVICE_URL}/v1/charity/donate/charityId/${causeSourceRef}${queryParams}`;
      case LineItemType.DonationCampaignDirect:
        return causeSourceRef
          ? `${APP_SETTINGS.LINK_SERVICE_URL}/v1/page/donate/pageId/${causeSourceRef}${queryParams}`
          : null;
      case LineItemType.DonationCrowdfunding:
        return `${APP_SETTINGS.LINK_SERVICE_URL}/v1/crowdfundingpage/donate/pageid/${causeSourceRef}${queryParams}`;
      default:
        return null;
    }
  })();

  if (!retryLink) {
    return null;
  }

  return (
    <>
      <a
        href={retryLink}
        data-qa="ctaRetryDonation"
        className={classNames(
          button({ color: 'primary', size: 'minWidth' }),
          sprinkles({ marginBottom: 'sizeSpacing04' }),
        )}
      >
        {t('tryAgain')}
      </a>
      <div data-qa="heading-3-invalid">
        {t('invalid|retryContact', { ns: 'status' })}{' '}
        <a href="https://www.justgiving.com/help" rel="noopener noreferrer" target="_blank">
          {t('supportteam')}
        </a>
      </div>
    </>
  );
};

type QueryParams = { key: string; value: string };

const extractParamsFromDeepLink = (deepLinkUri?: string): QueryParams[] | null => {
  if (!deepLinkUri) return null;

  const components = deepLinkUri.split(/\?(.*)/); // 3 values: url, value, empty string (product of the regex)
  if (components.length === 1) return null; // No query string params

  const queryParams = components[1].split('&');
  const suppressedParams = ['amount', 'donationValue', 'currency', 'totalAmount', 'tipScheme', 'message'];

  const paramsToReturn: QueryParams[] = [];
  queryParams.forEach(param => {
    const keyValue = param.split(/=(.*)/); // 3 values: key, value, empty string (product of the regex)

    if (suppressedParams.includes(keyValue[0])) return;

    paramsToReturn.push({ key: keyValue[0], value: keyValue[1] });
  });

  return paramsToReturn;
};
