import bankersRounding from 'bankers-rounding';

export const calculateCryptoAmount = (fiatAmount: string, exchangeRate: number): string =>
  isNaN(Number(fiatAmount)) ? '0' : bankersRounding(Number(fiatAmount) / exchangeRate, 8).toString();

export const calculateFiatAmount = (cryptoAmount: string, exchangeRate: number): string =>
  isNaN(Number(cryptoAmount)) ? '0' : bankersRounding(Number(cryptoAmount) * exchangeRate, 2).toString();

export const convertScientificNotationToLongForm = (value: string): string => {
  if (!value.includes('-', 1)) return value;
  return Number(value).toFixed(Number(value.split('-')[1]));
};
