import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import bankersRounding from 'bankers-rounding';

import { AmountPageFormValues } from '../../../../types/formValues';

const useSetTipAmount = () => {
  const { setValue } = useFormContext<AmountPageFormValues>();

  const donationValue = useWatch<AmountPageFormValues, 'donationValue'>({ name: 'donationValue' });

  const setTipAmount = useCallback(
    ({ valueType, value }: { valueType?: 'Amount' | 'Percentage'; value: number }) => {
      if (valueType === 'Percentage') {
        const calculatedValue = bankersRounding(Number(donationValue) * (value / 100), 2);
        setValue('tipAmount', calculatedValue);
      } else {
        setValue('tipAmount', value);
      }
    },
    [donationValue, setValue],
  );
  return { setTipAmount };
};

export default useSetTipAmount;
