import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import APP_SETTINGS from '../../../config';
import * as styles from './DropdownMenuWrapper.css';

type DropdownMenuWrapperProps = {
  children: React.ReactNode;
  buttonTitle?: string;
};

export const DropdownMenuWrapper: FunctionComponent<DropdownMenuWrapperProps> = ({ children, buttonTitle }) => {
  const { t } = useTranslation();

  const menuBoxMaxWidth = 280;
  const applicationPadding = 12;

  const parentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [leftPosition, setLeftPosition] = useState<number | undefined>();

  const setFocusOnButton = () => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (isOpen && e.code === 'Escape') {
        setIsOpen(false);
        setFocusOnButton();
      }
    },
    [isOpen],
  );

  const handleFocusOutsidemenuBox = (e: Event) => {
    if (
      contentRef.current &&
      !contentRef.current.contains(e.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleFocusOutsidemenuBox);
    document.addEventListener('focusin', handleFocusOutsidemenuBox);
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('click', handleFocusOutsidemenuBox);
      document.removeEventListener('focusin', handleFocusOutsidemenuBox);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [handleEscape]);

  const calculateMenuLeftPosition = useCallback(() => {
    const buttonBoundingClientRect = buttonRef.current?.getBoundingClientRect();
    const parentBoundingClientRect = parentRef.current?.getBoundingClientRect();
    const parentOffsetLeft = parentRef.current?.offsetLeft;

    const horizontalOffsetCalculation =
      (parentBoundingClientRect?.left ?? 0) + window.scrollX - (parentOffsetLeft ?? 0);

    const menuBoxMaxWidthLessAppPadding = menuBoxMaxWidth - applicationPadding * 2;
    const availableWidth = window.innerWidth - applicationPadding * 2;
    const defaultArrowPadding = 100;

    let menuBoxWidth = availableWidth;

    if (menuBoxWidth > menuBoxMaxWidthLessAppPadding) {
      menuBoxWidth = menuBoxMaxWidthLessAppPadding;
    }

    let leftPosition = (buttonBoundingClientRect?.left ?? 0) - defaultArrowPadding;
    const menuBoxOffscreenSize = leftPosition + menuBoxWidth - availableWidth;

    leftPosition = leftPosition < applicationPadding ? applicationPadding : leftPosition;
    const offScreenLeftAdjust = menuBoxOffscreenSize > 0 ? menuBoxOffscreenSize - applicationPadding : 0;

    leftPosition = leftPosition - horizontalOffsetCalculation - offScreenLeftAdjust;

    return leftPosition;
  }, [menuBoxMaxWidth, applicationPadding]);

  useEffect(() => {
    const handleResize = () => {
      const leftPosition = calculateMenuLeftPosition();
      if (isOpen) {
        setLeftPosition(leftPosition);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, calculateMenuLeftPosition]);

  const handleClick = () => {
    setIsOpen(currentIsOpen => {
      return !currentIsOpen;
    });
  };

  const buttonClasses = classNames(styles.dropdownMenuButton, {
    [styles.buttonOpen]: isOpen,
  });

  return (
    <div ref={parentRef} className={classNames(styles.container, styles.dropdownMenuContainer)}>
      <button
        ref={buttonRef}
        id="dropdownMenuWrapper"
        type="button"
        data-qa="dropdownMenu-btn-toggle"
        className={buttonClasses}
        onClick={handleClick}
        aria-label={`${buttonTitle} - ${t('userMenu', { ns: 'chromeHeader' })}`}
        aria-controls="dropdownMenu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        title={buttonTitle}
      >
        {children}
      </button>
      {isOpen && (
        <div
          className={styles.menuBox}
          ref={contentRef}
          style={{
            left: leftPosition,
          }}
        >
          <MenuItemList />
        </div>
      )}
    </div>
  );
};

const MenuItemList: FunctionComponent = () => {
  const { t } = useTranslation();
  const firstItemRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    firstItemRef.current?.focus();
  }, []);

  return (
    <ul className={styles.list} id="dropdownMenu" role="menu" aria-labelledby="dropdownMenuWrapper">
      <li role="none">
        <a ref={firstItemRef} role="menuitem" href={`${APP_SETTINGS.HOME_URL}/signin/logout`} className={styles.link}>
          {t('logOut', { ns: 'chromeHeader' })}
        </a>
      </li>
    </ul>
  );
};
