import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthContext } from '@justgiving/auth-react';
import promiseRetry from 'promise-retry';

import { TransactionStatus } from '../../../../types/__generated-graphQL__';
import APP_SETTINGS from '../../../config';
import { useFetchCheckoutQuery, useLazyFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLRedirectUrl } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLIsDirectDebitSelected } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLTransactionReference } from '../../redux/checkoutApiSlice/selectors/transaction.selectors';
import { useLazyQueryTransationStatusQuery } from '../../redux/transactionStatusApiSlice/transactionStatusApiSlice';
import { mapTransactionStatusToDonationsApiStatus } from '../../utils/utilityMapperFunctions';

export default function useInSession() {
  const { data } = useFetchCheckoutQuery();
  const [status, setStatus] = useState<DonationStatus>('pending');
  const dispatch = useDispatch();
  const isDirectDebitSelected = getGQLIsDirectDebitSelected(data);
  const redirectUrl = getGQLRedirectUrl(data);
  const transactionReference = getGQLTransactionReference(data);
  const auth = useContext(AuthContext);
  const [queryCheckout] = useLazyFetchCheckoutQuery();
  const [queryTransactionStatus] = useLazyQueryTransationStatusQuery();

  useEffect(() => {
    const checkDonationStatus = async (minTimeout = APP_SETTINGS.DONATION_STATUS_RETRY_TIMEOUT) => {
      if (isDirectDebitSelected) {
        setStatus('directdebit_succeeded');
        return;
      }

      const retryData = {
        retries: APP_SETTINGS.DONATION_STATUS_RETRIES,
        factor: 1,
        minTimeout,
        maxTimeout: minTimeout,
      };

      try {
        if (!transactionReference) {
          await promiseRetry<void>(
            async retry => {
              const { data } = await queryCheckout();
              const reference = data?.transaction?.reference;
              if (data?.transaction?.isComplete === false || !reference) {
                return retry(null);
              }

              return;
            },
            {
              retries: APP_SETTINGS.DONATION_STATUS_RETRIES,
              factor: 1,
              minTimeout: APP_SETTINGS.BANK_TRANSFER_STATUS_RETRY_TIMEOUT ?? 2000,
            },
          );
        }

        if (transactionReference) {
          await promiseRetry<void>(async retry => {
            const transactionStatusResponse = await queryTransactionStatus({ transactionReference });

            if ('error' in transactionStatusResponse) {
              throw transactionStatusResponse.error;
            }

            const transactionStatus = transactionStatusResponse.data;

            const updatedStatus = mapTransactionStatusToDonationsApiStatus(
              transactionStatus ?? TransactionStatus.Pending,
            );

            setStatus(updatedStatus);

            if (updatedStatus === 'pending') {
              return retry(null);
            }
            return;
          }, retryData);
        }
      } catch (error) {
        setStatus(redirectUrl ? 'redirecting' : 'timeout');
      }
    };
    checkDonationStatus();
  }, [dispatch, isDirectDebitSelected, redirectUrl, transactionReference, auth, queryCheckout, queryTransactionStatus]);
  return status;
}
