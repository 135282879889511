export const CONSTANTS = {
  CROWDFUNDING_SUGGESTED_AMOUNTS: {
    VALUES: [20, 30, 40, 50, 100],
  },
  PRIVACY_VERSION: 'JGPP-GB-2015-11-09',
  TERMS_AND_CONDITIONS_VERSION: 'JGTC-GB-2015-11-09',
  APPLE_PAY_API_VERSION: 2,
  FACEBOOK_SDK_VERSION: 'v8.0',
  NAME_REGEXP: /^ *[A-Za-zÀ-ž]([A-Za-zÀ-ž'`'\- ]*[A-Za-zÀ-ž])+ *$/,
  // eslint-disable-next-line no-useless-escape
  BANK_ACCOUNT_HOLDER_NAME: /^[A-Za-z0-9.&\/\- ]+$/,
  UK_POSTCODE_REGEXP:
    /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))\s*[0-9][A-Z]{2}$/i,
  EMAIL_REGEXP:
    // eslint-disable-next-line no-control-regex
    /^ *((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))) *$/i,
  EMAIL_TLD_REGEXP: /\.[a-z]{2,20}$/i,
  CURRENCY_REGEXP: /^\d+([.]\d{1,2})?$/,
  CARD_VERIFICATION_DECLINED: 'card-verification-declined',
  AMEX_PAY_WITH_POINTS_CONVERSION_DEFAULT: 0.07,
  PLATFORM_FEE_PERCENTAGE_DEFAULT: 5,
} as const;
