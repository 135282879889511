import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import { sprinkles } from '../../../styles/sprinkles.css';
import Label from '../../components/Form/Label/Label';
import Select from '../../components/Form/Select/Select';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';

export const CancelWhenCauseExipres: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const orderType = getGQLOrderType(data);
  const charityName = getGQLCharityName(data);
  const { t } = useTranslation();

  return (
    <>
      <Label htmlFor="cancelWhenCauseExpires">
        {t('cancelWhenCauseExpires|continueToSupport', { ns: 'recurring', causeName: charityName })}
      </Label>
      <Select
        name="directDebit.cancelWhenCauseExpires"
        data-qa="cancelWhenCauseExpires-select"
        id="cancelWhenCauseExpires"
        className={sprinkles({ marginTop: 'sizeSpacing02' })}
      >
        <option value="no">{t('cancelWhenCauseExpires|no', { ns: 'recurring' })}</option>
        <option value="yes">
          {t(
            `cancelWhenCauseExpires|yes|${
              orderType === LineItemType.DonationCampaignDirect ? 'campaignDirect' : 'frp'
            }`,
            { ns: 'recurring' },
          )}
        </option>
      </Select>
    </>
  );
};
