import { EntityType, MarketCode } from '../../../../types/__generated-graphQL__';

export type Consent = {
  type: string;
  id: number;
  text: string;
  entityType: EntityType;
};
type Preferences = {
  donationCauseSourceRef: string;
  collectionLocationId: number;
  marketCode?: MarketCode;
  regionCode: string;
  consents: Consent[];
};

export const setInitialPreferences = (
  cause: { donationTarget: string; causeName: string },
  preferences: Preferences,
  defaultConsentToFalse: boolean,
) =>
  ({
    type: 'preferences/setInitial',
    cause,
    preferences,
    defaultConsentToFalse,
  }) as const;

export const updateConsent = (consent: State['preferences']['consents'][number]) =>
  ({
    type: 'preferences/updateConsent',
    consent,
  }) as const;

export type UserConsentActionTypes = ReturnType<typeof setInitialPreferences> | ReturnType<typeof updateConsent>;
