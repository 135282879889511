import React, { FunctionComponent, ReactNode } from 'react';

import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { PageName, PAGES } from '../../config/pages';
import SummaryEditLink from '../Summary/SummaryEditLink';
import * as styles from './AccordionPageFilledStep.css';

type AccordionPageFilledStepProps = {
  children: ReactNode;
  title: string;
  routeTo: PageName;
  a11yRouteText: string;
  lockChange?: boolean;
};

export const AccordionPageFilledStep: FunctionComponent<AccordionPageFilledStepProps> = ({
  children,
  title,
  routeTo,
  a11yRouteText,
  lockChange,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div>{children}</div>
      {!lockChange && (
        <SummaryEditLink to={`../${PAGES[routeTo]}`} className={styles.change}>
          Change<VisuallyHidden> {a11yRouteText}</VisuallyHidden>
        </SummaryEditLink>
      )}
    </div>
  );
};
