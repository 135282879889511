import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';

import { AuthContext } from '@justgiving/auth-react';
import classNames from 'classnames';

import TickBlack from '../../../assets/images/tickBlack.svg';
import { sprinkles } from '../../../styles/sprinkles.css';
import { Image } from '../../components/Image/Image';
import Loading from '../../components/Loading/Loading';
import { optimizelyHeapPropertiesNotTracked } from '../../hooks/useOptimizelyListener';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCheckoutMode } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { addBaseHeapProperties } from '../../utils/heap';
import * as styles from './CookieBanner.css';

export const CookieBanner: FunctionComponent = () => {
  const auth = useContext(AuthContext);

  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isDeclineLoading, setIsDeclineLoading] = useState(false);
  const checkoutMode = getGQLCheckoutMode(data);

  const showBanner = useCallback(() => {
    setShouldShowBanner(true);
  }, []);

  const hideBanner = useCallback(() => {
    setShouldShowBanner(false);
  }, []);

  useEffect(() => {
    if (window.Cookiebot?.hasResponse === false) {
      showBanner();
    }
  }, [showBanner]);

  useEffect(() => {
    if (window.Cookiebot?.hasResponse) {
      hideBanner();
    }
    window.addEventListener('CookiebotOnAccept', hideBanner);
    window.addEventListener('CookiebotOnDecline', hideBanner);

    return () => {
      window.removeEventListener('CookiebotOnAccept', hideBanner);
      window.removeEventListener('CookiebotOnDecline', hideBanner);
    };
  }, [hideBanner]);

  const manageHeapProperties = useCallback(() => {
    try {
      if (data) addBaseHeapProperties(data, auth, optimizelyHeapPropertiesNotTracked);
    } catch (error) {
      // Do nothing
    }
  }, [auth, data]);

  useEffect(() => {
    window.addEventListener('CookiebotOnAccept', manageHeapProperties);
    return () => {
      window.removeEventListener('CookiebotOnAccept', manageHeapProperties);
    };
  }, [manageHeapProperties]);

  const handleAccept = () => {
    if (window.Cookiebot?.submitCustomConsent) {
      setIsAcceptLoading(true);
      window.Cookiebot.submitCustomConsent(true, true, true);
    }
  };

  const handleDecline = () => {
    if (window.Cookiebot?.withdraw) {
      setIsDeclineLoading(true);
      window.Cookiebot.withdraw();
    }
  };

  if (checkoutMode === 'Headless') return null;

  const banner = (
    <aside
      className={classNames(styles.wrapper, { [sprinkles({ display: 'none' })]: !shouldShowBanner })}
      aria-label={t('ariaLabel', { ns: 'cookie' })}
    >
      <div className={styles.content}>
        <div className={styles.text}>
          <p>
            <Trans i18nKey="message" ns="cookie">
              We would like to use optional cookies to improve and personalise your experience on JustGiving. Learn more
              in our{' '}
              <a className={styles.textAnchor} href="https://www.justgiving.com/info/cookies">
                cookie policy
              </a>
              .
            </Trans>
            {` \u{1F36A}`}
          </p>
        </div>
        <div className={styles.buttonGroup}>
          <button
            className={classNames(styles.button, styles.buttonAccept, {
              'is-loading': isAcceptLoading,
            })}
            onClick={handleAccept}
            disabled={isAcceptLoading || isDeclineLoading}
          >
            {isAcceptLoading ? (
              <Loading size="s" />
            ) : (
              <>
                <Image src={TickBlack} className={styles.buttonIcon} />
                {t('accept', { ns: 'cookie' })}
              </>
            )}
          </button>
          <button
            className={classNames(styles.button, styles.buttonDecline, {
              'is-loading': isDeclineLoading,
            })}
            onClick={handleDecline}
            disabled={isAcceptLoading || isDeclineLoading}
          >
            {isDeclineLoading ? <Loading size="s" /> : t('decline', { ns: 'cookie' })}
          </button>
        </div>
      </div>
    </aside>
  );

  const placeholder = document.getElementById('custom-cookiebot-banner-placeholder');

  return placeholder ? createPortal(banner, placeholder) : null;
};
