import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Address } from '../../../../types/__generated-graphQL__';
import * as labelStyles from '../../components/Form/Label/Label.css';
import { PreLineWhiteSpace } from '../../components/PreLineWhitespace/PreLineWhitespace';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import * as styles from './addressLookupSelectedAddress.css';

type AddressLookupSelectedAddressProps = { selectedAddress: Address; onEdit: () => void; formPrefix: string };
const AddressLookupSelectedAddress: FunctionComponent<AddressLookupSelectedAddressProps> = ({
  selectedAddress,
  onEdit,
  formPrefix,
}) => {
  const { register } = useFormContext();
  const { t } = useTranslation();
  const { line1, line2, townCity, countyStateRegion, postalCode, country } = selectedAddress;

  return (
    <div className={styles.wrapper} data-qa="selected-address">
      <input type="hidden" {...register(`${formPrefix}.address.line1`, { value: line1 })} />
      <input type="hidden" {...register(`${formPrefix}.address.line2`, { value: line2 })} />
      <input type="hidden" {...register(`${formPrefix}.address.townCity`, { value: townCity })} />
      <input type="hidden" {...register(`${formPrefix}.address.postalCode`, { value: postalCode })} />
      <input type="hidden" {...register(`${formPrefix}.address.countyStateRegion`, { value: countyStateRegion })} />
      <input type="hidden" {...register(`${formPrefix}.address.country`, { value: country })} />
      <div className={styles.header}>
        <h3 className={labelStyles.label}>{t('selectedAddressHeading', { ns: 'addressLookup' })}</h3>
        <ButtonTextLink data-qa="address-edit-button" className={styles.edit} onClick={onEdit}>
          {t('edit')}
        </ButtonTextLink>
      </div>
      <PreLineWhiteSpace
        text={[
          line1 ?? undefined,
          line2 ?? undefined,
          townCity ?? undefined,
          countyStateRegion ?? undefined,
          postalCode,
        ]}
      />
    </div>
  );
};

export default AddressLookupSelectedAddress;
