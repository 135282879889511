import createAuth from '@justgiving/auth/es/utils/createAuth';
import { createInstance, enums } from '@optimizely/react-sdk';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import APP_SETTINGS from '../../config';
import { additionalPageDataApiSlice } from './additionalPageDataApiSlice/additionalPageDataApiSlice';
import { addressLookupApiSlice } from './addressLookupApiSlice/addressLookupApiSlice';
import { checkoutApiSlice } from './checkoutApiSlice/checkoutApiSlice';
import { cryptocurrenciesApiSlice } from './cryptocurrenciesApiSlice/cryptocurrenciesApiSlice';
import rootReducer from './reducers';
import { transactionStatusApiSlice } from './transactionStatusApiSlice/transactionStatusApiSlice';

const getOptimizelyLogLevel = () => {
  try {
    if (localStorage?.getItem('jgAbLogging')) return enums.LOG_LEVEL.DEBUG;
  } catch (error) {
    // Do nothing
  }
  return enums.LOG_LEVEL.WARNING;
};

export const optimizely = createInstance({
  sdkKey: APP_SETTINGS.OPTIMIZELY_FULLSTACK_KEY,
  logLevel: getOptimizelyLogLevel(),
  eventBatchSize: 10,
  eventFlushInterval: 1000,
});

export const auth = createAuth({
  documentOrReq: document,
  authCookieName: APP_SETTINGS.AUTH_COOKIE_NAME,
  activeGroupCookieName: APP_SETTINGS.AUTH_ACTIVE_GROUPS_COOKIE_NAME,
  refreshAccessTokenUrl: APP_SETTINGS.AUTH_REFRESH_URL,
  allowGuestLogin: true,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ thunk: { extraArgument: { auth, optimizely } } })
      .concat(checkoutApiSlice.middleware)
      .concat(additionalPageDataApiSlice.middleware)
      .concat(addressLookupApiSlice.middleware)
      .concat(transactionStatusApiSlice.middleware)
      .concat(cryptocurrenciesApiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
setupListeners(store.dispatch);
