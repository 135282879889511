import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { sprinkles } from '../../../styles/sprinkles.css';
import Button from '../button/Button';

const ChunkError: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={`donate-main ${sprinkles({ textAlign: 'center' })}`}>
      <p className={sprinkles({ color: 'light' })}>{t('chunkError|message', { ns: 'alert' })}</p>
      <p>
        <Button
          size="small"
          className={sprinkles({ marginTop: 'sizeSpacing05' })}
          onClick={() => window.location.reload()}
        >
          {t('tryAgain')}
        </Button>
      </p>
    </div>
  );
};

export default ChunkError;
