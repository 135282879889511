import { gql } from 'graphql-tag';

import { Checkout, UpdateCheckoutTipValueInput } from '../../../../types/__generated-graphQL__';
import { checkoutDetails } from '../../graphQL/fragmentCheckoutDetails';
import { checkoutApiSlice } from './checkoutApiSlice';

export const mutateTip = checkoutApiSlice.injectEndpoints({
  endpoints: builder => ({
    mutateTip: builder.mutation<Checkout, Omit<UpdateCheckoutTipValueInput, 'checkoutId'>>({
      query(data) {
        return {
          variables: {
            input: data,
          },
          document: gql`
            ${checkoutDetails}
            mutation updateCheckoutTipValue($input: UpdateCheckoutTipValueInput!) {
              updateCheckoutTipValue(input: $input) {
                ...checkoutDetails
              }
            }
          `,
        };
      },
      transformResponse: (response: { updateCheckoutTipValue: Checkout }) => response.updateCheckoutTipValue,
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // @ts-ignore
          dispatch(mutateTip.util.updateQueryData('fetchCheckout', undefined, () => data));
        } catch (err) {
          // Do nothing
        }
      },
    }),
  }),
});

export const { useMutateTipMutation } = mutateTip;
