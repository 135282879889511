import "src/app/modules/Crypto/Cryptocurrency.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/app/modules/Crypto/Cryptocurrency.css.ts.vanilla.css\",\"source\":\"LnVlNWN4MjAgewogIG1hcmdpbi10b3A6IDE2cHg7CiAgbWluLWhlaWdodDogMjIwcHg7Cn0KLnVlNWN4MjEgewogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogMTJweDsKICBtYXJnaW4tdG9wOiAxNnB4OwogIGhlaWdodDogMjM4cHg7Cn0KLnVlNWN4MjIgewogIGNvbG9yOiAjNWE1YTVhOwp9Ci51ZTVjeDIzIHsKICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7CiAgY29sb3I6ICM2NjY2NjY7CiAgd2lkdGg6IDEwMCU7Cn0KLnVlNWN4MjQgewogIG1hcmdpbi10b3A6IDhweDsKfQoudWU1Y3gyNSB7CiAgaGVpZ2h0OiA0OHB4Owp9Ci51ZTVjeDI1OmhvdmVyIHsKICBib3JkZXItY29sb3I6ICNjY2NjY2M7Cn0KLnVlNWN4MjYgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKfQoudWU1Y3gyNyB7CiAgY29sb3I6ICM5OTk5OTk7CiAgbWFyZ2luLXJpZ2h0OiA2cHg7Cn0KLnVlNWN4MjggewogIGNvbG9yOiAjOTk5OTk5OwogIG1hcmdpbi1sZWZ0OiA2cHg7CiAgbWFyZ2luLXJpZ2h0OiA2cHg7Cn0KLnVlNWN4MjkgewogIG1hcmdpbi1yaWdodDogMTBweDsKfQoudWU1Y3gyYSB7CiAgaGVpZ2h0OiA0OHB4OwogIGJvcmRlcjogc29saWQgMXB4ICNjY2NjY2M7CiAgZGlzcGxheTogZmxleDsKICBib3JkZXItcmFkaXVzOiA0cHg7CiAgbWFyZ2luLXRvcDogOHB4OwogIHBhZGRpbmctaW5saW5lOiAxNnB4OwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLnVlNWN4MmIgewogIHdpZHRoOiA1MCU7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwp9Ci51ZTVjeDJjIHsKICBtaW4td2lkdGg6IDI0cHg7CiAgbWFyZ2luLXJpZ2h0OiA4cHg7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var clearIndicator = 'ue5cx27';
export var container = 'ue5cx24';
export var control = 'ue5cx25';
export var dropdownIndicator = 'ue5cx28';
export var errorMessage = 'ue5cx22';
export var errorWrapper = 'ue5cx21';
export var label = 'ue5cx23';
export var loaderContent = 'ue5cx2b';
export var loaderWrapper = 'ue5cx2a';
export var logoLoader = 'ue5cx2c';
export var option = 'ue5cx26';
export var selectLogo = 'ue5cx29';
export var wrapper = 'ue5cx20';