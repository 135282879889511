import { Reducer } from 'redux';

import { AmexpayWithPointsActionTypes } from './AmexPayWithPoints.actions';

const initialState: State['amexPWP'] = null;

export const amexPWP: Reducer<State['amexPWP'], AmexpayWithPointsActionTypes> = (
  state = initialState,
  action: AmexpayWithPointsActionTypes,
) => {
  switch (action.type) {
    case 'amexPWP/updateAppliedPoints': {
      return action.appliedPoints;
    }
    case 'amexPWP/resetAppliedPoints': {
      return null;
    }
    default:
      return state;
  }
};
