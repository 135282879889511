import { PaymentType } from '../../../types/__generated-graphQL__';
import { HACK_isGoCardlessStaffPage } from '../config/hacks';
import { useMutatePaymentMethodMutation } from '../redux/checkoutApiSlice/mutatePaymentMethod';
import { useLazyFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLIsApplePayAvailable,
  getGQLIsGoCardlessInstantBankPaymentAvailable,
  getGQLIsGooglePayAvailable,
  getGQLIsPayPalAvailable,
  getGQLIsVenmoAvailable,
} from '../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLSuggestedPaymentType } from '../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import { getGQLPage } from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLSelectedPaymentType } from '../redux/checkoutApiSlice/selectors/payment.selectors';
import { applePay, googlePay, paypal, venmo } from './useInitialiseBraintree';

export const useSetSelectedPaymentType = () => {
  const [queryCheckout] = useLazyFetchCheckoutQuery();
  const [mutatePaymentMethod] = useMutatePaymentMethodMutation();

  const setSelectedPaymentType = async () => {
    const { data } = await queryCheckout(undefined, true);
    const page = getGQLPage(data);
    if (HACK_isGoCardlessStaffPage(page?.slug)) return;

    const selectedPaymentType = getGQLSelectedPaymentType(data);
    const suggestedPaymentType = getGQLSuggestedPaymentType(data);

    if (selectedPaymentType) return;

    // Apple pay
    const isApplePayAvailable = getGQLIsApplePayAvailable(data);
    const isApplePayEnabled = applePay.enabled && applePay.instance;
    const shouldSelectApple =
      isApplePayAvailable &&
      isApplePayEnabled &&
      (suggestedPaymentType === PaymentType.BraintreeApplePay || !suggestedPaymentType);

    // Google pay
    const isGooglePayAvailable = getGQLIsGooglePayAvailable(data);
    const isGooglePayEnabled = googlePay.enabled && googlePay.instance;
    const shouldSelectGoogle =
      isGooglePayAvailable && isGooglePayEnabled && suggestedPaymentType === PaymentType.BraintreeGooglePay;

    // GoCardless Instant Bank Payment
    const isGoCardlessInstantBankPaymentAvailable = getGQLIsGoCardlessInstantBankPaymentAvailable(data);
    const shouldSelectGCIBP =
      isGoCardlessInstantBankPaymentAvailable && suggestedPaymentType === PaymentType.GoCardlessInstantBankPayment;

    // Paypal
    const isPayPalAvailable = getGQLIsPayPalAvailable(data);
    const isPayPalEnabled = paypal.enabled && paypal.instance;
    const shouldSelectPayPal =
      isPayPalAvailable && isPayPalEnabled && suggestedPaymentType === PaymentType.BraintreePayPal;

    // Venmo
    const isVenmoAvailable = getGQLIsVenmoAvailable(data);
    const isVenmoEnabled = venmo.enabled && venmo.instance;
    const shouldSelectVenmo = isVenmoAvailable && isVenmoEnabled && suggestedPaymentType === PaymentType.BraintreeVenmo;

    let paymentTypeToSave: PaymentType | null = null;

    if (!selectedPaymentType) {
      if (shouldSelectApple) {
        paymentTypeToSave = PaymentType.BraintreeApplePay;
      }
      if (shouldSelectGoogle) {
        paymentTypeToSave = PaymentType.BraintreeGooglePay;
      }
      if (shouldSelectGCIBP) {
        paymentTypeToSave = PaymentType.GoCardlessInstantBankPayment;
      }
      if (shouldSelectPayPal) {
        paymentTypeToSave = PaymentType.BraintreePayPal;
      }
      if (shouldSelectVenmo) {
        paymentTypeToSave = PaymentType.BraintreeVenmo;
      }
      if (paymentTypeToSave) {
        await mutatePaymentMethod({ selectedPaymentType: paymentTypeToSave });
      }
    }
  };

  return { setSelectedPaymentType };
};
