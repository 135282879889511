import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { PaymentType } from '../../../../types/__generated-graphQL__';
import { SelectPaymentTypeFormValues } from '../../../../types/formValues';
import { paymentIcon } from '../../../styles/icons.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import Radio from '../../components/Form/Radio/Radio';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLSelectedPaymentType } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import * as styles from './GoCardlessTeamsPageRadio.css';
import * as paymentMethodsRadiosStyles from './paymentMethodsRadios.css';

type GoCardlessTeamsPageRadioProps = {
  recommended?: boolean;
  icon?: 'goCardless' | 'bank';
};

const GoCardlessTeamsPageRadio: FunctionComponent<GoCardlessTeamsPageRadioProps> = ({ recommended, icon }) => {
  const { t } = useTranslation();
  const { data } = useFetchCheckoutQuery();

  const selectedPaymentType = getGQLSelectedPaymentType(data);

  const {
    formState: { isSubmitting: isDisabled },
  } = useFormContext<SelectPaymentTypeFormValues>();

  return (
    <div className={styles.recommendedWrapper}>
      {recommended && (
        <span className={styles.recommended}>
          {t('gocardlessTeams|recommended', { ns: 'ab' })}{' '}
          {recommended && (
            <Tooltip
              id="instantBankPayment-tooltip"
              buttonAccessibleLabel={t('instantBankTransfer|HelpAccessibleLabel')}
              buttonClassName={paymentMethodsRadiosStyles.tooltipButtonRecommended}
            >
              <p className={sprinkles({ color: 'light' })}>{t('instantBankTransfer|HelpText')}</p>
            </Tooltip>
          )}
        </span>
      )}
      <Radio
        name="paymentType"
        id="instantBank"
        value={PaymentType.GoCardlessInstantBankPayment}
        disabled={isDisabled}
        defaultChecked={selectedPaymentType === PaymentType.GoCardlessInstantBankPayment}
        registerMethods={{
          required: { value: true, message: t('selectPayment|validation|required', { ns: 'payment' }) },
        }}
      >
        <span className={paymentMethodsRadiosStyles.labelWrapper}>
          {t('instantBankPay')}
          {!recommended && (
            <Tooltip
              id="instantBankPayment-tooltip"
              buttonAccessibleLabel={t('instantBankTransfer|HelpAccessibleLabel')}
              buttonClassName={paymentMethodsRadiosStyles.tooltipButton}
            >
              <p className={sprinkles({ color: 'light' })}>{t('instantBankTransfer|HelpText')}</p>
            </Tooltip>
          )}

          {icon === 'goCardless' && (
            <span
              className={classNames(
                paymentIcon({ type: 'instantBankGoCardlessTeam' }),
                paymentMethodsRadiosStyles.icon,
              )}
              aria-hidden
            />
          )}
          {icon === 'bank' && (
            <span className={classNames(paymentMethodsRadiosStyles.icons)}>
              <span
                className={classNames(paymentIcon({ type: 'natwest' }), paymentMethodsRadiosStyles.icon)}
                aria-hidden
              />
              <span
                className={classNames(paymentIcon({ type: 'barclays' }), paymentMethodsRadiosStyles.icon)}
                aria-hidden
              />
              <span
                className={classNames(paymentIcon({ type: 'bankCount' }), paymentMethodsRadiosStyles.icon)}
                aria-hidden
              />
            </span>
          )}
        </span>
      </Radio>
    </div>
  );
};

export default GoCardlessTeamsPageRadio;
