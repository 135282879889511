import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import * as styles from './AccordionPageNewStep.css';

type AccordionPageNewStepProps = {
  children: ReactNode;
};

export const AccordionPageNewStep: FunctionComponent<AccordionPageNewStepProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {children}
      <VisuallyHidden> - {t('newSection|disabled', { ns: 'ab' })}</VisuallyHidden>
    </div>
  );
};
