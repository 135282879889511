import React, { FunctionComponent } from 'react';

import classNames from 'classnames';

import * as styles from './Label.css';

type Props = React.LabelHTMLAttributes<HTMLLabelElement> & { className?: string; children: React.ReactNode };

const Label: FunctionComponent<Props> = ({ className, children, ...props }) => {
  return (
    <label className={classNames(styles.label, className)} {...props}>
      {children}
    </label>
  );
};

export default Label;
