import { Auth } from '@justgiving/auth';

export const createHeaders = (auth?: Auth) => {
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const accessToken = auth?.getParsedAuthCookie()?.accessToken;

  if (accessToken && typeof accessToken === 'string') {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }
  return headers;
};
