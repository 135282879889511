import React, { FunctionComponent, useCallback, useReducer } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Address, AddressesByPostalCodeEdge } from '../../../../types/__generated-graphQL__';
import { sprinkles } from '../../../styles/sprinkles.css';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import { FormError } from '../../components/FormError/FormError';
import Button from '../../components/button/Button';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import { CONSTANTS } from '../../config/constants';
import { useLazyAddressLookup } from '../../redux/addressLookupApiSlice/addressLookupApiSlice';
import { normalisePostcodeInput } from '../../utils/normalisePostcodeInput';
import AddressLookupManualAddress from './AddressLookupManualAddress';
import AddressLookupSelect from './AddressLookupSelect';
import AddressLookupSelectedAddress from './AddressLookupSelectedAddress';
import * as styles from './addressLookup.css';

type AddressLookUpState = {
  addressResults: AddressesByPostalCodeEdge[];
  isSubmitting: boolean;
  isManual: boolean;
  selectedAddress: Address | null;
};

type AddressLookUpActions =
  | { type: 'ADDRESS_LOOK_UP_SUCCESS'; addressResults: AddressesByPostalCodeEdge[] }
  | { type: 'ADDRESS_LOOKUP_IN_PROGRESS' }
  | { type: 'ADDRESS_LOOKUP_COMPLETE' }
  | { type: 'SELECT_ADDRESS'; selectedAddress: Address }
  | { type: 'ENTER_MANUAL_ADDRESS' }
  | { type: 'EDIT_SELECTED_ADDRESS' }
  | { type: 'ADDRESS_LOOKUP_SINGLE_RESULT'; addressResult: Address };

type PostcodeLookupFormProps = {
  formPrefix: string;
  state: AddressLookUpState;
  stateDispatch: React.Dispatch<AddressLookUpActions>;
};
const PostcodeLookupForm: FunctionComponent<PostcodeLookupFormProps> = ({ formPrefix, state, stateDispatch }) => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    clearErrors,
    trigger,
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();
  const addressId = useWatch({ name: `${formPrefix}.address.id` });

  const [addressLookupTrigger] = useLazyAddressLookup();

  const handleFindAddress = useCallback(async () => {
    const lookupPostalCode = getValues('lookupPostalCode');
    const isValid = await trigger('lookupPostalCode');

    try {
      if (!isValid || !lookupPostalCode) {
        stateDispatch({ type: 'ADDRESS_LOOKUP_COMPLETE' });
        return;
      }

      stateDispatch({ type: 'ADDRESS_LOOKUP_IN_PROGRESS' });
      clearErrors(`${formPrefix}.address.id`);
      const response = await addressLookupTrigger(
        { input: { postalCode: normalisePostcodeInput(lookupPostalCode) } },
        true,
      );
      const addresses = response.data?.addressesByPostalCode;
      if ('error' in response) {
        throw new Error(response.error?.message);
      }
      if (addresses?.totalCount === 0 || !addresses?.totalCount) {
        setError(`${formPrefix}.address.id`, { message: t('addressLookup|noAddressFound', { ns: 'validation' }) });
        return;
      }
      if (addresses.totalCount === 1) {
        stateDispatch({ type: 'ADDRESS_LOOKUP_SINGLE_RESULT', addressResult: addresses.edges[0].node });
        return;
      }
      stateDispatch({ type: 'ADDRESS_LOOK_UP_SUCCESS', addressResults: addresses.edges });
    } catch (e) {
      setError(`${formPrefix}.address.selectionHash`, { message: t('recoverable', { ns: 'alert' }) });
    } finally {
      stateDispatch({ type: 'ADDRESS_LOOKUP_COMPLETE' });
    }
  }, [clearErrors, formPrefix, getValues, trigger, stateDispatch, addressLookupTrigger, setError, t]);

  return (
    <div className={styles.wrapper}>
      <input
        type="hidden"
        {...register(`${formPrefix}.address.id`, {
          required: {
            value: true,
            message: t('addressLookup|addressSelectionId|mandatory', { ns: 'validation' }),
          },
        })}
      />
      <Label htmlFor="lookupPostalCode">{t('postalCodeLabel', { ns: 'addressLookup' })}</Label>
      <div className={sprinkles({ display: 'flex', gap: 'sizeSpacing04', marginTop: 'sizeSpacing02' })}>
        <Input
          className={sprinkles({ flex: 1 })}
          name="lookupPostalCode"
          id="lookupPostalCode"
          registerMethods={{
            setValueAs: value => value?.trim(),
            onChange: () => {
              setValue(`${formPrefix}.address.id`, '');
              clearErrors([`${formPrefix}.address.id`, 'lookupPostalCode']);
            },
            required: {
              value: !addressId,
              message: t('addressLookup|postalCode|mandatory', { ns: 'validation' }),
            },
            pattern: {
              value: CONSTANTS.UK_POSTCODE_REGEXP,
              message: t('postalCode|invalid', { ns: 'validation' }),
            },
            maxLength: { value: 10, message: t('postalCode|maxLength', { ns: 'validation' }) },
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const key = e.key || e.keyCode || e.which;
            if (key === 'Enter' || key === 13) {
              e.preventDefault();
              handleFindAddress();
            }
          }}
          required={!addressId}
        />
        <Button
          type="button"
          size="small"
          onClick={handleFindAddress}
          isLoading={state.isSubmitting}
          disabled={state.isSubmitting || Boolean(errors.lookupPostalCode)}
          className={styles.findAddressButton}
        >
          {t('findButtonLabel', { ns: 'addressLookup' })}
        </Button>
      </div>
      <FormError fieldName="lookupPostalCode" />
      <FormError fieldName={`${formPrefix}.address.id`} />
      <ButtonTextLink
        className={sprinkles({ marginTop: 'sizeSpacing02' })}
        data-qa="address-lookup-manual-button"
        onClick={() => stateDispatch({ type: 'ENTER_MANUAL_ADDRESS' })}
      >
        {t('manualAddressLabel', { ns: 'addressLookup' })}
      </ButtonTextLink>
      {Boolean(state.addressResults.length) && (
        <AddressLookupSelect
          addresses={state.addressResults}
          className={styles.selectAddress}
          onSelect={async selectedAddress => {
            setValue(`${formPrefix}.address.id`, selectedAddress.id);
            stateDispatch({ type: 'SELECT_ADDRESS', selectedAddress });
          }}
        />
      )}
    </div>
  );
};

type AddressLookupFormProps = {
  formPrefix: string;
  initialValues?: Address | null;
};
const AddressLookUpForm: FunctionComponent<AddressLookupFormProps> = ({ formPrefix, initialValues }) => {
  const reducer = (state: AddressLookUpState, action: AddressLookUpActions): AddressLookUpState => {
    switch (action.type) {
      case 'ADDRESS_LOOKUP_IN_PROGRESS':
        return {
          ...state,
          addressResults: [],
          isSubmitting: true,
          selectedAddress: null,
        };
      case 'ADDRESS_LOOK_UP_SUCCESS':
        return {
          ...state,
          addressResults: action.addressResults,
          isSubmitting: false,
        };
      case 'ADDRESS_LOOKUP_SINGLE_RESULT':
        return {
          ...state,
          selectedAddress: action.addressResult,
          isSubmitting: false,
        };
      case 'ADDRESS_LOOKUP_COMPLETE':
        return {
          ...state,
          isSubmitting: false,
        };
      case 'SELECT_ADDRESS':
        return {
          ...state,
          selectedAddress: action.selectedAddress,
        };
      case 'ENTER_MANUAL_ADDRESS':
        return {
          ...state,
          selectedAddress: null,
          isManual: true,
        };
      case 'EDIT_SELECTED_ADDRESS':
        return {
          ...state,
          selectedAddress: null,
          addressResults: [],
        };
      default: {
        return state;
      }
    }
  };

  const setInitialAddress = (): Address | null => {
    if (!initialValues) return null;
    const { postalCode, country, countyStateRegion, line1, line2, townCity } = initialValues;
    return {
      id: '',
      line1: line1 ?? '',
      line2: line2 ?? '',
      townCity: townCity ?? '',
      countyStateRegion: countyStateRegion ?? '',
      postalCode: postalCode ?? '',
      country: country ?? '',
    };
  };

  const [state, stateDispatch] = useReducer(reducer, {
    isManual: false,
    isSubmitting: false,
    selectedAddress: setInitialAddress(),
    addressResults: [],
  });

  if (state.selectedAddress) {
    return (
      <AddressLookupSelectedAddress
        formPrefix={formPrefix}
        selectedAddress={state.selectedAddress}
        onEdit={() => stateDispatch({ type: 'EDIT_SELECTED_ADDRESS' })}
      />
    );
  }

  if (state.isManual) {
    return <AddressLookupManualAddress formPrefix={formPrefix} />;
  }

  return <PostcodeLookupForm state={state} stateDispatch={stateDispatch} formPrefix={formPrefix} />;
};

export default AddressLookUpForm;
