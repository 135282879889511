import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '@justgiving/auth-react';

import JustGivingGrey from '../../../assets/images/JustGivingGrey.svg';
import JustGivingPurple from '../../../assets/images/JustGivingPurple.svg';
import avatarPlaceholder from '../../../assets/images/avatarPlaceholder.svg';
import APP_SETTINGS from '../../../config';
import { Image } from '../../components/Image/Image';
import { SkipLink } from '../../components/SkipLink/SkipLink';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { usePageName } from '../../hooks/usePageName';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLIsPoweredByAvailable,
  getGQLBranding,
  getGQLCheckoutMode,
} from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getLoginURL, getSignUpURL } from '../../utils/getLoginURL';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import { AnimatedChevron } from '../AnimatedChevron/AnimatedChevron';
import { DropdownMenuWrapper } from '../DropdownMenu/DropdownMenuWrapper';
import * as styles from './ChromeHeader.css';

type ChromeHeaderProps = {
  skipFetch?: boolean;
};

export const ChromeHeader: FunctionComponent<ChromeHeaderProps> = ({ skipFetch }) => {
  const { data } = useFetchCheckoutQuery(undefined, { skip: skipFetch });
  const { accordionEnabled } = useAccordionExperiment();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const pageName = usePageName();

  const checkoutMode = getGQLCheckoutMode(data);

  const isGuest = auth?.isGuest();
  const isUserLoggedIn = auth?.isUserLoggedIn();

  if (checkoutMode === 'Headless') {
    return null;
  }

  if (checkoutMode === 'LowBranded') {
    return null;
  }

  const { IMAGE_SERVICE_URL } = APP_SETTINGS;

  const poweredByAvailable = getGQLIsPoweredByAvailable(data);
  const branding = getGQLBranding(data);
  const customHeaderImageUrl =
    poweredByAvailable && branding?.header ? `${IMAGE_SERVICE_URL}/${branding.header}` : undefined;

  const accessToken = auth?.getAccessToken();
  const userName = accessToken?.givenName;
  const avatarImageUrl = accessToken?.profilePictureUrl
    ? `${IMAGE_SERVICE_URL}/${accessToken?.profilePictureUrl}?template=size75x75face`
    : avatarPlaceholder;

  const displayLinks = pageName === 'AmountPage' && !isGuest && !isUserLoggedIn;

  const loginUrl = getLoginURL(false);
  const signUpUrl = getSignUpURL(false);

  return (
    <header className={styles.container}>
      <SkipLink />
      <div className={accordionEnabled ? styles.accordionBanner : styles.banner}>
        {customHeaderImageUrl ? (
          <div className={styles.imageWrapper}>
            <Image className={styles.customHeaderImage} src={customHeaderImageUrl} />
          </div>
        ) : (
          <a href={APP_SETTINGS.HOME_URL} className={styles.imageWrapper} title="JustGiving">
            <VisuallyHidden>JustGiving</VisuallyHidden>
            <Image className={styles.justGivingLogoPurple} src={JustGivingPurple} />
          </a>
        )}
        {((userName && avatarImageUrl) || displayLinks) && (
          <div className={styles.otherContentWrapper}>
            {customHeaderImageUrl && (
              <a href={APP_SETTINGS.HOME_URL} className={styles.justGivingLogoGreyWrapper} title="JustGiving">
                <VisuallyHidden>JustGiving</VisuallyHidden>
                <Image src={JustGivingGrey} className={styles.justGivingLogoGrey} />
              </a>
            )}
            {userName && avatarImageUrl && (
              <DropdownMenuWrapper buttonTitle={userName}>
                <Image src={avatarImageUrl} className={styles.avatar} />
                <span className={styles.userName}>{userName}</span>
                <AnimatedChevron />
              </DropdownMenuWrapper>
            )}
            {displayLinks && (
              <>
                <a href={loginUrl} className={styles.link}>
                  {t('logIn', { ns: 'chromeHeader' })}
                </a>
                <a href={signUpUrl} className={styles.link}>
                  {t('signUp', { ns: 'chromeHeader' })}
                </a>
              </>
            )}
          </div>
        )}
      </div>
    </header>
  );
};
