import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import Select from '../../components/Form/Select/Select';

type Props = {
  className?: string;
};

const PaymentCardExpiryYearDropdown: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation();
  const yearsSelectList: JSX.Element[] = useMemo(
    () =>
      Array(13)
        .fill(null)
        .map((_, i) => {
          const year = String(new Date().getFullYear() + i);
          return (
            <option key={`year-${year}`} value={year}>
              {year}
            </option>
          );
        }),
    [],
  );
  return (
    <Select
      className={classNames(className, sprinkles({ flex: 1 }))}
      name="cardExpiryYear"
      aria-label={t('paymentCard|expiryYear|label', { ns: 'payment' })}
      data-qa="expiryYear"
      autoComplete="off"
      defaultValue=""
      required
      registerMethods={{
        required: { value: true, message: t('paymentCard|expiryYear|error|required', { ns: 'validation' }) },
      }}
    >
      <option value="">{t('paymentCard|expiryYear|placeholder', { ns: 'payment' })}</option>
      {yearsSelectList}
    </Select>
  );
};

export default PaymentCardExpiryYearDropdown;
