import React from 'react';

import * as styles from './PreLineWhiteSpace.css';

type Props = {
  text: (string | undefined)[];
  dataQA?: string;
};

export const PreLineWhiteSpace = ({ text, dataQA }: Props) => {
  const spacedText = text.filter(Boolean).join('\n');

  return (
    <div className={styles.wrapper} data-qa={dataQA}>
      {spacedText}
    </div>
  );
};
